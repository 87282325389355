import DetailMixinController from '@/mixins/DetailMixinController';

export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$contentsService',
      hasDelete: true
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  methods: {
    prepareResponse(data) {
      data.pillarObject = this.$missionsService.getMissionPillar(data.pillar)
      return data
    },
  }
}