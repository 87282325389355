<template>
	<div id="mission_detail">
		<Navbar current="Detalhes da tarefa" :link="$paths.tasks.list" :linkName="'Tarefas'" />
		<Sidebar />
		<Container>
			<Header title="Detalhes da Tarefa">
				<template v-slot:button>
					<button @click="handleDelete" class="btn btn-outline-primary ms-3">
						Remover
					</button>
					<router-link :to="`${$paths.tasks.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
						Editar
						<i class="fa-solid fa-pen me-1"></i>
					</router-link>
				</template>
			</Header>
			<LoadingContainer v-if="loadingItems" />
			<div v-else>
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-xl-7">
								<TextInput :readOnly="true" label="Título" :value="item.title" />
							</div>
							<div class="col-12 col-xl-3">
								<TextInput :readOnly="true" label="Status" :value="item.statusObject?.title" />
							</div>
							<div class="col-12 col-xl-2">
								<TextInput :readOnly="true" label="Criado em" :value="item.formatted_created_at" />
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Categoria" :value="item.targetObject?.title">
									<template v-slot:labelContentEnd v-if="item.target_id && item.target == 'quiz'">
										<router-link :to="`${$paths.quizzes.detail}/${item.target_id}`" tag="a">
											<small> Ver questionario </small>
										</router-link>
									</template>
								</TextInput>
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Tipo" :value="item.typeObject?.title" />
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Especialista"
									:value="item.specialist_id ? item.specialist?.name : 'N/A'" />
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Tutor" :value="item.user?.name" />
							</div>
							<div class="col-12 col-xl-12" v-if="item.target == 'appointment'">
								<TextInput :readOnly="true" label="Pilar" :value="item.pillarObject?.title" />
							</div>

							<div v-if="item.file" class="col-md-2 col-2 card border rounded-4 mx-3 cursor-pointer"
								style="height: 170px" @click="viewFile(item)">
								<div class="card-header px-0" style="height: 35px">
									<div class="col">
										<small class="ps-2">
											<strong class="">{{ "Arquivo em anexo" }}</strong>
										</small>
									</div>
								</div>
								<div class="card-body justify-content-center align-items-center d-flex">
									<span class="fas fa-file-pdf display-2 text-muted"></span>
								</div>
							</div>

						</div>
						<hr />
						<div v-if="quizQuestions">
							<h3>Respostas do Questionário</h3>
							<div class="col-12" v-if="quizQuestions.length">
								<div class="col-12 col-xl-12" v-for="(question, index) in quizQuestions" :key="index">
									<TextAreaInput :rows="3" :readOnly="true" :label="question.title"
										:value="question.answer" />
								</div>
							</div>

							<EmptyState v-else :title="'Sem respostas'" :msg="'Este questionário ainda não foi respondido'">
								<template v-slot:icon>
									<span></span>
								</template>
							</EmptyState>
						</div>
					</div>
				</div>
			</div>
		</Container>
	</div>
</template>
<script>
import TaskDetailController from "./TaskDetailController"
export default TaskDetailController
</script>
