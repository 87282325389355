import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Nome',
          sortName: 'name',
          order: null
        },
        {
          name: 'Especialidades',
          sortName: null,
          order: null
        },
        {
          name: 'Sessões',
          sortName: null,
          order: null
        },
        {
          name: 'Avaliação média',
          sortName: null,
          order: null
        },
        {
          name: 'Data de criação',
          sortName: 'created_at',
          order: null
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        type: 'specialist',
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    },
    prepareResponse(data) {
      return data.map(elem => {
        let sp = ''
        if (elem.specialist_info?.specialties) {
          sp = elem.specialist_info.specialties.split('|').map(s => this.$missionsService.getMissionPillar(s).title).join(', ')
        }
        elem.concluded_sessions_time = elem.concluded_sessions_count * 30
        return {
          ...elem,
          specialties: sp
        }
      })
      return data
    }
  }
};