import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  props: {
    chatFilter: {
      type: String,
      default: 'all'
    },
    specialistId: {
      type: String,
      default: ''
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  mounted() {
    this.loadingItems = true
    this.getData()

    this.$eventBus.$on("newMessage", () => {
      try {
        this.getData()
      } catch (e) {
        console.log(e)
      }
    });

  },
  data() {
    return {
      columns: [
        {
          name: 'Usuário',
          sortName: null,
          order: null
        },
        {
          name: 'Especialidade',
          sortName: 'subject',
          order: null
        },
        {
          name: 'Título',
          sortName: 'reason',
          order: null
        },
        {
          name: 'Última mensagem',
          sortName: null,
          order: null
          // sortName: 'latest_message',
          // order: 'DESC'
        },
        {
          name: 'Status',
          sortName: 'status',
          order: null
        },
        {
          name: 'Criação',
          sortName: 'created_at',
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => ({
        ...elem,
        statusObject: this.$chatService.getChatStatus(elem.status),
        pillarObject: this.$missionsService.getMissionPillar(elem.subject),
      }))
    },
    fetchPromise() {
      let paramFilters = {
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      }

      if (this.chatFilter == 'with_specialist') {
        paramFilters.specialist_id = this.specialistId
      }
      if (this.chatFilter == 'without_specialist') {
        paramFilters.noSpecialists = true
      }
      return this.$chatService.list(paramFilters)
    }
  }
};