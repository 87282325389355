import NetworkService from '@/services/NetworkService.js'

export default class TasksService {
    constructor() {
        this.network = new NetworkService()
    }
    taskTargets = [
        {
            id: 'pet_register',
            title: 'Cadastro do pet',
            active: false
        },
        {
            id: 'quiz',
            title: 'Quiz',
            active: true
        },
        {
            id: 'first_appointment',
            title: 'Sessão com especialista',
            active: true
        },
        {
            id: 'health_test',
            title: 'Exame de saúde',
            active: false
        },
        {
            id: 'first_health_test',
            title: 'Solicitar exames ou pedidos',
            active: true
        },
        {
            id: 'appointment',
            title: 'Encaminhamento',
            active: true
        },
    ]
    taskTypes = [
        {
            id: 'free',
            title: 'Gratuito',
        },
        {
            id: 'premium',
            title: 'Assinante',
        }
    ]
    create = (params) => {
        return this.network.postMultipart(this.network.endpoints.tasks, params)
    }
    list = (params) => {
        return this.network.get(this.network.endpoints.tasks, params)
    }
    show = (id) => {
        return this.network.get(`${this.network.endpoints.tasks}/${id}`)
    }
    delete = (id) => {
        return this.network.delete(`${this.network.endpoints.tasks}/${id}`)
    }
    update = (id, params) => {
        return this.network.postMultipart(`${this.network.endpoints.tasks}/${id}/update`, params)
    }
    getTaskType(type) {
        // Possíveis types de uma missao ['free','standard','repetition']
        const defaultObject = {
            title: ''
        }
        return this.taskTypes.find(r => r.id == type) || defaultObject

    }
    getTaskTarget(target) {
        // Possíveis targets de uma missao ['free','standard','repetition']
        const defaultObject = {
            title: ''
        }
        return this.taskTargets.find(r => r.id == target) || defaultObject

    }

    getTaskStatus(status) {
        // Possíveis status de uma missao ['pending_review','canceled','active','done']
        const defaultObject = {
            title: ''
        }
        switch (status) {
            case 'pending':
                return {
                    title: 'Pendente',
                    badgeClass: 'badge bg-warning-soft'
                }
                break;
            case 'canceled':
                return {
                    title: 'Cancelada',
                    badgeClass: 'badge bg-danger-soft'
                }
                break;
            case 'concluded':
                return {
                    title: 'Concluída',
                    badgeClass: 'badge bg-success-soft'
                }
                break;
            default: return defaultObject
        }
    }

}