<template>
  <div id="mission_detail">
    <Navbar current="Chat com o tutor" :link="`${$paths.customerService.list}/chats`" :linkName="'Chats'" />
    <Sidebar />
    <Container>
      <Header :title="`Chat com ${item?.user?.name || 'tutor'}`">
        <template v-slot:button>
          <button v-if="canEndChat" class="btn btn-primary lift ms-3" @click="handleEndChat">
            Encerrar
          </button>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações</h3>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Razão" :value="item.reason" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Status" :value="item.statusObject?.title" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Assunto" :value="item.pillarObject?.title" />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body py-0">
            <div class="row">
              <EmptyState v-if="!chatMessages.length" :totalItemsRegistered="0">
                <template v-slot:noItemsRegistered>
                  <span class="fas fa-2x fa-comment text-primary opacity-50"></span>
                  <p>Nenhuma mensagem!</p>
                </template>
              </EmptyState>
              <div v-else id="main-chat-container" class="col-12 chat-container overflow-scroll px-6 py-4">

                <div v-for="(message, index) in chatMessages" v-bind:key="'message' + index">
                  <!-- Mensagens do CMS -->
                  <div v-if="!message.user_id || message.user_id == userSession.id"
                    class="message-container mb-4 d-flex flex-column align-items-end">
                    <div class="chat-bubble right-bubble bg-primary-soft">
                      <a :href="`${$baseImageUrl}${message.image}`" target="_blank">
                        <img v-if="message.image" :src="`${$baseImageUrl}${message.image}`" alt=""
                          class="chat-image rounded-4">
                      </a>
                      <p class="text-left mb-0 px-3">{{ message.message }}</p>
                    </div>
                    <div class="chat-footer">
                      <small class="text-muted me-2 time-text">{{
                        $moment(message.created_at).format("DD/MM/YYYY - HH:mm")
                      }}</small>
                    </div>
                  </div>
                  <!-- Mensagens do User -->
                  <div v-else class="message-container mb-4 d-flex flex-column">
                    <div class="d-flex flex-row align-items-end">
                      <span class="fas me-2 fa-user-circle fa-2x text-primary"></span>
                      <div class="chat-bubble left-bubble bg-secondary">
                        <a :href="`${$baseImageUrl}${message.image}`" target="_blank">
                          <img v-if="message.image" :src="`${$baseImageUrl}${message.image}`" alt=""
                            class="chat-image rounded-4">
                        </a>
                        <p class="text-left px-3 mb-0 text-dark">{{ message.message }}</p>
                      </div>
                    </div>
                    <div class="chat-footer">
                      <small class="text-muted me-2 ps-5 time-text ">{{
                        $moment(message.created_at).format("DD/MM/YYYY - HH:mm")
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="canSendMessage">
                <div class="col-12 mt-3">
                  <div class="input-group input-group-merge input-group mb-3">
                    <textarea rows="2" class="form-control" v-model="textMessage" type="text"
                      v-on:keyup.enter="handleSend()" :maxlength="MESSAGE_MAX_LENGTH"
                      placeholder="Digite a mensagem..." />
                    <div class="input-group-text text-dark align-items-start" id="inputGroupReverse">
                      <button class="btn btn-link" @click.prevent="handleSend()">Enviar</button>
                      <button class="btn btn-link ps-0" @click.prevent="handleSendImage()"><span
                          class="fas fa-camera text-primary"></span></button>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-end">
                  <small class="text-muted">Caracteres restantes: {{ MESSAGE_MAX_LENGTH - textMessage.length }}</small>
                </div>
              </template>
            </div>
          </div>
          <div v-if="item?.status != 'active'" class="card-footer bg-secondary d-flex justify-content-center">
            <p class="pt-2 text-muted">
              <strong>Encerrado</strong>
            </p>
          </div>
        </div>
      </div>
      <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="4 / 3" v-on:cropEnd="cropEnd"
        inputId="chat-message-input" :hiddenInput="true" />
    </Container>
  </div>
</template>
<script>
import ChatDetailController from "./ChatDetailController";
export default ChatDetailController;
</script>

<style>
div.chat-container {
  height: calc(100vh - 400px);
}

div.chat-bubble {
  background-color: pink;
  max-width: 60%;
  border-radius: 30px;
  padding: 12px !important;
}

div.chat-bubble.right-bubble {
  border-bottom-right-radius: 0 !important;
}

div.chat-bubble.left-bubble {
  border-bottom-left-radius: 0 !important;
}

textarea {
  resize: none !important;
}

.time-text {
  font-size: 0.68rem;
}

img.chat-image {
  width: 260px;
  height: 200px;
  object-fit: cover;
}
</style>