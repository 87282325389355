import { email, required, minLength, requiredIf } from 'vuelidate/lib/validators';
export default {
  name: 'AddQuizzes',
  mounted() {
    if (this.id) {
      this.getData()
    } else {
      this.handleAddQuestion()
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      // image: null,
      TITLE_MAX_LENGTH: 120,
      DESCRIPTION_MAX_LENGTH: 480,
      errorMessage: '',
      submitting: false,
      specialistList: [],
      ownersList: [],
      quizList: [],
      form: {
        title: '',
        description: '',
        pillar: 'none',
        questions: []
      },
      deletedQuestions: []
    };
  },
  validations: {
    form: {
      description: {
        required,
        minLength: minLength(20)
      },
      pillar: '',
      title: {
        required,
        minLength: minLength(10)
      },
      questions: {
        required,
        minLength: minLength(1),
        $each: {
          title: {
            required,
            minLength: minLength(10)
          },
          field_label: {
            required
          },
          field_type: {
            required,
          },
          select_answers: {
            $each: {
              title: { required }
            }
          },
        }
      },
    }
  },
  methods: {
    async submit() {
      try {
        let res
        let questionsRes
        this.submitting = true
        this.errorMessage = ''
        let values = { ...this.form }
        let questions = values.questions
        if (values.pillar === 'none') {
          values.pillar = ''
        }
        delete (values.questions)
        if (!this.id) {
          //create
          res = await this.$quizService.create(values)
          questionsRes = await this.$quizService.createQuestions({ quiz_id: res.data.id, questions })
          this.$eventBus.$emit('showToast', { title: 'Quiz criado', msg: `Quiz adicionado com sucesso` })
        } else {
          //update
          res = await this.$quizService.update(this.id, values)
          questionsRes = await this.$quizService.createQuestions({ quiz_id: this.id, questions: [...questions, ...this.deletedQuestions] })
          this.$eventBus.$emit('showToast', { title: 'Quiz criado', msg: `Quiz adicionado com sucesso` })
        }
        this.$router.push({ path: `${this.$paths.quizzes.list}` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
    async getData() {
      try {
        const res = await this.$quizService.show(this.id)
        let f = {
          title: res.data.title,
          description: res.data.description,
          pillar: res.data.pillar || 'none',
        }
        f.questions = res.data.questions.map(q => {
          return {
            id: q.id,
            title: q.title,
            field_label: q.field_label,
            field_type: q.field_type,
            select_answers: (q.select_answers && q.field_type == 'select') ? q.select_answers.split('|').map(sa => ({ title: sa })) : [],
            new: false
          }
        })
        console.log(f.questions)
        this.$set(this, 'form', f)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    // cropEnd(image) {
    //   this.$set(this.form, 'picture', image.file)
    // },
    answerTypeChanged(type, index) {
      if (type == 'select') {
        //add uma alternativa pra questao
        this.handleAddAlternative(index)
      } else {
        //remove todas as alternativas dessa questao
        this.$set(this.form.questions[index], 'select_answers', [])
      }

    },
    handleAddQuestion() {
      const newQuestion = {
        title: '',
        field_label: '',
        field_type: 'string',
        select_answers: [],
        new: true
      }
      this.$set(this.form, 'questions', [...this.form.questions, newQuestion])
    },
    handleRemoveQuestion(index) {
      let questions = [...this.form.questions]
      let questionToRemove = questions[index]
      if (this.id && !questionToRemove.new) {
        questionToRemove.deleted = true
        this.$set(this, 'deletedQuestions', [...this.deletedQuestions, questionToRemove])
      }
      if (questions.length > 1) {
        questions.splice(index, 1)
      }
      this.$set(this.form, 'questions', questions)
    },

    handleAddAlternative(questionIndex) {
      this.$set(this.form.questions[questionIndex], 'select_answers', [...this.form.questions[questionIndex].select_answers, { title: '' }])
    },
    handleRemoveAlternative(questionIndex) {
      let alternatives = this.form.questions[questionIndex].select_answers
      if (alternatives.length > 1) {
        alternatives.splice(-1)
      }
      this.$set(this.form.questions[questionIndex], 'select_answers', alternatives)
    }
  }
};