<template>
  <div id="admins">
    <Navbar current="Sessões de veterinários" />
    <Sidebar />
    <Container col="col-12">
      <Header :isSubHeader="false" title="Sessões por veterinários"
        subtitle="Quantidade de sessões realizadas por veterinários no período" :iconClass="'fa-solid fa-shield-dog text-primary'"
        :totalCount="totalItemsRegistered">
        <template v-slot:button>
          <!-- <div class="row">
            <div class="col"> -->
          <Datepicker format="DD/MM/YYYY" v-model="dateRange" placeholder="Selecione o período" range></Datepicker>
          <!-- </div> -->
          <!-- </div> -->
        </template>
      </Header>
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-solid  fa-shield-dog  text-black-50 mb-2"></span>
              <p>Nenhum veterinário com sessão</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table class="
                table table-sm table-nowrap
                card-table
                table-hover
              ">
              <TableHead :columns="columns" v-on:sort="sort" />

              <tbody class="list">
                <!-- <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.appointments.detail}/${item?.id}`"> -->
                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index">
                  <td>{{ item?.name }}</td>
                  <td>
                    <time>{{
                      item?.concluded_sessions_count
                    }}</time>
                  </td>
                  <td></td>
                </tr>
                <!-- </router-link> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import VetsAppointmentsController from "./VetsAppointmentsController";
export default VetsAppointmentsController;
</script>
