<template>
  <div id="app">
    <router-view/>
    <NotificationToast />
    <ConfirmationAlert />
  </div>
</template>

<script>
import NotificationToast from '@/components/NotificationToast'
export default {
  name: "App",
  components: {
    NotificationToast
  },
  created() {
    document.title = 'Bluu | Backoffice'
  }
};
</script>

<style lang="scss"></style>
