<template>
  <div id="pet_summary">
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
            <LoadingContainer v-if="loadingItems" />
            <div v-else>
              <EmptyState v-if="!tasks.length" :totalItemsRegistered="totalItemsRegistered">
                <template v-slot:noItemsRegistered>
                  <p>Nenhuma tarefa criada</p>
                </template>
              </EmptyState>
              <div v-else class="table-responsive">
                <table class="
                    table table-sm table-nowrap
                    card-table
                    table-hover
                    cursor-pointer
                  ">
                  <TableHead :columns="columns" v-on:sort="sort" />
                  <tbody class="list">
                    <router-link tag="tr" v-for="(item, index) in tasks" v-bind:key="'tr-' + index"
                      :to="`${$paths.tasks.detail}/${item.id}`">
                      <td class="fw-bolder" >{{ item.title }}</td>
                      <td>{{ item.pillarObject.title }}</td>
                      <td>
                        <time>{{
                        $moment(item.created_at).format("DD/MM/YYYY")
                        }}</time>
                      </td>
                      <td class="text-end"><span :class="`${item.statusObject.badgeClass}`">{{ item.statusObject.title
                      }}</span></td>
                    </router-link>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PetTasksController from "./PetTasksController";
export default PetTasksController;
</script>
