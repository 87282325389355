<template>
  <div id="admins_detail">
    <Navbar current="Minha conta" />
    <Sidebar />
    <Container>
      <Header title="Minha conta">
        <template v-slot:button>
          <button @click="handlePasswordUpdate" class="btn btn-outline-primary lift ms-3">
            <i class="fa-solid fa-unlock-keyhole me-1"></i>
            Alterar senha
          </button>
          <button @click="handleEdit" class="btn btn-primary lift ms-3"
            v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST])">
            <i class="fa-solid fa-pen me-1"></i>
            Editar
          </button>
        </template>
      </Header>
      <div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações</h3>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Nome" :value="userSession.name" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Data de criação" :value="userSession.formatted_created_at" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Tipo de conta"
                  :value="userSession.type == 'admin' ? 'Administrador' : 'Especialista'" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Email" :value="userSession.email" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Permissões" :value="userSession.formatted_permissions" />
              </div>
              <template v-if="userSession.type == 'specialist'">
                <div class="col-12 col-xl-12">
                  <TextInput :readOnly="true" label="Formação" :value="userSession.specialist_info?.education" />
                </div>
                <div class="col-12 col-xl-12">
                  <TextAreaInput :readOnly="true" label="Resumo da biografia" :value="userSession.specialist_info?.bio" />
                </div>
                <div class="col-12 col-xl-12">

                  <label class="text-muted form-label mb" for="">Especialidades</label>
                  <div v-for="(sp, index) in $missionsService.pillars" v-bind:key="'tr-' + index">
                    <p>
                      <span v-if="userSession.specialties?.includes(sp.id)"
                        class="fa-solid fa-circle-check fa-medium-size mb-0 text-muted"></span>
                      <span v-else class="far fa-circle fa-medium-size mb-0 text-muted"></span>
                      {{ sp.title }}
                    </p>
                  </div>

                </div>
                <div class="col-12 col-xl-12">
                  <p>
                    <label class="text-muted form-label mb-0" for="">Foto</label>
                  </p>
                  <img class="rounded-circle avatar avatar-xxl mb-4" style="object-fit: contain;"
                    :src="`${$baseImageUrl}${userSession.specialist_info?.image}`" alt="">
                </div>
                <h3 class="mb-5">Horário de atendimento</h3>
                <DutyCardComponent />
                <div v-for="(weekDay, weekIndex) in weekDays" v-bind:key="'week-' + weekIndex"
                  class="col-12 col-xl-12 border-bottom mt-3 pb-3">

                  <h4 class="fw-bolder mb-1">{{ weekDay.title }}</h4>
                  <div v-if="userSession.formatted_working_hours?.[`${weekDay.weekday}`]?.length">
                    <div v-for="(hour, hourIndex) in userSession.formatted_working_hours[`${weekDay.weekday}`]"
                      v-bind:key="'hour-' + hourIndex" class="text-muted">{{
                        hour.start_time.substring(0, 5)
                      }} as
                      {{ hour.end_time.substring(0, 5) }}</div>
                  </div>
                  <span class="text-muted" v-else>
                    Indisponível
                  </span>

                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import MyAccountController from "./MyAccountController";
export default MyAccountController;
</script>
