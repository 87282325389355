import DetailMixinController from '@/mixins/DetailMixinController';

export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$missionsService',
      hasDelete: true
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'Conteúdo',
          sortName: null,
          order: null
        },
        {
          name: 'Criado em',
          sortName: null,
          order: null
        },
        {
          name: '',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      data.formatted_created_at = this.$moment(data.created_at).format('DD/MM/YYYY')
      data.formatted_due_date = this.$moment(data.due_date).format('DD/MM/YYYY HH:mm')
      data.statusObject = this.$missionsService.getMissionStatus(data.status)
      data.pillarObject = this.$missionsService.getMissionPillar(data.pillar)
      data.typeObject = this.$missionsService.getMissionType(data.type)

      if (data.repetition) {
        const frequency = this.$missionsService.getFrequency(data.repetition.frequency)
        let rec = `A cada ${data.repetition.interval} ${frequency.unity(data.repetition.interval)}`
        if (data.repetition.repetitions < 9999) {
          rec = `${rec}, terminando após ${data.repetition.repetitions} execução(ões)`
        }
        data.formatted_frequency = rec
      }
      return data
    },
    viewContent(it) {
      window.open(`${this.$baseImageUrl}${it.path}`, '_blank');
    }
  }
}