<template>
  <div>
    <div class="card" v-if="isSpecialist">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <span class="fas fa-list-check fa-medium-size fa-user-doctor text-primary"></span>
          </div>
          <div class="col ps-0">
            <h3 class="mb-0">Plantão</h3>
            <div v-if="isOnDuty">
              Você está de plantão e disponível para atender sessões quando necessário.
            </div>
            <div v-else>
              você não está disponível de plantão, somente atendimento de sessões com horário marcado.
            </div>
          </div>
          <div class="col-auto d-flex align-items-center">
            <div class="form-check form-switch">
              <input class="form-check-input" @change="updateOnDuty" :checked="isOnDuty" type="checkbox"
                id="autoTaskCheckbox" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DutyCardComponentController from "./DutyCardComponentController";
export default DutyCardComponentController;
</script>

<style scoped></style>
