import NetworkService from "@/services/NetworkService.js";

export default class EventsService {
  constructor() {
    this.network = new NetworkService();
  }
  create = (params) => {
    return this.network.postMultipart(this.network.endpoints.events, params);
  };
  list = (params) => {
    return this.network.get(this.network.endpoints.events, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.events}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.events}/${id}`);
  };
  update = (id, params) => {
    return this.network.postMultipart(
      `${this.network.endpoints.events}/${id}/update`,
      params
    );
  };
}
