import DetailMixinController from '@/mixins/DetailMixinController';
import DutyCardComponent from '@/components/DutyCardComponent/DutyCardComponent.vue';
import { weekdays } from '@/utils/HelperVariables';
export default {
  // mixins: [DetailMixinController],
  mounted() {
    this.getUser()
    console.log('Andrews')
  },
  setup() {
  },
  data() {
    return {
      item: {},
      weekDays: weekdays.full
    }
  },
  computed: {
    userSession() {
      return this.$userService.getFormattedUserAccount({
        ...this.$store.state.userSession,
        ...this.item
      })
    },
  },
  methods: {
    async getUser() {
      try {
        const res = await this.$userService.show(this.userSession.id)
        this.item = res.data
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    handleEdit() {
      let path
      if (this.userSession.type == 'admin') {
        path = `${this.$paths.admins.edit}/${this.userSession.id}`
      }
      if (this.userSession.type == 'specialist') {
        path = `${this.$paths.specialists.edit}/${this.userSession.id}`
      }
      this.$router.push({ path })
    },
    handlePasswordUpdate() {
      let path
      console.log(this.userSession.type)
      if (this.userSession.type == 'admin') {
        path = `${this.$paths.admins.password_update}/${this.userSession.id}`
      }
      if (this.userSession.type == 'specialist') {
        path = `${this.$paths.specialists.password_update}/${this.userSession.id}`
      }
      this.$router.push({ path })
    }
  },
  components: {
    DutyCardComponent
  }
}