<template>
  <div id="pet_summary">
    <div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <router-link tag="a" :to="`${$paths.pets.detail}/${item.id}/pet-missions`" class="card lift">
            <div class="card-body">
              <h3 class="text-dark fw-bold">
                <span class="ms-2">
                  Missões em andamento
                </span>
              </h3>
              <div class="display-3 my-3 text-dark">
                {{summary?.pending?.total}}
              </div>
              <p class="font-weight-bold text-primary fw-bold mb-0">Ver mais</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-lg-6">
          <router-link tag="a" :to="`${$paths.pets.detail}/${item.id}/pet-tasks`" class="card lift">
            <div class="card-body">
              <h3 class="text-dark fw-bold">
                <span class="ms-2">
                  Tarefas pendentes
                </span>
              </h3>
              <div class="display-3 my-3 text-dark">
                {{summary?.pendingTasks?.total}}
              </div>
              <p class="font-weight-bold text-primary fw-bold mb-0">Ver mais</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-header-title fw-bold mb-4">
                Pet
              </h3>
              <div class="d-flex align-items-center">
                <div class="avatar">
                  <img :src="`${$baseImageUrl}${item.picture}`" alt="..." class="avatar-img rounded-circle">
                </div>
                <h1 class="card-header-title fw-bold ms-3 display-3">
                  {{item.name}}
                </h1>
              </div>
              <hr>
              <div class="list-group list-group-flush my-n3">
                <div class="list-group-item" v-for="(task, index) in summary?.allTasks" v-bind:key="'tr-' + index">
                  <div class="row align-items-center">
                    <div class="col">
                      <h4 class="mb-1">
                        <a href="profile-posts.html">{{task.title}}</a>
                      </h4>
                    </div>
                    <div class="col-auto">
                      <span v-if="task.status == 'pending'" class="fw-bolder text-warning">Incompleto</span>
                      <span v-else class="fw-bolder text-muted">Completo</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-header-title fw-bold mb-4">
                Tutor
              </h3>
              <div class="d-flex align-items-center">
                <span class="fas fa-user-circle fa-2xl text-primary"></span>
                <span class="ms-2">
                  {{ item.owner?.name }}
                </span>
              </div>
              <hr>
              <div class="list-group list-group-flush my-n3">
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <h4 class="mb-1">
                        <a href="profile-posts.html">Conta criada</a>
                      </h4>
                    </div>
                    <div class="col-auto">
                      <span class="fw-bolder">{{item.user_created_at}}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <h4 class="mb-1">
                        <a href="profile-posts.html">Tipo de conta</a>
                      </h4>
                    </div>
                    <div class="col-auto">
                      <span class="fw-bolder">{{item.account}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PetSummaryController from "./PetSummaryController";
export default PetSummaryController;
</script>
