<template>
  <!-- <router-link class="card lift" tag="a" :to="path"> -->
  <div class="card lift">
    <div class="card-body">
      <h2 class="text-dark fw-bold">
        <i :class="iconClass" class="text-primary"></i>
        <span class="ms-2">
          {{ title }}
        </span>
      </h2>
      <div class="display-2 my-3 text-dark">
        {{ qtd }}
      </div>
      <!-- <p v-if="path" class="font-weight-bold text-primary fw-bold mb-0">Ver mais</p> -->
    </div>
  </div>
  <!-- </router-link> -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    qtd: {
      type: Number,
      default: 0,
    },
    path: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>

</style>