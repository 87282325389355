export default {
  props: {
    item: {},
    pillar: {
      type: String,
      default: ''
    },
    summary: {}
  },
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      loadingItems: false,
      countItemsShowing: 0,
      totalItemsRegistered: 0,
      countItemsTotal: 0,
      filters: {
        page: 1,
        q: ''
      },
      missions: [],
      columns: [
        {
          name: 'Missão',
          sortName: 'title',
          order: null
        },
        {
          name: 'Categoria',
          sortName: 'pillar',
          order: null
        },
        {
          name: 'Criada em',
          sortName: 'created_at',
          order: null
        },

        {
          name: 'Status',
          sortName: 'status',
          order: null
        },
      ],
    };
  },
  methods: {
    async getData(loadingMore = false) {
      if (!this.item.id) { return }
      try {
        // if (!loadingMore) {
        //   this.$set(this.filters, 'page', 1)
        // }
        console.log('filtrin',{
          ...this.filters,
          pet_id: this.item.id,
          pillar: this.pillar,
          orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
        })
        const res = await this.$missionsService.list({
          ...this.filters,
          pet_id: this.item.id,
          pillar: this.pillar,
          orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
        })
        let data = res.data.map(elem => {
          let st = elem.status
          if (st == 'active' && this.$moment(elem?.due_date).isBefore()) {
            st = 'late'
          }
          return {
            ...elem,
            statusObject: this.$missionsService.getMissionStatus(st),
            pillarObject: this.$missionsService.getMissionPillar(elem.pillar),
            typeObject: this.$missionsService.getMissionType(elem.type),
          }
        })
        this.$set(this, 'missions', data)
        this.moreItemsToLoad = res.next_page_url != null
        this.countItemsShowing = res.to
        this.countItemsTotal = res.total

        if (this.filters.page == 1 && this.filters.q == '') {
          // so seta o o totalzao quando nao tem filtro
          this.$set(this, 'totalItemsRegistered', res.total)
        }

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
        this.loadingMoreItems = false
        this.$set(this, 'columns', this.columns.map(c => { return { ...c, loading: false } }))
      }
    },
    sort(colIndex) {
      this.$set(this, 'columns', this.$helper.sortCols(this.columns, colIndex))
      this.getData()
    },
    search(string) {
      this.$set(this.filters, 'q', string)
      this.getData()
    },
  }
}