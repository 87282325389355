<template>
  <div id="pet_detail">
    <Navbar current="Detalhes do prontuário" :link="$paths.users.list" :linkName="'Prontuários'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do prontuário">
        <template v-slot:button>
          <router-link
            v-if="selectedTab == 'pet-missions' && $permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST])"
            :to="`${$paths.missions.add}?pet_id=${item.id}`" tag="a" class="btn lift btn-primary">
            <i class="fa-solid fa-circle-plus"></i>
            Adicionar
          </router-link>
          <router-link v-if="selectedTab == 'pet-tasks' && $permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST])" :to="`${$paths.tasks.add}?user_id=${item.owner?.id}`" tag="a"
            class="btn lift btn-primary">
            <i class="fa-solid fa-circle-plus"></i>
            Adicionar
          </router-link>
        </template>
      </Header>
      <ul class="nav nav-tabs mb-4">
        <li tag="li" class="nav-item" v-for="(tab, index) in tabs" v-bind:key="'tabp-' + index"
          v-on:click="selectedTab = tab.id">
          <router-link tag="a" :to="`${$paths.pets.detail}/${item.id}/${tab.id}`" class="nav-link"
            :class="{ active: selectedTab == tab.id }">
            {{ tab.name }}
          </router-link>
        </li>
      </ul>
      <LoadingContainer v-if="loadingItems" />
      <div v-else class="tab-content" id="myTabContent">
        <div class="tab-pane fade" id="pet-summary" role="tabpanel" aria-labelledby="home-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'pet-summary' }">
          <PetSummary :item="item" :summary="summary" />
        </div>
        <div class="tab-pane fade" id="pet-missions" role="tabpanel" aria-labelledby="mission-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'pet-missions' }">
          <PetMissions :item="item" :summary="summary" />
        </div>
        <div class="tab-pane fade" id="pet-tasks" role="tabpanel" aria-labelledby="mission-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'pet-tasks' }">
          <PetTasks :item="item" />
        </div>
        <div class="tab-pane fade" id="pet-vaccines" role="tabpanel" aria-labelledby="mission-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'pet-vaccines' }">
          <PetMissions :item="item" pillar="vaccination" />
        </div>
        <div class="tab-pane fade" id="pet-quiz" role="tabpanel" aria-labelledby="mission-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'pet-quiz' }">
          <PetQuiz :item="item" />
        </div>
        <div class="tab-pane fade" id="pet-health-tests" role="tabpanel" aria-labelledby="mission-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'pet-health-tests' }">
          <PetHealthTests :item="item" />
        </div>
        <div class="tab-pane fade" id="pet-appointments-tests" role="tabpanel" aria-labelledby="appointments-tab"
          tabindex="0" :class="{ 'show active': selectedTab == 'pet-appointments' }">
          <Appointments :propFilters="{ user_id: item?.owner?.id }" />
        </div>
      </div>

    </Container>
  </div>
</template>
<script>
import PetDetailController from "./PetDetailController";
import PetQuiz from "./PetQuiz/PetQuiz.vue";
export default PetDetailController;
</script>
