<template>
  <div id="add-admin">
    <Navbar :current="id ? 'Editar Especialista' : 'Adicionar Especialista'" :link="$paths.specialists.list"
      :linkName="'Especialistas'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar Especialista' : 'Adicionar Especialista'" />
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Informações do especialista</h3>
          <div class="row">
            <div class="col-12 col-md-6">
              <TextInput label="Nome" :required="true" :model="$v.form.name" :maxLength="30" type="text"
                placeholder="Informe um nome" />
            </div>
            <div class="col-12 col-md-6">
              <TextInput label="Email" :required="true" :model="$v.form.email" :maxLength="50" type="email"
                placeholder="nome@email.com" />
            </div>
            <template v-if="!id">
              <div class="col-12 col-md-6">
                <PasswordInput label="Senha" :required="true" :maxLength="30" :model="$v.form.password"
                  placeholder="Informe uma senha" />
              </div>
              <div class="col-12 col-md-6">
                <PasswordInput label="Confirme a senha" :required="true" :maxLength="30"
                  :model="$v.form.passwordConfirmation" placeholder="Confirme a senha" />
              </div>
            </template>
            <div class="col-12 col-md-12">
              <TextInput label="Formação" :required="true" :maxLength="100" :model="$v.form.specialist_info.education"
                type="text" placeholder="Informe sobre o nível profissional do especialista" />
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput :required="true" label="Resumo da biografia" :model="$v.form.specialist_info.bio" type="text"
                :maxLength="DESCRIPTION_MAX_LENGTH" placeholder="Informe uma descrição">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{ DESCRIPTION_MAX_LENGTH -
                      form.specialist_info.bio.length
                    }} caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>
            <div class="col-12 col-md-12">
              <label for="" class="form-label text-muted">Especialidades <i class="text-danger">*</i></label>
              <div v-for="(item, index) in $missionsService.pillars.filter(sp => sp.id != 'none')"
                v-bind:key="'option_' + index" class="col-3 mt-3 mb-3">
                <input type="checkbox" style="display: none;" :id="item.id" :value="item.id" v-model="specialtiesChecked"
                  @change="handleSpecialtiesChanged">
                <label :for="item.id" class="">
                  <span :for="item.id" v-if="specialtiesChecked.includes(item.id)"
                    class="fa-solid fa-circle-check fa-medium-size mb-0 text-primary me-2"></span>
                  <span :for="item.id" v-else class="far fa-circle fa-medium-size mb-0 text-muted me-2"></span>
                  {{ item.title }}
                </label>
              </div>
              <p v-if="$v.form.specialist_info.specialties.$invalid" class="text-danger">* Preencha ao menos uma
                especialidade</p>
            </div>
            <div class="col-12" v-if="!id">
              <label class="text-muted mb-3 mt-3 form-label">Foto de perfil <i class="text-danger">*</i></label>
              <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="1" v-on:cropEnd="cropEnd" />
              <p v-if="$v.form.image.$invalid" class="text-danger">* Selecione uma imagem de perfil</p>
            </div>
            <div class="col-12 mb-3" v-else>
              <label class="text-muted mb-3 mt-3 form-label">Imagem</label>
              <div class="w-15 position-relative" style="width:150px; height:150px">
                <div style="right: -8px; bottom: -8px; z-index: 3" class="position-absolute">
                  <button @click="editImageClicked()" class="btn btn-primary rounded-circle"><span
                      class="fas fa-pencil"></span></button>
                  <button v-if="form.image" @click="removeImage()" class="btn btn-secondary rounded-circle ms-2"><span
                      class="fas fa-trash-alt"></span></button>
                </div>
                <img v-if="form.image" :src="form.image.base64" alt="..." class="rounded-circle avatar avatar-xxl"
                  style="height:100%; width: 100%; object-fit: fill">
                <img v-else :src="`${$baseImageUrl}${existingImage}`" alt="..." class="rounded-circle avatar avatar-xxl"
                  style="height:100%; width: 100%; object-fit: fill">
              </div>
            </div>
            <h3 id="schedule-container-title" class="my-3">Horário de atendimento</h3>
            <div v-for="(wDay, weekDayIndex) in $v.form.workingHours.$each.$iter" v-bind:key="'tr-' + weekDayIndex"
              class="col-12 col-xl-12 border-bottom mt-3 pb-3">
              <div class="row align-items-center d-flex">
                <div class="col-3">
                  <button class="btn btn-link ps-0 d-flex" @click="handleChangeAvailability(weekDayIndex)">
                    <span v-if="wDay.$model.isAvailable" class="fas fa-check-square ms-2 med-size"></span>
                    <span v-else class="far fa-square ms-2 med-size text-muted"></span>
                    <span class="fw-bold text-dark ms-2">{{ wDay.$model.title }}</span>
                  </button>
                </div>
                <div class="col-auto">
                  <span v-if="!wDay.$model.isAvailable" class="text-gray-800">Indisponível</span>
                </div>
                <div class="col-auto d-flex flex-1 justify-content-end">
                  <button class="btn btn-link text-dark" @click="addTime(weekDayIndex)">
                    <span class="fas fa-plus med-size"></span>
                  </button>
                  <button v-if="wDay.$model.isAvailable" class="btn btn-link text-dark"
                    @click="handleCopyTimesClicked(weekDayIndex)">
                    <span class="fa-regular fa-copy med-size"></span>
                  </button>
                </div>
              </div>
              <div v-for="(hour, hourIndex) in wDay.hours.$each.$iter" v-bind:key="'hour-' + hourIndex">
                <div class="row">
                  <div class="col-12 col-md-2">
                    <TextInput label="Inicio" :mask="$helper.timeMask" errorMsg="Horário inválido"
                      :model="hour.start_time" type="text" placeholder="Inicio" />
                  </div>
                  <div class="col-12 col-md-2">
                    <TextInput label="Final" :mask="$helper.timeMask" errorMsg="Informe um horário maior que o início"
                      :model="hour.end_time" type="text" placeholder="Final" />
                  </div>
                  <div class="col-12 col-md-8 d-flex">
                    <button class="btn btn-link ps-0 text-dark" @click="removeTime(weekDayIndex, hourIndex)">
                      <span class="fa-regular fa-trash-alt med-size"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="$v.form.workingHours.$invalid" class="text-danger mt-2">* Preencha os horários corretamente, ou
              remova-os</p>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton @onClick="id ? edit() : create()" :label="'Salvar'" :loading="submitting"
              :disabled="$v.$invalid" />
          </form>
        </div>
      </div>
      <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="1" v-on:cropEnd="cropEnd"
        inputId="specialist-edit-image-input" :hiddenInput="true" />
    </Container>
    <ModalComponent :title="copyModalTitle" id="copyTimesModal">
      <template v-slot:body>
        <div>
          <span>Copiar horários para</span>
          <div v-for="(item, index) in form.workingHours" v-bind:key="'option_' + index" class="col-12 mt-3 mb-3">
            <div v-if="item.weekday != timesToCopy?.weekday">
              <input type="checkbox" style="display: none;" :id="item.weekday" :value="item.weekday"
                v-model="copyDaysChecked">
              <label :for="item.weekday" class="cursor-pointer">
                <span :for="item.weekday" v-if="copyDaysChecked.includes(item.weekday)"
                  class="fa-solid fa-square-check fa-medium-size mb-0 text-primary me-2"></span>
                <span :for="item.weekday" v-else class="far fa-square fa-medium-size mb-0 text-muted me-2"></span>
                {{ item.title }}
              </label>
            </div>
            <div v-else class="opacity-25">
              <label :for="item.weekday" class="cursor-pointer text-decoration-line-through">
                <span :for="item.weekday" class="fa-solid fa-square-check fa-medium-size mb-0 text-primary me-2"></span>
                {{ item.title }}
              </label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" @click="handleCancelCopy" class="btn btn-white w-15">
          Cancelar
        </button>
        <button type="button" @click="handleCopy" class="btn btn-primary w-15">
          Aplicar
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import AddSpecialistsController from "./AddSpecialistsController";
export default AddSpecialistsController;
</script>
<style scoped></style>
