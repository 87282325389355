import {
  email,
  required,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "AddTasks",
  mounted() {
    if (this.$route.query?.user_id) {
      this.$set(this.form, "user_id", this.$route.query.user_id);
      this.getUserInfo(this.$route.query.user_id);
    }
    if (this.id) {
      this.getData();
    }
    if (this.userSession?.type == "specialist" && !this.id) {
      this.$set(this.form, "specialist_id", this.userSession.id);
    }
    this.getSpecialists();
    this.getOwners();
    this.getQuizzes();
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },

  data() {
    return {
      id: this.$route.params.id,
      user: null,
      TITLE_MAX_LENGTH: 50,
      errorMessage: "",
      submitting: false,
      specialistList: [],
      ownersList: [],
      quizList: [],
      form: {
        specialist_id: "", //opcional
        user_id: "",
        type: "free",
        title: "",
        pillar: "",
        target: "",
        target_id: "", //opcional
        file: null, //opcional
        delete_file: false, //opcional
      },
    };
  },
  validations: {
    form: {
      file: "",
      pillar: "",
      specialist_id: "",
      target_id: {
        required: requiredIf(function () {
          return this.form.target == "quiz";
        }),
      },
      user_id: {
        required,
      },
      type: {
        required,
      },
      target: {
        required,
      },
      title: {
        required,
        minLength: minLength(5),
      },
    },
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        let values = { ...this.form };
        let res;
        if (values.target !== "first_health_test") {
          delete values.file;
        }
        if (values.target != "appointment") {
          values.pillar = "";
        }
        if (!this.id) {
          delete values.delete_file;
          res = await this.$tasksService.create(values);
          this.$eventBus.$emit("showToast", {
            title: "Tarefa criada",
            msg: `Tarefa criada com sucesso`,
          });
          this.$router.push({ path: `${this.$paths.tasks.list}` });
        } else {
          //editando
          if (values.file === null) {
            delete values.file;
          }
          res = await this.$tasksService.update(this.id, values);
          this.$eventBus.$emit("showToast", {
            title: "Tarefa alterada",
            msg: `Tarefa alterada com sucesso`,
          });
          this.$router.back();
        }
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    async getUserInfo(id) {
      try {
        const res = await this.$userService.show(id);
        this.$set(this, "user", res.data);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    async getQuizzes() {
      try {
        const res = await this.$quizService.list();
        this.$set(this, "quizList", res.data);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    async getSpecialists() {
      try {
        const res = await this.$userService.list({ type: "specialist" });
        this.$set(this, "specialistList", res.data);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    async getOwners() {
      try {
        const res = await this.$userService.list({ type: "user" });
        this.$set(this, "ownersList", res.data);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    targetChanged(e) {
      this.$set(this.form, "target_id", "");
    },
    handleAddFile() {
      document.getElementById("task-file-input").click();
    },

    removeFile() {
      if (this.id) {
        //editando
        this.$set(this.form, "delete_file", 1);
      }
      this.$set(this.form, "file", null);
    },

    cropEnd(file) {
      this.$set(this.form, "file", file.file);
      this.$set(this.form, "delete_file", 0);
    },

    async getData() {
      try {
        const res = await this.$tasksService.show(this.id);
        if (res.data.user_id) {
          this.getUserInfo(res.data.user_id);
        }
        let f = {
          specialist_id: res.data.specialist_id || "",
          user_id: res.data.user_id,
          type: res.data.type,
          title: res.data.title,
          target: res.data.target,
          target_id: res.data.target_id,
          file: res.data.file,
          pillar: res.data.pillar,
        };
        this.$set(this, "form", f);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
  },
};
