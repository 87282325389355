import DetailMixinController from '@/mixins/DetailMixinController';
import { weekdays } from '@/utils/HelperVariables';

export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$userService',
      hasDelete: true
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      weekDays: weekdays.full
    };
  },
  methods: {
    prepareResponse(data) {
      return this.$userService.getFormattedUserAccount(data)
    },
  }
}