import ListControllerMixin from "../../mixins/ListControllerMixin";
export default {
  name: "Users",
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      filters: {
        page: 1,
        q: "",
        type: "user",
      },
      columns: [
        {
          name: "Nome",
          sortName: "name",
          order: null,
        },
        {
          name: "Email",
          sortName: "email",
          order: null,
        },
        {
          name: "Assinante",
          sortName: null,
          order: null,
        },
        {
          name: "Data de assinatura",
          sortName: null,
          order: null,
        },
        {
          name: "Convites aceitos",
          sortName: null,
          order: null,
        },
        {
          name: "Convidados assinantes",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: "created",
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    prepareResponse(data) {
      return data.map((elem) => {
        return {
          ...elem,
          subscriptionInfo: this.$userService.getSubscriptionInfo(elem),
        };
      });
    },
  },
};
