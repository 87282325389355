import DetailMixinController from '@/mixins/DetailMixinController';
import { url, required, minLength } from 'vuelidate/lib/validators';
export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$appointmentsService'
    }
  },
  watch: {
    diagnosis: function (value) {
      if (!value || this.$v.diagnosis.$error || !this.canEditDiagnosis) return
      // pra nao espamar a api, depois de 800ms sem escrever, ele salva
      clearTimeout(this.timeoutConclusion)
      this.timeoutConclusion = setTimeout(() => {
        this.$appointmentsService.update(this.id, { diagnosis: value }).then().catch()
      }, 800);
    },

  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    canSeeRating() {
      return this.$permissionService.isAllowed([this.$permissionService.ROLES.ADMIN])
    },
    canEditDiagnosis() {
      console.log(this.item.status != 'concluded')
      console.log(this.item.status != 'concluded')
      return this.$permissionService.isAllowed([this.$permissionService.ROLES.SPECIALIST]) && this.item.status != 'canceled'
    }
  },
  data() {
    return {
      DIAGNOSIS_MAX_LENGTH: 2400,
      timeoutConclusion: null,
      canEdit: false,
      call_link: '',
      diagnosis: '',
      loadingAction: false,
    };
  },
  validations: {
    call_link: { url, required },
    diagnosis: { required, minLength: minLength(10) }
  },
  methods: {
    prepareResponse(data) {
      data.pillarObject = this.$missionsService.getMissionPillar(data.pillar)
      data.statusObject = this.$appointmentsService.getAppointmentStatus(data.status)
      data.formatted_day = this.$moment(data.date).format('DD/MM/YYYY')
      data.formatted_time = `${this.$moment(data.date).format('HH:mm')} as ${this.$moment(data.date).add(30, 'm').format('HH:mm')}`
      data.formatted_created_at = `${this.$moment(data.created_at).format('DD/MM/YYYY')}`
      this.$set(this, 'item', data)
      this.$set(this, 'call_link', data.call_link)
      this.$set(this, 'diagnosis', data.diagnosis || '')

      this.$set(this, 'canEdit', this.$permissionService.isAllowed([this.$permissionService.ROLES.SPECIALIST]) && data.status == 'scheduled')
      return data
    },
    handleNotAvailable() {
      if (!this.canEdit) return
      this.$eventBus.$emit("showConfirmationAlert", {
        title: 'Informar indisponibilidade de horário',
        message: "Caso informe que você não está disponível para uma sessão nesta data, o usuário será avisado e poderá solicitar uma nova data. Deseja continuar?",
        confirmCallback: async () => {
          try {
            this.$appointmentsService.update(this.id, { status: 'pending_reschedule' }).then(r => this.getData()).catch()
            this.$eventBus.$emit('showToast', { title: 'Informações salvas', msg: `Indisponibilidade confirmada` })
          } catch (e) {
            console.log(e);
          } finally {

          }
        },
      });
    },
    handleConclude() {
      if (!this.canEdit) return
      this.$eventBus.$emit("showConfirmationAlert", {
        title: 'Concluir sessão',
        message: "Você realizou essa sessão?",
        confirmCallback: async () => {
          try {
            this.$appointmentsService.update(this.id, { status: 'concluded' }).then(r => this.getData()).catch()
            this.$eventBus.$emit('showToast', { title: 'Informações salvas', msg: `sessão encerrada` })

          } catch (e) {
            console.log(e);
          } finally {

          }
        },
      });
    },
    handleCancel() {
      if (!this.canEdit) return
      this.$eventBus.$emit("showConfirmationAlert", {
        title: 'Cancelamento de sessão',
        message: "Deseja cancelar essa sessão?",
        confirmCallback: async () => {
          try {
            this.$appointmentsService.update(this.id, { status: 'canceled', cancel_reason: 'Cancelado pelo especialista' }).then(r => this.getData()).catch()
            this.$eventBus.$emit('showToast', { title: 'Informações salvas', msg: `Sessão cancelada` })
          } catch (e) {
            console.log(e);
          } finally {

          }
        },
      });
    },
    goToRoom() {
      window.open(this.item?.call_link, '_blank');
    }
  }
}