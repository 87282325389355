<template>
  <div id="pet_summary">
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
            <LoadingContainer v-if="loadingItems" />
            <div v-else>
              <EmptyState v-if="!quizzes.length" :totalItemsRegistered="totalItemsRegistered">
                <template v-slot:noItemsRegistered>
                  <p>Nenhum questionário criado</p>
                </template>
              </EmptyState>
              <div v-else class="table-responsive">
                <table class="
                    table table-sm table-nowrap
                    card-table
                    table-hover
                    cursor-pointer
                  ">
                  <TableHead :columns="columns" v-on:sort="sort" />
                  <tbody class="list">
                    <router-link tag="tr" v-for="(item, index) in quizzes" v-bind:key="'tr-' + index"
                      :to="`${$paths.quizzes.detail}/${item.id}`">
                      <td class="fw-bolder">{{ item.title }}</td>
                      <td>{{ item.pillarObject.title }}</td>
                      <td class="text-end" >
                        <time>{{
                        $moment(item.created_at).format("DD/MM/YYYY")
                        }}</time>
                      </td>
                    </router-link>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PetQuizController from "./PetQuizController";
export default PetQuizController;
</script>
