import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Nome do pet',
          sortName: 'name',
          order: null
        },
        {
          name: 'Nome do tutor',
          sortName: null,
          order: null
        },
        {
          name: 'Pet adicionado',
          sortName: 'created_at',
          order: null
        },
        {
          name: 'Tipo de conta',
          sortName: 'plan_id',
          order: null
        },
        {
          name: 'Missões em andamento',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => {
        let owner = elem.owners[0]?.user
        let account = owner && owner.plan_status == 'paid' ? 'Assinante' : 'Não assinante'
        return {
          ...elem,
          owner,
          subscriptionInfo: this.$userService.getSubscriptionInfo(owner),
          missionsLength: elem.missions.filter(pm => pm.status != 'done' && pm.status != 'canceled').length,
          formatted_created_at: this.$moment(elem.created_at).format('DD/MM/YYYY')

        }
      })
    },
    fetchPromise() {
      return this.$petService.list({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    },
  }
};