<template>
    <div id="events">
        <Navbar current="Eventos" />
        <Sidebar />
        <Container col="col-12">
            <Header :isSubHeader="false" title="Eventos" subtitle="Registro dos eventos"
                :iconClass="'fa-regular fa-calendar-days text-primary'" :totalCount="totalItemsRegistered">
                <template v-slot:button v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
                    <router-link :to="`${$paths.events.add}`" tag="a" class="btn lift btn-primary">
                        <i class="fa-solid fa-circle-plus"></i>
                        Adicionar
                    </router-link>
                </template>
            </Header>

            <div class="card">
                <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
                <LoadingContainer v-if="loadingItems" />
                <div v-else>
                    <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
                        <template v-slot:noItemsRegistered>
                            <span class="fa-3x fa-regular fa-calendar-days text-black-50 mb-2"></span>
                            <p>Nenhum evento adicionado</p>
                        </template>
                    </EmptyState>
                    <div v-else class="table-responsive">
                        <table class="
                  table table-sm table-nowrap
                  card-table
                  table-hover
                  
                ">
                            <TableHead :columns="columns" v-on:sort="sort" />
                            <tbody class="list">
                                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index">
                                    <td>
                                        <!-- Avatar -->
                                        <div class="avatar avatar-xs align-middle me-2">
                                            <img class="avatar-img rounded" :src="`${$baseImageUrl}${item.file}`" alt="...">
                                        </div>

                                    </td>
                                    <td width="100%">{{ item?.title }}</td>
                                    <td class="text-end">
                                        <time>{{
                                            $moment(item.date).format("DD/MM/YYYY")
                                        }}</time>
                                    </td>
                                    <td>{{ item?.count }}</td>

                                    <td class="text-end">
                                        <time>{{
                                            $moment(item.created_at).format("DD/MM/YYYY")
                                        }}</time>
                                    </td>
                                    <td class="text-end">
                                        <!-- Dropdown -->
                                        <div class="dropdown">
                                            <a class="dropdown-ellipses dropdown-toggle" href="#" role="button"
                                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe fe-more-vertical"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <a :href="item?.link" class="dropdown-item" target="_blank">
                                                    Visualizar evento
                                                </a>
                                                <router-link :to="`${$paths.events.edit}/${item?.id}`" tag="a"
                                                    class="dropdown-item">
                                                    Editar evento
                                                </router-link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!loadingItems">
                <div class="col-12  text-end">
                    <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
                        <b>{{ countItemsTotal }}</b> resultado(s)</span>
                </div>
            </div>
            <div class="row justify-event-center" v-if="!loadingItems && moreItemsToLoad">
                <div class="col-2">
                    <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
                </div>
            </div>
        </Container>
    </div>
</template>
<script>
import EventsController from "./EventsController";
export default EventsController;
</script>
  