import NetworkService from '@/services/NetworkService.js'

export default class ContentsService {
    constructor() {
        this.network = new NetworkService()
    }
    create = (params) => {
        return this.network.postMultipart(this.network.endpoints.contents, params)
    }
    list = (params) => {
        return this.network.get(this.network.endpoints.contents, params)
    }
    show = (id) => {
        return this.network.get(`${this.network.endpoints.contents}/${id}`)
    }
    delete = (id) => {
        return this.network.delete(`${this.network.endpoints.contents}/${id}`)
    }
    update = (id, params) => {
        return this.network.postMultipart(`${this.network.endpoints.contents}/${id}/update`, params)
    }
}