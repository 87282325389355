<template>
  <div class="main-day-container p-3 cursor-pointer position-relative" @mouseover="hover = true"
    @mouseleave="hover = false">
    <div class="d-flex flex-1 justify-content-between mb-3">
      <span class="fw-bolder small" :class="{ 'text-black': isToday, 'text-gray-600': !isToday }">
        {{ formattedDay }}
      </span>
      <span v-if="isToday" class="text-primary fw-bolder small">
        Hoje <span class="fas fa-calendar-day"></span>
      </span>
    </div>
    <template v-if="userSession.online && isToday">
      <p class="m-0 p-0 text-gray-500 fw-bold small mb-2">
        Em Plantão
      </p>
    </template>
    <template v-else>
      <p v-if="!day.availableTimes?.length" class="m-0 p-0 text-gray-500 fw-bold small">
        Indisponível
      </p>
    </template>
    <template>
      <p v-for="time in day.availableTimes" :key="time.date" class="m-0 p-0 text-gray-500 fw-bold small"></p>
      <p v-for="time in day.availableTimes" :key="time.date" class="m-0 p-0 text-gray-500 fw-bold small">
        {{ time.from.slice(0, 5) }} - {{ time.to.slice(0, 5) }}
      </p>
    </template>

    <router-link v-for="appointment in day.appointments" tag="a" :key="appointment?.id"
      :to="`${$paths.appointments.detail}/${appointment?.id}`" class="m-0 p-0 text-black fw-bolder small">
      {{ $moment(appointment.date).format("HH:mm") }}
      {{ appointment.user?.name }}
      <p class="mb-0" v-if="appointment.pillar == 'medical_duty' && !userSession.online">
        <span class="badge bg-primary-soft">Plantão</span>
      </p>
    </router-link>

    <div v-if="hover && !isInThePast" class="btn-group edit-btn-group position-absolute p-0">
      <button type="button" class="btn btn-primary edit-btn p-0" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="fas fa-pencil-alt"></span>
      </button>

      <ul class="dropdown-menu">
        <li @click="emitEditCurrentDayEvent()">
          <a class="dropdown-item">
            <span class="fas fa-calendar-day text-black me-2"></span> Editar
            somente este dia
          </a>
        </li>
        <li>
          <a class="dropdown-item" @click="emitEveryWeekDayDayEvent()">
            <span class="fas fa-refresh text-black me-2"></span> Editar
            {{ formattedWeekDay }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import AgendaDayController from "./AgendaDayController";
export default AgendaDayController;
</script>
<style scoped>
div.main-day-container {
  min-height: 120px;
}

.edit-btn {
  border-radius: 50% !important;
}

.edit-btn-group {
  right: 10px;
  bottom: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.dropdown-item:hover {
  background-color: #cb0e8726;
}
</style>
