import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Titulo',
          sortName: 'title',
          order: null
        },
        {
          name: 'Pilar',
          sortName: 'pillar',
          order: null
        },
        {
          name: 'Questões',
          sortName: null,
          order: null
        },
        {
          name: 'Criação',
          sortName: 'created_at',
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => ({
        ...elem,
        formatted_created_at: this.$moment(elem.created_at).format('DD/MM/YYYY'),
        pillarObject: this.$missionsService.getMissionPillar(elem.pillar)
      }))
    },
    fetchPromise() {
      return this.$quizService.list({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    }
  }
};