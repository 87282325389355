import moment from 'moment';

export default {
  name: "AgendaDay",
  props: {
    day: {
      type: Object,
      defaut: {}
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession
    },
    momentFormattedDate() {
      return moment(this.day.date)
    },
    formattedDay() {
      return this.momentFormattedDate.format('DD')
    },
    isToday() {
      return this.momentFormattedDate.isSame(moment(), 'day')
    },
    isInThePast() {
      return this.momentFormattedDate.isBefore(moment(), 'day')
    },
    formattedWeekDay() {
      const w = this.momentFormattedDate.day()
      switch (w) {
        case 0: return 'todos os domingos'
        case 1: return 'todas as segundas-feiras'
        case 2: return 'todas as terças-feiras'
        case 3: return 'todas as quartas-feiras'
        case 4: return 'todas as quintas-feiras'
        case 5: return 'todas as sextas-feiras'
        case 6: return 'todos os sábados'
      }
      return ''
    }
  },
  mounted() {
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    emitEditCurrentDayEvent() {
      this.$emit("onEditCurrentDay", { ...JSON.parse(JSON.stringify(this.day)) })
    },
    emitEveryWeekDayDayEvent() {
      this.$emit("onEditWeekDay", { ...JSON.parse(JSON.stringify(this.day)) })
    },
  }
};

