<template>
  <div id="pet_summary">
    <div>

      <div class="row">
        <template v-if="pillar === ''">
          <div class="col-12 col-lg-6">
            <a class="card lift" href="#pet-missions">
              <div class="card-body">
                <h3 class="text-dark fw-bold">
                  <span class="ms-2">
                    Missões em andamento
                  </span>
                </h3>
                <div class="display-3 my-3 text-dark">
                  {{summary?.pending?.total}}
                </div>
              </div>
            </a>
          </div>
          <div class="col-12 col-lg-3">
            <a class="card lift" href="#pet-missions">
              <div class="card-body">
                <h3 class="text-dark fw-bold">
                  <span class="ms-2">
                    Missões atrasadas
                  </span>
                </h3>
                <div class="display-3 my-3 text-dark">
                  {{summary?.late?.total || 0}}
                </div>
              </div>
            </a>
          </div>
          <div class="col-12 col-lg-3">
            <a class="card lift" href="#pet-missions">
              <div class="card-body">
                <h3 class="text-dark fw-bold">
                  <span class="ms-2">
                    Missões finalizadas
                  </span>
                </h3>
                <div class="display-3 my-3 text-dark">
                  {{summary?.done?.total || 0}}
                </div>
              </div>
            </a>
          </div>
        </template>
        <div class="col-12">
          <div class="card">
            <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
            <LoadingContainer v-if="loadingItems" />
            <div v-else>
              <EmptyState v-if="!missions.length" :totalItemsRegistered="totalItemsRegistered">
                <template v-slot:noItemsRegistered>
                  <p v-if="pillar === ''">Nenhuma missão criada</p>
                  <p v-else>Nenhuma vacina criada</p>
                </template>
              </EmptyState>
              <div v-else class="table-responsive">
                <table class="
                    table table-sm table-nowrap
                    card-table
                    table-hover
                    cursor-pointer
                  ">
                  <TableHead :columns="columns" v-on:sort="sort" />
                  <tbody class="list">
                    <router-link tag="tr" v-for="(item, index) in missions" v-bind:key="'tr-' + index"
                      :to="`${$paths.missions.detail}/${item.id}`">
                      <td class="fw-bolder">{{ item.title }}</td>
                      <td>{{ item.pillarObject.title }}</td>
                      <td>
                        <time>{{
                        $moment(item.created_at).format("DD/MM/YYYY")
                        }}</time>
                      </td>
                      <td class="text-end"><span :class="`${item.statusObject.badgeClass}`">{{ item.statusObject.title
                      }}</span></td>
                    </router-link>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PetMissionsController from "./PetMissionsController";
export default PetMissionsController;
</script>
