import ChatService from "@/services/ChatService"
import WebSocketService from "@/services/WebSocketService"
import store from "@/store"
import router from '../router'
import { EventBus } from '@/utils/EventBus'

const chatService = new ChatService()
async function setupChatSocketListener() {
    const pusherInstance = new WebSocketService().getInstance()
    let channel = await pusherInstance.subscribe('chats')
    channel.bind('new-message', async (data) => {
        console.log(data)
        if (router.currentRoute.path.indexOf(`/chats/detalhes/${data.message?.chat_id}`) !== -1) return // se nao estiver na tela do chat em si, ele atualiza o badge
        EventBus.$emit('newMessage')
        setupChatBadge()
    })
}
async function setupChatBadge() {
    try {
        const res = await chatService.checkForReadMessages()
        store.commit('setUserInfo', { chatBadge: res.has_unread_messages })
    } catch (e) {
        console.log(e)
    } finally {
    }
}
setupChatBadge()
setupChatSocketListener()
