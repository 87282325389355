<template>
  <div id="add-admin">
    <Navbar :current="id ? 'Editar Tarefa' : 'Adicionar Tarefa'" :link="$paths.missions.list" :linkName="'Tarefas'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar Tarefa' : 'Adicionar Tarefa'" />
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-8">
              <TextInput label="Título" :required="true" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title" type="text"
                placeholder="Informe um título para a tarefa" :errorMsg="'Informe um título com ao menos 5 caracteres'" />
            </div>
            <div class="col-12 col-md-4">
              <TextInput v-if="user" :required="true" :readOnly="true" label="Tutor" :value="user.name" />
              <SelectInput v-else label="Tutor" :required="true" :model="$v.form.user_id" :options="ownersList"
                :propertyForText="'name'" />
            </div>
            <div class="col-12 col-md-4">
              <SelectInput :required="true" label="Tipo" :model="$v.form.type" :options="$tasksService.taskTypes" />
            </div>
            <div class="col-12 col-md-8" v-if="userSession?.type != 'specialist'">
              <SelectInput :required="true" label="Especialista" :model="$v.form.specialist_id" :options="specialistList"
                :propertyForText="'name'" />
            </div>
            <div class="col-12 col-md-8" v-else>
              <TextInput :readOnly="true" :required="true" label="Especialista" :value="userSession.name" />
            </div>
            <div class="col-12 col-md-12">
              <SelectInput label="Categoria" :required="true" :model="$v.form.target"
                :options="$tasksService.taskTargets.filter(t => t.active)" @selectChanged="targetChanged" />
            </div>
            <div v-if="form.target === 'first_health_test'" class="col-12">
              <button v-if="!form.file" @click="handleAddFile" class="btn btn-link ps-0"> <span
                  class="fas fa-circle-plus"></span>
                Adicionar arquivo</button>
              <div class="col-md-2 col-2 card border rounded-4 mx-3" style="height: 170px" v-if="form.file">
                <div class="card-header pb-0 px-1" style="height:35px">
                  <div class="col">
                    <small class="ps-2"> <strong>{{
                      'Arquivo.pdf'
                    }}</strong></small>
                  </div>
                  <div class="col justify-content-end d-flex">
                    <button class="btn btn-link text-danger ps-0" @click="removeFile()">
                      <span class="fa-solid fa-trash-alt"></span>
                    </button>
                  </div>
                </div>
                <div class="card-body justify-content-center align-items-center d-flex">
                  <span class="fas fa-file-pdf display-2 text-muted"></span>
                </div>
              </div>
              <div>
                <label class="text-muted mb-3 mt-3 form-label"></label>
                <ImageCropper accept=".pdf" :customFileInputWidth="100" :aspectRatio="1" v-on:cropEnd="cropEnd"
                  inputId="task-file-input" :hiddenInput="true" />
              </div>
            </div>
            <div v-if="form.target == 'quiz'" class="col-12 col-md-7">
              <SelectInput :required="true" label="Quiz" :model="$v.form.target_id" :options="quizList" />
            </div>
            <div v-if="form.target == 'appointment'" class="col-12 col-md-7">
              <SelectInput :required="true" label="Pilar" :model="$v.form.pillar"
                :options="$missionsService.pillars.filter(mp => mp.id !== 'none' && mp.id !== 'vaccination')" />
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PermissionService from '@/services/PermissionService';
import TaskAddController from "./TaskAddController";
export default TaskAddController;
</script>
<style scoped></style>
