import { required, minLength, url } from "vuelidate/lib/validators";
export default {
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      existingImage: "",
      TITLE_MAX_LENGTH: 120,
      CONTENT_MAX_LENGTH: 7000,
      errorMessage: "",
      submitting: false,
      form: {
        file: "",
        title: "",
        link: "",
        date: "",
      },
    };
  },
  validations() {
    return {
      form: {
        file: !this.id
          ? {
              required,
            }
          : "",
        title: {
          required,
          minLength: minLength(10),
        },
        link: {
          required,
          url,
        },
        date: {
          required,
          isAfter: (value) => {
            if (this.id) {
              return true;
            } else {
              return this.$moment(value).isAfter();
            }
          },
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        const values = { ...this.form };
        if (values.file) {
          values.file = values.file.file;
        }

        if (!this.id) {
          await this.$eventsService.create(values);
          this.$eventBus.$emit("showToast", {
            title: "Evento criado",
            msg: `Evento criado com sucesso`,
          });
          this.$router.push({ path: `${this.$paths.events.list}` });
        } else {
          await this.$eventsService.update(this.id, values);
          this.$eventBus.$emit("showToast", {
            title: "Evento alterada",
            msg: `Evento alterada com sucesso`,
          });
          this.$router.back();
        }
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    cropEnd(image) {
      console.log("CROP END", image);
      this.$set(this.form, "file", { ...image });
    },
    editImageClicked() {
      document.getElementById("event-add-message-input").click();
    },
    removeImage() {
      this.$set(this.form, "file", "");
    },
    async getData() {
      try {
        const res = await this.$eventsService.show(this.id);
        let f = {
          title: res.data.title,
          // description: res.data.description,
          date: res.data.date,
          link: res.data.link,
        };
        this.$set(this, "existingImage", res.data.file);
        this.$set(this, "form", f);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
