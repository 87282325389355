import Pusher from "pusher-js";

export default class WebSocketService {
  constructor() {}
  getInstance() {
    return new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      // encrypted: process.env.VUE_APP_PUSHER_ENCRIPTION,
      // disableStats: true,
      // wsHost: process.env.VUE_APP_PUSHER_HOST,
      // wsPort: process.env.VUE_APP_PUSHER_PORT,
      // wssPort: process.env.VUE_APP_PUSHER_PORT,
      // forceTLS: process.env.VUE_APP_PUSHER_TLS
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    });
  }
}
