import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Titulo',
          sortName: 'title',
          order: null
        },
        {
          name: 'Tipo',
          sortName: 'type',
          order: null
        },
        {
          name: 'Status',
          sortName: 'status',
          order: null
        },
        {
          name: 'Criação',
          sortName: 'created_at',
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => ({
        ...elem,
        statusObject: this.$tasksService.getTaskStatus(elem.status),
        typeObject: this.$tasksService.getTaskType(elem.type),
      }))
    },
    fetchPromise() {
      return this.$tasksService.list({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    }
  }
};