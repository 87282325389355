<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="fw-bold pb-0 mb-0">{{ formattedMonth }}</h2>
          <div>
            <button @click="handlePreviuosMonth()" class="btn btn-white">
              <span class="fas fa-chevron-left"></span>
            </button>
            <button @click="handleNextMonth()" class="btn btn-white ms-3">
              <span class="fas fa-chevron-right"></span>
            </button>
            <button @click="handleToday()" class="btn btn-white ms-3">
              Hoje
            </button>
            <button @click="handleMyAgenda()" class="btn btn-white ms-3">
              <span class="fa-regular fa-calendar"></span>
              Meus horários
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="calendar-days" class="card mt-4">
      <div id="calendar-header" class="card-header px-0">
        <div class="col-12 d-flex" id="calendar-header-row">
          <div
            v-for="day in ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB']"
            v-bind:key="day"
            class="d-flex flex-1 justify-content-center"
          >
            <span class="fw-bold">
              {{ day }}
            </span>
          </div>
        </div>
      </div>
      <!-- Loading Items -->
      <div
        v-if="loadingItems"
        id="calendar-body"
        class="col-12 d-flex flex-column"
      >
        <div
          v-for="(week, weekIndex) in Array(6)"
          v-bind:key="new Date().getTime() + weekIndex"
          class="d-flex calendar-row border-bottom"
        >
          <div
            v-for="(day, dayIndex) in Array(7)"
            v-bind:key="new Date().getTime() + 'd' + dayIndex"
            class="flex-1"
            :class="{ 'border-end': dayIndex < 6 }"
          >
            <div class="placeholder-day-container p-3">
              <div>
                <div class="placeholder-glow">
                  <div
                    class="placeholder placeholder-sm day-placeholder-line d-flex"
                    style="width: 20%"
                  ></div>
                  <div
                    class="placeholder placeholder-sm day-placeholder-line d-flex mt-4"
                    style="width: 55%"
                  ></div>
                  <div
                    class="placeholder placeholder-sm day-placeholder-line d-flex mt-1"
                    style="width: 75%"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Calendar Items -->
      <div v-else id="calendar-body" class="col-12 d-flex flex-column">
        <div
          v-for="(week, weekIndex) in monthDays"
          v-bind:key="new Date().getTime() + weekIndex"
          class="d-flex calendar-row border-bottom"
        >
          <div
            v-for="(day, dayIndex) in week"
            v-bind:key="new Date().getTime() + 'd' + dayIndex"
            class="flex-1"
            :class="{
              'border-end': dayIndex < 6,
              'bg-light': isInThePast(day),
            }"
          >
            <AgendaDay
              :day="day"
              @onEditCurrentDay="
                handleEditCurrentDay({ ...$event }, weekIndex, dayIndex)
              "
              @onEditWeekDay="handleEditWeekDay($event, weekIndex, dayIndex)"
            />
          </div>
        </div>
      </div>
    </div>
    <ModalComponent :title="modalTitle" id="editDateModal">
      <template v-slot:body>
        <div>
          <div
            v-if="!selectedEditDate?.availableTimes?.length"
            class="text-center"
          >
            <p class="mb-0">Nenhum horário ainda</p>
            <button @click="addTime()" class="btn btn-link">
              <span class="fa-regular fa-plus med-size"></span> Adicionar
              horário
            </button>
          </div>
          <div
            v-for="(hour, hourIndex) in $v.selectedEditDate.availableTimes.$each
              .$iter"
            v-bind:key="'hour-' + hourIndex"
          >
            <div class="row">
              <div class="col-12 col-md-4 pe-0">
                <TextInput
                  label=" "
                  :mask="$helper.timeMask"
                  errorMsg="Informe um horário válido"
                  :model="hour.from"
                  type="text"
                  placeholder="Inicio"
                />
              </div>
              <div
                class="col-1 align-items-center d-flex justify-content-center p-0 pb-3"
              >
                <span>-</span>
              </div>
              <div class="col-12 col-md-4 ps-0">
                <TextInput
                  label=" "
                  :mask="$helper.timeMask"
                  errorMsg="Informe um horário maior que o início"
                  :model="hour.to"
                  type="text"
                  placeholder="Final"
                />
              </div>
              <div class="col-12 col-md-3 d-flex">
                <button
                  class="btn btn-link ps-0 pt-0 text-dark"
                  @click="removeTime(hourIndex)"
                >
                  <span class="fa-regular fa-trash-alt med-size"></span>
                </button>
                <button
                  v-if="hourIndex == selectedEditDate.availableTimes.length - 1"
                  class="btn btn-link ps-0 pt-0 text-dark"
                  @click="addTime()"
                >
                  <span class="fas fa-plus med-size"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 text-center text-danger">
            <p>{{ updateErrorMsg }}</p>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          @click="handleCancelDateEdition"
          class="btn btn-white w-15"
        >
          Cancelar
        </button>
        <button
          type="button"
          @click="handleConfirmDateEdition"
          class="btn btn-primary w-15"
          :disabled="loadingUpdate || $v.selectedEditDate.$invalid"
        >
          <span
            v-if="loadingUpdate"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <!-- {{ selectedEditDate?.availableTimes.length ? 'Aplicar' : 'Tornar indisponível' }} -->
          Aplicar
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import AgendaComponentController from "./AgendaComponentController";
export default AgendaComponentController;
</script>
<style scoped>
.placeholder-day-container {
  height: 120px;
}

.day-placeholder-line {
  height: 3px;
  margin-bottom: 5px;
  border-radius: 3px;
}
</style>
