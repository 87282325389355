import DetailMixinController from '@/mixins/DetailMixinController'

export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$tasksService',
      hasDelete: true
    }
  },
  computed: {
    quizQuestions() {
      if (!this.item || this.item.target != 'quiz' || !this.item.user_answers) return null
      if (!this.item.user_answers.length) return []
      return this.item.quiz?.questions.map(q => {
        const answer = this.item.user_answers.find(a => a.question_id == q.id)
        return {
          ...q,
          answer: answer.answer
        }
      })
    }
  },
  methods: {
    prepareResponse(data) {
      data.typeObject = this.$tasksService.getTaskType(data.type)
      data.targetObject = this.$tasksService.getTaskTarget(data.target)
      data.statusObject = this.$tasksService.getTaskStatus(data.status)
      data.pillarObject = this.$missionsService.getMissionPillar(data.pillar)
      data.formatted_created_at = this.$moment(data.created_at).format('DD/MM/YYYY')
      return data
    },
    viewFile() {
      window.open(`${this.$baseImageUrl}${this.item.file}`, '_blank');
    }
  }
}