import store from "@/store";
import AddAdmins from "@/views/Admins/AddAdmins/AddAdmins.vue";
import AddSpecialists from "@/views/Specialists/AddSpecialists/AddSpecialists.vue";
import AdminDetail from "@/views/Admins/AdminDetail/AdminDetail.vue";
import SpecialistDetail from "@/views/Specialists/SpecialistDetail/SpecialistDetail.vue";
import AdminPasswordUpdate from "@/views/Admins/AdminPasswordUpdate/AdminPasswordUpdate.vue";
import Admins from "@/views/Admins/Admins.vue";
import Specialists from "@/views/Specialists/Specialists.vue";
import EditAdmins from "@/views/Admins/EditAdmins/EditAdmins.vue";
import ForgotPassword from "@/views/ForgotPassword/ForgotPassword.vue";
import Home from "@/views/Home/Home.vue";
import Login from "@/views/Login/Login.vue";

import MissionAdd from "@/views/Missions/MissionAdd/MissionAdd.vue";
import MissionDetail from "@/views/Missions/MissionDetail/MissionDetail.vue";
import Missions from "@/views/Missions/Missions.vue";

import TaskAdd from "@/views/Tasks/TaskAdd/TaskAdd.vue";
import TaskDetail from "@/views/Tasks/TaskDetail/TaskDetail.vue";
import Tasks from "@/views/Tasks/Tasks.vue";

import QuizAdd from "@/views/Quizzes/QuizAdd/QuizAdd.vue";
import QuizDetail from "@/views/Quizzes/QuizDetail/QuizDetail.vue";
import Quizzes from "@/views/Quizzes/Quizzes.vue";

import NotFound from "@/views/NotFound/NotFound.vue";
import PetDetail from "@/views/Pets/PetDetail/PetDetail.vue";
import Pets from "@/views/Pets/Pets.vue";

import Settings from "@/views/Settings/Settings.vue";
import SettingsEdit from "@/views/Settings/SettingsEdit/SettingsEdit.vue";

import UserDetail from "@/views/Users/UserDetail/UserDetail.vue";
import Users from "@/views/Users/Users.vue";

import ChatDetail from "@/views/CustomerService/Chats/ChatDetail/ChatDetail.vue";
import AppointmentDetail from "@/views/CustomerService/Appointments/AppointmentDetail/AppointmentDetail.vue";
import CustomerService from "@/views/CustomerService/CustomerService.vue";

import ContentDetail from "@/views/Contents/ContentDetail/ContentDetail.vue";
import Contents from "@/views/Contents/Contents.vue";
import ContentAdd from "@/views/Contents/ContentAdd/ContentAdd.vue";

import Events from "@/views/Events/Events.vue";
import EventAdd from "@/views/Events/EventAdd/EventAdd.vue";

import Vue from "vue";
import VueRouter from "vue-router";
import paths from "./paths";
import CrashDetail from "@/views/Crashes/CrashDetail/CrashDetail.vue";
import Crashes from "@/views/Crashes/Crashes.vue";
import Notifications from "@/views/Notifications/Notifications.vue";
import PermissionService from "@/services/PermissionService";
import MyAccount from "@/views/MyAccount/MyAccount.vue";
import Invites from '@/views/Invites/Invites.vue';
import VetsAppointments from "@/views/VetsAppointments/VetsAppointments.vue";

Vue.use(VueRouter);
const permissionService = new PermissionService();
function isLogged() {
  return store.state.userSession ? true : false;
}

function authCheck(to, from, next) {
  if (isLogged()) {
    if (to.meta?.allowedRoles) {
      if (permissionService.isAllowed(to.meta?.allowedRoles)) {
        next({ path: to });
      } else {
        next({ path: paths.not_found });
      }
    } else {
      next({ path: to });
    }
  } else {
    next({ path: paths.login });
  }
}

const routes = [
  {
    path: "/",
    redirect: {
      name: "Home",
    },
  },
  {
    path: paths.login,
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: paths.forgot_password,
    name: "Esqueci a senha",
    component: ForgotPassword,
    props: true,
  },
  {
    path: paths.home,
    name: "Home",
    component: Home,
    props: true,
    beforeEnter: authCheck,
  },

  //USERS
  {
    path: paths.users.list,
    name: "Usuários",
    component: Users,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },
  {
    path: `${paths.users.detail}/:id`,
    name: "Detalhes do Usuário",
    component: UserDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },

  //ADMINS
  {
    path: paths.admins.list,
    name: "Administradores",
    component: Admins,
    props: true,
    beforeEnter: authCheck,
    meta: { allowedRoles: [permissionService.ROLES.ADMIN] },
  },
  {
    path: `${paths.admins.detail}/:id`,
    name: "Detalhe do Administrador",
    component: AdminDetail,
    props: true,
    beforeEnter: authCheck,
    meta: { allowedRoles: [permissionService.ROLES.ADMIN] },
  },
  // {
  //   path: `${paths.admins.password_update}/:id`,
  //   name: 'Editar Senha de Administrador',
  //   component: AdminPasswordUpdate,
  //   props: true,
  //   beforeEnter: authCheck,
  //   meta: { allowedRoles: [permissionService.ROLES.ADMIN] }
  // },
  {
    path: `${paths.admins.edit}/:id`,
    name: "Editar Administrador",
    component: EditAdmins,
    props: true,
    beforeEnter: authCheck,
    meta: { allowedRoles: [permissionService.ROLES.ADMIN] },
  },
  {
    path: `${paths.admins.add}`,
    name: "Adicionar Administrador",
    component: AddAdmins,
    props: true,
    beforeEnter: authCheck,
    meta: { allowedRoles: [permissionService.ROLES.ADMIN] },
  },
  {
    path: `${paths.vets.list}`,
    name: "Sessões por Veterinarios",
    component: VetsAppointments,
    props: true,
    beforeEnter: authCheck,
    meta: { allowedRoles: [permissionService.ROLES.ADMIN] },
  },

  // TASKS

  {
    path: paths.tasks.list,
    name: "Tasks",
    component: Tasks,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: paths.tasks.add,
    name: "TasksAdd",
    component: TaskAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.tasks.detail}/:id`,
    name: "TasksDetail",
    component: TaskDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.tasks.edit}/:id`,
    name: "TasksEdit",
    component: TaskAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },

  // Quizzes

  {
    path: paths.quizzes.list,
    name: "Quizzes",
    component: Quizzes,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: paths.quizzes.add,
    name: "QuizAdd",
    component: QuizAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.quizzes.detail}/:id`,
    name: "QuizDetail",
    component: QuizDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.quizzes.edit}/:id`,
    name: "QuizEdit",
    component: QuizAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },

  // customer service

  {
    path: `${paths.customerService.list}/:tab?`,
    name: "CustomerService",
    component: CustomerService,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },

  // appointment detail
  {
    path: `${paths.appointments.detail}/:id`,
    name: "AppointmentDetail",
    component: AppointmentDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },
  // chat detail
  {
    path: `${paths.chats.detail}/:id`,
    name: "ChatDetail",
    component: ChatDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },

  // INVITATIONS
  {
    path: paths.invites.list,
    name: "Convites",
    component: Invites,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  // EVENTS
  {
    path: paths.events.list,
    name: "Eventos",
    component: Events,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.events.add}`,
    name: "Adicionar Eventos",
    component: EventAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [permissionService.ROLES.ADMIN],
    },
  },
  {
    path: `${paths.events.edit}/:id`,
    name: "Editar Conteúdo",
    component: EventAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [permissionService.ROLES.ADMIN],
    },
  },

  // CONTENTS
  {
    path: paths.contents.list,
    name: "Conteudos",
    component: Contents,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.contents.detail}/:id`,
    name: "ContentDetail",
    component: ContentDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.contents.add}`,
    name: "ContentAdd",
    component: ContentAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [permissionService.ROLES.SPECIALIST],
    },
  },
  {
    path: `${paths.contents.edit}/:id`,
    name: "ContentEdit",
    component: ContentAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [permissionService.ROLES.SPECIALIST],
    },
  },

  // MISSIONS
  {
    path: paths.missions.list,
    name: "Missions",
    component: Missions,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: paths.missions.add,
    name: "MissionsAdd",
    component: MissionAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.missions.detail}/:id`,
    name: "MissionsDetail",
    component: MissionDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.missions.edit}/:id`,
    name: "MissionsEdit",
    component: MissionAdd,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },

  // Pets

  {
    path: paths.pets.list,
    name: "PetList",
    component: Pets,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },
  {
    path: `${paths.pets.detail}/:id/:tab?`,
    name: "PetDetail",
    component: PetDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
        permissionService.ROLES.CUSTOMER_SUPPORT,
      ],
    },
  },

  // EDIT CONFIGURAÇÕES

  {
    path: `${paths.settings.edit}/:settings_key`,
    name: "Editar Configurações",
    component: SettingsEdit,
    props: true,
    beforeEnter: authCheck,
    meta: { allowedRoles: [permissionService.ROLES.ADMIN] },
  },

  //ESPECIALISTS

  {
    path: `${paths.specialists.list}`,
    name: "Especialistas",
    component: Specialists,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [permissionService.ROLES.ADMIN],
    },
  },
  {
    path: `${paths.specialists.detail}/:id`,
    name: "Detalhes do Especialista",
    component: SpecialistDetail,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.specialists.add}`,
    name: "Adicionar Especialista",
    component: AddSpecialists,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [permissionService.ROLES.ADMIN],
    },
  },
  {
    path: `${paths.specialists.edit}/:id`,
    name: "Editar Especialista",
    component: AddSpecialists,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.SPECIALIST,
      ],
    },
  },
  {
    path: `${paths.password_update}/:type/:id`,
    name: "Editar Senha",
    component: AdminPasswordUpdate,
    props: true,
    beforeEnter: authCheck,
  },
  {
    path: `${paths.appNotifications}`,
    name: "Notificações",
    component: Notifications,
    props: true,
    beforeEnter: authCheck,
    meta: {
      allowedRoles: [
        permissionService.ROLES.ADMIN,
        permissionService.ROLES.MARKETING,
      ],
    },
  },

  {
    path: `${paths.crashes.detail}/:id`,
    name: "Detalhe do Crash",
    component: CrashDetail,
    props: true,
  },
  {
    path: `${paths.crashes.list}`,
    name: "Crashes",
    component: Crashes,
    props: true,
  },

  //MINHA CONTA
  {
    path: `${paths.myAccount}`,
    name: "Minha Conta",
    component: MyAccount,
    props: true,
  },

  // VIEW CONFIGURAÇÕES
  {
    path: `${paths.settings.view}/:settings_key`,
    name: "Visualizar Configurações",
    component: Settings,
    props: true,
  },

  {
    path: "*",
    redirect: {
      name: "NotFound",
    },
  },
  {
    path: paths.not_found,
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  paths,
});

export default router;
