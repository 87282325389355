<template>
  <div id="add-admin">
    <Navbar current="Adicionar Administrador" :link="$paths.admins.list" :linkName="'Administradores'" />
    <Sidebar />
    <Container>
      <Header title="Adicionar Administrador" />
      <div class="card">
        <div class="card-body">
          <h3>Informações</h3>
          <div class="row">
            <div class="col-12 col-md-6">
              <TextInput label="Nome" :required="true" :model="$v.form.name" :maxLength="30" type="text"
                placeholder="Informe um nome" />
            </div>
            <div class="col-12 col-md-6">
              <TextInput label="Email" :required="true" :model="$v.form.email" type="email" :maxLength="50"
                placeholder="nome@email.com" />
            </div>
            <div class="col-12 col-md-6">
              <PasswordInput label="Senha" :required="true" :model="$v.form.password" :maxLength="30"
                placeholder="Informe uma senha" />
            </div>
            <div class="col-12 col-md-6">
              <PasswordInput label="Confirme a senha" :required="true" :maxLength="30"
                :model="$v.form.passwordConfirmation" placeholder="Confirme a senha" />
            </div>
          </div>

          <h3 class="my-4">Permissões</h3>
          <PermissionsComponent @onPermissionChanged="handlePermissionsChanged" />
          <div class="row mt-3">
            <div class="col-12 text-center">
              <span class="text-danger text-center">{{ errorMessage }}</span>
            </div>
          </div>
          <FormButton label="Adicionar" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AddAdminsController from "./AddAdminsController";
export default AddAdminsController;
</script>
<style scoped></style>
