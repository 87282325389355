<template>
  <div id="add-admin">
    <Navbar :current="id ? 'Editar Missão' : 'Adicionar Missão'" :link="$paths.missions.list" :linkName="'Missões'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar Missão' : 'Adicionar Missão'" />
      <div class="card">
        <div class="card-body">
          <h3>Informações</h3>
          <div class="row">
            <div class="col-12 col-md-7">
              <TextInput :required="true" label="Título" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title" type="text"
                placeholder="Informe um título para a missão" />
            </div>
            <div class="col-12 col-xl-5">
              <TextInput :required="true" :readOnly="true" label="Pet"
                :value="pet ? `${pet.name} - ${pet.breed}` : 'Todos'" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput :required="true" label="Resumo" :model="$v.form.short_description" type="text"
                :maxLength="SHORT_DESCRIPTION_MAX_LENGTH" placeholder="Informe um resumo">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{
                      SHORT_DESCRIPTION_MAX_LENGTH -
                      form.short_description.length
                    }}
                      caracteres restantes</small>
                  </div>
                </template>
              </TextInput>
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput :required="true" label="Descrição completa" :model="$v.form.description" type="text"
                :maxLength="DESCRIPTION_MAX_LENGTH" placeholder="Informe a descrição completa">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{
                      DESCRIPTION_MAX_LENGTH - form.description?.length
                    }}
                      caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput label="Descrição complementar" :model="$v.form.extra_description" type="text"
                :maxLength="DESCRIPTION_MAX_LENGTH" placeholder="Informe uma descrição adicional">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{
                      DESCRIPTION_MAX_LENGTH - form.extra_description?.length
                    }}
                      caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>
            <div class="col-12">
              <div class="d-flex flex-row align-items-center">
                <div class="flex-1">
                  <TextInput label="Links de referência" :model="$v.reference_link" @inputChanged="linkChanged($event)" type="text"
                    placeholder="Informe um link, ex: https://google.com"
                    :errorMsg="'Informe um link de referência correto, ex: https://google.com'" />
                </div>
                <button @click="addReferenceLink" class="btn btn-link">
                  Adicionar
                </button>
              </div>
            </div>
            <div class="col-12 mb-4">
              <span v-for="(item, index) in referenceLinkArray" v-bind:key="'link_' + index"
                class="badge bg-primary-soft me-3 mb-2">
                <button @click="removeReferenceLink(index)" class="btn btn-link p-0 pe-2">
                  <span class="fas fa-times"></span>
                </button>
                <a :href="item" target="_blank">{{ item.limitString(50) }} </a>
              </span>
            </div>


            <h3 v-if="extraContent.length" class="mt-4">Conteúdos extras</h3>

            <div class="col-md-2 col-2 card border rounded-4 mx-3" style="height: 170px" v-for="(file, fileIndex) in extraContent?.filter(
              (d) => !d.deleted
            )" v-bind:key="'tr-' + fileIndex">
              <template v-if="file.type == 'file'">
                <div class="card-header pb-0 pe-2" style="height: 35px">
                  <div class="col">
                    <small><strong>{{ file.name || "Arquivo.pdf" }}</strong></small>
                  </div>
                  <div class="col justify-content-end d-flex">
                    <button class="btn btn-link text-danger ps-0" @click="removeExtraContent(fileIndex, file)">
                      <span class="fa-solid fa-trash-alt"></span>
                    </button>
                  </div>
                </div>
                <div class="card-body justify-content-center align-items-center d-flex">
                  <span class="fas fa-file-pdf display-2 text-muted"></span>
                </div>
              </template>
              <template v-else>
                <div class="card-header pb-0 pe-2" style="height: 35px">
                  <div class="col">
                    <small><strong>{{ file.name || "Imagem" }}</strong></small>
                  </div>
                  <div class="col justify-content-end d-flex" @click="removeExtraContent(fileIndex, file)">
                    <button class="btn btn-link text-danger ps-0">
                      <span class="fa-solid fa-trash-alt"></span>
                    </button>
                  </div>
                </div>
                <div class="card-body justify-content-center align-items-center d-flex">
                  <span class="fas fa-image display-2 text-muted"></span>
                </div>
              </template>
            </div>
            <div class="col-12">
              <button @click="handleAddExtraContent" class="btn btn-link ps-0">
                <span class="fas fa-circle-plus"></span> Adicionar conteúdo
                extra
              </button>
              <div>
                <label class="text-muted mb-3 mt-3 form-label"></label>
                <ImageCropper :customFileInputWidth="100" :aspectRatio="1" v-on:cropEnd="cropEnd"
                  inputId="mission-content-input" :hiddenInput="true" />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <SelectInput :readOnly="userSession.type === 'specialist'" :required="true" label="Especialista"
                :model="$v.form.specialist_id" :options="specialistList" :propertyForText="'name'" />
            </div>
            <div class="col-12 col-md-6">
              <SelectInput :required="true" label="Pilar" :model="$v.form.pillar" :options="$missionsService.pillars.filter((mp) => mp.id !== 'none')
                " />
            </div>

            <div class="col-12">
              <SelectInput :required="true" label="Tipo" :model="$v.form.type" :options="$missionsService.missionTypes" />
            </div>

            <div class="col-12 col-md-6">
              <TextInput label="Duração" :model="$v.form.duration" type="text" placeholder="Informe a duração"
                :max-length="2" />
            </div>
            <div class="col-12 col-md-6">
              <SelectInput label="Tempo" :model="$v.durationUnity"
                :options="$missionsService.durations.map(p => ({ ...p, title: p.title(form.duration).capitalizeFirst() }))" />
            </div>
            <template v-if="!id">
              <div class="col-12 col-md-12 mb-4">
                <hr class="my-5">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-auto">
                        <span class="fas fa-list-check fa-medium-size text-primary"></span>
                      </div>
                      <div class="col ps-0">
                        <h3 class="mb-0">Tarefa para agendar sessão após concluir a missão</h3>
                        <span>Uma nova tarefa será criada automaticamente após o tutor concluir esta missão</span>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <div class="form-check form-switch">
                          <input class="form-check-input" v-model="$v.form.automatic_task.$model" type="checkbox"
                            id="autoTaskCheckbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-auto">
                        <span class="fas fa-stopwatch fa-medium-size text-primary"></span>
                      </div>
                      <div class="col ps-0">
                        <h3 class="mb-0">Iniciar automaticamente</h3>
                        <span v-if="form.start_automatically">Duração irá iniciar a partir do momento da criação desta
                          missão</span>
                        <span v-else>Missão será iniciada somente quando o tutor clicar em iniciar</span>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <div class="form-check form-switch">
                          <input class="form-check-input" v-model="$v.form.start_automatically.$model" type="checkbox"
                            id="autoStartCheckbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- <div class="col-12 mb-4" v-if="!id">
                  <div class="d-flex justify-content-between">
                    <label class="form-label text-muted">
                      Quantidade de repetições no período
                    </label>
                  </div>
                  <div class="d-flex align-items-center">
                    <input type="number" :min="1" class="form-control me-3" v-model="$v.form.times_to_complete.$model" />
                  </div>
                </div> -->
            </template>
            <div class="col-12 col-md-12 mb-4">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-auto">
                      <span class="fas fa-repeat fa-medium-size text-primary"></span>
                    </div>
                    <div class="col ps-0">
                      <h3 class="mb-0">Repetir</h3>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <div class="form-check form-switch">
                        <input class="form-check-input" v-model="hasRepetition" type="checkbox" id="repetitionCheckbox" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" v-if="hasRepetition">
                  <button class="btn btn-primary mb-4">Após conclusão</button>
                  <div class="row">
                    <div class="col-12 col-md-3">
                      <TextInput label="Intervalo" :model="$v.form.repetition.interval" type="text"
                        placeholder="Informe a quantidade" :max-length="2" />
                    </div>
                    <div class="col-12 col-md-3">
                      <SelectInput label="Período" :model="$v.form.repetition.frequency"
                        :options="$missionsService.frequencies.filter(r => r.active)" />
                    </div>
                    <p>Uma nova missão será criada <span class="text-primary fw-bold">{{ missionFrequencySentence
                    }}</span> que o tutor completar a
                      missão anterior </p>
                  </div>
                  <div class="row mt-4">
                    <div class="col-auto">
                      <p class="pt-2">Termina</p>
                    </div>
                    <div class="col-12 col-md-3">
                      <SelectInput label="" :model="$v.endlessMission"
                        :options="[{ id: false, title: 'Depois de' }, { id: true, title: 'Nunca' }]" />
                    </div>
                    <template v-if="!endlessMission">
                      <div class="col-12 col-md-2">
                        <TextInput style="margin-top: -3px;" label="" :model="$v.form.repetition.repetitions" type="text"
                          placeholder="Repetições" :max-length="2" />
                      </div>
                      <div class="col-auto">
                        <p class="pt-2">{{ repetitionTimesLabel }}</p>
                      </div>
                    </template>
                    <p>Esta missão irá repetir <span class="text-primary fw-bold">
                        {{ endlessMission ? '' : form.repetition.repetitions }} {{ repetitionTimesLabel }}</span>
                    </p>

                    <!-- <div class="mt-2 d-flex align-items-center">
                        <template v-if="!endlessMission">
                          <input type="number" style="width: 65px" :min="1" class="form-control ms-2 me-2"
                            v-model="$v.form.repetition.repetitions.$model" :max-length="3" />
                          <label class="text-muted"> ocorrência(s) </label>
                        </template>
                        <div class="col-12 col-md-12 mb-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" v-model="endlessMission" type="checkbox"
                              id="endlessCheckbox" />
                            <label class="form-check-label" for="endlessCheckbox">Indeterminado</label>
                          </div>
                        </div>
                      </div> -->

                  </div>
                </div>
              </div>
            </div>


            <form>
              <div class="row">
                <div class="col-12 text-center">
                  <span class="text-danger text-center">{{
                    errorMessage
                  }}</span>
                </div>
              </div>
              <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
            </form>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import MissionAddController from "./MissionAddController";
export default MissionAddController;
</script>
<style scoped></style>
