import { render, staticRenderFns } from "./ContentAdd.vue?vue&type=template&id=9589ec6a&scoped=true"
import script from "./ContentAdd.vue?vue&type=script&lang=js"
export * from "./ContentAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9589ec6a",
  null
  
)

export default component.exports