import NetworkService from '@/services/NetworkService.js'
import moment from 'moment'
import MissionsService from './MissionsService'

export default class AppointmentsService {
    constructor() {
        this.network = new NetworkService()
    }
    // create = (params) => {
    //     return this.network.postMultipart(this.network.endpoints.contents, params)
    // }
    list = (params) => {
        return this.network.get(this.network.endpoints.appointments, { ...params })
    }
    show = (id) => {
        return this.network.get(`${this.network.endpoints.appointments}/${id}`)
    }
    update = (id, params) => {
        return this.network.put(`${this.network.endpoints.appointments}/${id}`, params)
    }
    callDone = (id) => {
        return this.network.post(`${this.network.endpoints.appointmentsCallDone}/${id}`)
    }
    listAgenda = async (userId, date) => {
        const from = moment(date).startOf('month').startOf('week')
        const to = moment(from).add(41, 'day')
        const params = {
            user_id: userId,
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
        }
        return this.network.get(this.network.endpoints.calendar, params)
    }
    updateScheduleForDay = async (date, schedule) => {
        // const block = !schedule.length ? false : true
        const block = !(!!schedule.length)
        return this.network.post(this.network.endpoints.specificDaySchedules, { date, schedule, block })
    }
    getAppointmentStatus(status) {
        // Possíveis status: 
        // scheduled -> agendada
        // concluded -> concluida
        // canceled -> cancelada
        // pending_reschedule -> especialista sinalizou que nao pode o horario agendado e o usuario precisa selecionar um novo horario
        // canceled_reschedule -> uma nova consulta foi gerado devido a reagendamento e a antiga fica com esse status aqui
        const defaultObject = {
            title: ''
        }
        switch (status) {
            case 'concluded':
                return {
                    title: 'Realizada',
                    badgeClass: 'badge bg-dark-soft',
                    description: 'Sessão finalizada'
                }
            case 'canceled':
                return {
                    title: 'Cancelada',
                    badgeClass: 'badge bg-danger-soft',
                    description: ''
                }
            case 'scheduled':
                return {
                    title: 'Agendada',
                    badgeClass: 'badge bg-success-soft',
                    description: 'Sessão agendada pelo usuário.'
                }
            case 'canceled_reschedule':
                return {
                    title: 'Reagendada',
                    badgeClass: 'badge bg-info-soft',
                    description: 'Essa sessão foi cancelada devido ao reagendamento e outra com um novo horário foi gerada.'

                }
            case 'pending_reschedule':
                return {
                    title: 'Reagendamento pendente',
                    badgeClass: 'badge bg-warning-soft',
                    description: 'Aguardando o usuário selecionar um novo horario no app.'
                }
            default: return defaultObject
        }
    }

    getFormattedAppointment = (item) => {
        const mService = new MissionsService()
        try {
            item.statusObject = this.getAppointmentStatus(item.status)
            item.pillarObject = mService.getMissionPillar(item.pillar)
        } catch (e) {
            console.log(e)
        } finally {
            return item
        }
    }

    // delete = (id) => {
    //     return this.network.delete(`${this.network.endpoints.contents}/${id}`)
    // }

}