<template>
  <div class="container">
    <div class="row mt-5">

      <div class="col-10 offset-1">
        <h1 class="mb-5"> <img src="@/assets/img/logo.png" alt="Bluu" width="70" /> Bluu | {{settingsInfo.title}}</h1>
      </div>

      <div class="col-10 offset-1">
        <div v-html="text"></div>
      </div>
    </div>
  </div>
</template>
<script>
import SettingsController from "./SettingsController";
export default SettingsController;
</script>
