import axios from "axios";
import router from "@/router";
import paths from "@/router/paths";
import qs from "qs";

export default class NetworkService {
  baseUrl = process.env.VUE_APP_API_BASE_URL;
  endpoints = {
    login: "api/login",
    users: "api/users",
    forgotPassword: "api/password/email",
    settings: "api/settings",
    missions: "api/missions",
    pets: "api/pets",
    missionsFree: "api/missions/free",
    tasks: "api/tasks",
    quizzes: "api/quizzes",
    quizzesQuestions: "api/quiz-questions/batch-add-questions",
    healthTests: "api/health-tests",
    missionFiles: "api/mission-files",
    missionSummary: "api/missions/summary",
    contents: "api/articles",
    statistics: "api/backoffice/home-info",
    appointments: "api/appointments",
    chats: "api/chats",
    chatMessages: "api/chat-messages",
    workingHours: "api/specialists/working-hours",
    deleteMissionFiles: "api/mission-files/batch-delete",
    unreadBadge: "api/chats/unread-badge",
    appCrashes: "api/app-crashes",
    sendNotification: "api/admin/batch-push-send",
    calendar: "api/specialists/calendar",
    specificDaySchedules: "api/specific-day-schedules",
    events: "api/events",
    //TODO: pegar endpoint certo
    appointmentsCallDone: "api/appointments/done",
  };

  constructor() { }

  post(endPoint, params) {
    return axios
      .post(this.baseUrl + endPoint, params, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  get(endPoint, query = {}, defaultToken = "") {
    const queryParams = new URLSearchParams();
    if (query.page) {
      queryParams.append("page", query.page);
    }
    if (query.search) {
      queryParams.append("q", query.search.trim());
    }
    if (query.orderBy && query.orderBy.length) {
      queryParams.append(
        "orderBy",
        `${query.orderBy[0].sortName}|${query.orderBy[0].order}`
      );
      // queryParams.append('orderByDirection', query.orderBy[0].order)
    }

    delete query.search;
    delete query.orderBy;
    delete query.page;

    Object.keys(query).map((key) => {
      queryParams.append(`${key}`, query[key]);
    });

    let url = `${this.baseUrl}${endPoint}?${queryParams.toString()}`;

    return axios
      .get(url, { headers: this.headers("", defaultToken) })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  put(endPoint, params) {
    return axios
      .put(this.baseUrl + endPoint, params, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  delete(endPoint) {
    return axios
      .delete(this.baseUrl + endPoint, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  postEncoded(endPoint, params) {
    return axios
      .post(this.baseUrl + endPoint, qs.stringify(params), {
        headers: this.headers("urlencoded"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  putEncoded(endPoint, params) {
    var token = localStorage.getItem("token");
    console.log(token);
    if (token == null) {
      token = "";
    }
    return axios
      .put(this.baseUrl + endPoint, qs.stringify(params), {
        headers: this.headers("urlencoded"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  postMultipart(endPoint, params) {
    return axios
      .post(this.baseUrl + endPoint, this.makeItMultipartParams(params), {
        headers: this.headers("multipart"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  putMultipart(endPoint, params) {
    var token = localStorage.getItem("token");
    console.log(token);
    if (token == null) {
      token = "";
    }
    return axios
      .put(this.baseUrl + endPoint, this.makeItMultipartParams(params), {
        headers: this.headers("multipart"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  makeItMultipartParams(params) {
    var p = new FormData();
    Object.keys(params).forEach(function (key) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].map((r) => {
            p.append(`${key}[]`, r);
          });
        } else {
          p.append(key, params[key]);
        }
      }
    });
    return p;
  }

  makeExternalRequest(type, endPoint, params, headers = {}) {
    switch (type) {
      case "post":
        return axios.post(endPoint, params, { headers });

      case "get":
        return axios.get(endPoint, { headers });

      case "put":
        return axios.put(endPoint, params, { headers });
    }
  }
  headers(encodeType = "", defaultToken = "") {
    var access_token =
      localStorage.getItem(process.env.VUE_APP_API_TOKEN_KEY) || defaultToken;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (encodeType == "multipart") {
      delete headers["Content-Type"];
    }
    if (encodeType == "urlencoded") {
      headers["Content-Type"] = `application/x-www-form-urlencoded`;
    }
    if (access_token) {
      headers["Authorization"] = `Bearer ${access_token}`;
    }
    return headers;
  }

  handleResponse(res) {
    if (res.data.status === false) {
      this.handleError(res);
    } else {
      return res.data;
    }
  }
  handleError(error) {
    const e = error.response || error;
    console.log("ERRO", e);
    if (e && e.status && e.status == 401) {
      router.push(paths.login);
    }
    let message =
      e?.data?.message || e?.message || "Ocorreu um erro, tente novamente";
    if (e?.data?.errors) {
      const keys = Object.keys(e.data?.errors);
      message = e.data?.errors[keys[0]][0];
      console.log("meeeeeeeessage", message);
    }
    throw { message };
  }
}
