export default {
  watch: {
    "$route.params"() {
      this.setupSettings();
    },
  },
  data() {
    return {
      text: "",
      loadingItems: false,
      submitting: false,
      errorMessage: "",
      settingsInfo: {
        key: "",
        viewPath: "",
        title: "",
      },
    };
  },
  mounted() {
    this.setupSettings();
  },
  validations: {
    text: "",
  },
  methods: {
    setupSettings() {
      this.loadingItems = true;
      let k = this.$route.params.settings_key;
      // console.log('queryyyyyyy', k)
      switch (k) {
        case "termos-de-uso":
          this.$set(this, "settingsInfo", {
            key: "terms-of-use",
            viewPath: this.$paths.settings.terms.view,
            title: "Termos de Uso",
          });
          break;
        case "politica-de-privacidade":
          this.$set(this, "settingsInfo", {
            key: "support",
            viewPath: this.$paths.settings.support.view,
            title: "Política de privacidade",
          });
          break;
        case "termos-cadastro":
          this.$set(this, "settingsInfo", {
            key: "initial-terms-of-use",
            viewPath: this.$paths.settings.initial_terms_of_use.view,
            title: "Termos de uso do cadastro inicial",
          });
          break;
        case "termos-convites":
          this.$set(this, "settingsInfo", {
            key: "invitation-tos",
            viewPath: this.$paths.settings.invitation_tos.view,
            title: "Termos de uso para convites",
          });
          break;
        case "sobre-o-app":
          this.$set(this, "settingsInfo", {
            key: "about",
            viewPath: this.$paths.settings.about.view,
            title: "Sobre o App",
          });
          break;
        default:
          this.$router.push({ path: this.$paths.not_found });
      }

      this.getData();
    },

    async getData() {
      try {
        const res = await this.$userService.settings(this.settingsInfo.key);
        this.$set(this, "text", res.data.value);
      } catch (e) {
        console.log(e);
        this.$set(this, "text", "");
      } finally {
        this.loadingItems = false;
      }
    },
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        await this.$userService.updateSettings(
          this.settingsInfo.key,
          this.text
        );
        this.$eventBus.$emit("showToast", {
          title: "Informações salvas",
          msg: `Informações alteradas com sucesso`,
        });
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    view() {
      let routeData = this.$router.resolve({
        path: this.settingsInfo.viewPath,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
