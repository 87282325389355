<template>
  <div id="mission_detail">
    <Navbar
      current="Detalhes da missão"
      :link="$paths.users.list"
      :linkName="'Usuários'"
    />
    <Sidebar />
    <Container>
      <Header title="Detalhes da Missão">
        <template v-slot:button>
          <button @click="handleDelete" class="btn btn-outline-primary ms-3">
            Remover
          </button>
          <router-link
            :to="`${$paths.missions.edit}/${id}`"
            tag="a"
            class="btn btn-primary lift ms-3"
          >
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>

      <!-- <Header v-else title="Detalhes da Missão" /> -->
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações</h3>
              <div class="col-12 col-xl-6">
                <TextInput
                  :readOnly="true"
                  label="Título"
                  :value="item.title"
                />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput
                  :readOnly="true"
                  label="Status"
                  :value="`${item.statusObject?.title}`"
                />
              </div>
              <div class="col-12 col-xl-2">
                <TextInput
                  :readOnly="true"
                  label="Criado em"
                  :value="item.formatted_created_at"
                />
              </div>
              <div class="col-12 col-xl-12" v-if="item.reject_reason">
                <TextInput
                  :readOnly="true"
                  label="Motivo do cancelamento"
                  :value="`${item.reject_reason}`"
                />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput
                  :readOnly="true"
                  label="Resumo"
                  :value="item.short_description"
                />
              </div>
              <div class="col-12 col-xl-12">
                <TextAreaInput
                  :readOnly="true"
                  label="Descrição Completa"
                  :value="item.description"
                />
              </div>
              <div class="col-12 col-xl-12">
                <TextAreaInput
                  :readOnly="true"
                  label="Descrição adicional"
                  :value="item.extra_description"
                />
              </div>
              <div
                class="col-12"
                :class="{
                  'col-xl-4': item.status == 'pending',
                  'col-xl-3': item.status != 'pending',
                }"
              >
                <TextInput
                  :readOnly="true"
                  label="Especialista"
                  :value="item.specialist_id ? item.specialist?.name : 'N/A'"
                />
              </div>
              <div
                class="col-12"
                :class="{
                  'col-xl-4': item.status == 'pending',
                  'col-xl-3': item.status != 'pending',
                }"
              >
                <TextInput
                  :readOnly="true"
                  label="Pilar"
                  :value="item.pillarObject?.title"
                />
              </div>
              <div
                class="col-12"
                :class="{
                  'col-xl-4': item.status == 'pending',
                  'col-xl-3': item.status != 'pending',
                }"
                v-if="item.status != 'pending'"
              >
                <TextInput
                  :readOnly="true"
                  label="Data de término"
                  :value="item.formatted_due_date"
                />
              </div>

              <div
                class="col-12"
                :class="{
                  'col-xl-4': item.status == 'pending',
                  'col-xl-3': item.status != 'pending',
                }"
              >
                <TextInput
                  :readOnly="true"
                  label="Tipo"
                  :value="item.typeObject?.title"
                />
              </div>

              <div v-if="item.repetition" class="col-12">
                <TextInput
                  :readOnly="true"
                  label="Recorrência"
                  :value="item?.formatted_frequency"
                />
              </div>

              <h3>Links de referência</h3>
              <EmptyState
                v-if="!item.reference_links"
                :totalItemsRegistered="0"
              >
                <template v-slot:noItemsRegistered>
                  <p>Nenhum link de referência criado</p>
                </template>
              </EmptyState>
              <div v-else class="col-12 mb-3">
                <span
                  v-for="(ref, index) in item.reference_links?.split('|')"
                  v-bind:key="'link_' + index"
                  class="badge bg-primary-soft me-3 mb-2"
                >
                  <a :href="ref" target="_blank">{{ ref.limitString(50) }} </a>
                </span>
              </div>
              <h3>Conteúdos extras</h3>

              <EmptyState v-if="!item.files?.length" :totalItemsRegistered="0">
                <template v-slot:noItemsRegistered>
                  <p>Nenhum conteúdo extra criado</p>
                </template>
              </EmptyState>
              <table
                v-else
                class="table table-sm table-nowrap card-table table-hover cursor-pointer"
              >
                <TableHead :columns="columns" />
                <tbody class="list">
                  <tr
                    v-for="(item, index) in item.files"
                    v-bind:key="'tr-' + index"
                    @click="viewContent(item)"
                  >
                    <td v-if="item.type == 'file'">
                      <span class="fas fa-file-pdf display-5 text-muted"></span>
                    </td>
                    <td v-else>
                      <span
                        class="fas fa-file-image display-5 text-muted"
                      ></span>
                    </td>
                    <td>
                      <time>{{
                        $moment(item.created_at).format("DD/MM/YYYY")
                      }}</time>
                    </td>
                    <td class="text-end">
                      <span class="fw-bold text-info">Ver conteúdo</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import MissionDetailController from "./MissionDetailController";
import TextAreaInput from "@/components/FormComponents/TextAreaInput.vue";
export default MissionDetailController;
</script>
