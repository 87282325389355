export default {
  props: {
    settings_key: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: "Lorem Ipsum",
      settingsInfo: {
        key: "",
        title: "",
      },
    };
  },
  mounted() {
    this.setupSettings();
  },
  methods: {
    setupSettings() {
      this.loadingItems = true;
      switch (this.settings_key) {
        case "termos-de-uso":
          this.$set(this, "settingsInfo", {
            key: "terms-of-use",
            title: "Termos de Uso",
          });
          break;
        case "politica-de-privacidade":
          this.$set(this, "settingsInfo", {
            key: "support",
            title: "Política de privacidade",
          });
          break;
        case "sobre-o-app":
          this.$set(this, "settingsInfo", {
            key: "about",
            title: "Sobre o App",
          });
          break;
        case "termos-convites":
          this.$set(this, "settingsInfo", {
            key: "invitation_tos",
            title: "Termos de uso para convites",
          });
          break;
        case "termos-cadastro":
          this.$set(this, "settingsInfo", {
            key: "initial-terms-of-use",
            title: "Termos de uso do cadastro inicial",
          });
          break;

        default:
          this.$router.push({ path: this.$paths.not_found });
      }
      this.getData();
    },
    async getData() {
      try {
        const res = await this.$userService.settings(this.settingsInfo.key);
        this.$set(this, "text", res.data.value);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
  },
};
