import router from '@/router';
import paths from '@/router/paths';
import ChatService from '@/services/ChatService';
import UserService from '@/services/UserService';
import { EventBus } from '@/utils/EventBus'
import moment from 'moment';
export const confirmEmail = (item, callBack) => {
    const userService = new UserService()
    EventBus.$emit("showConfirmationAlert", {
        title: "Deseja confirmar o email deste usuário manualmente?",
        message: "Ao fazer isso, não será possível validar se o usuário possui um email real, o que poderá prejudicar nas funcionalidades que utilizam email. Deseja continuar?",
        confirmCallback: async () => {
            try {
                await userService.confirmEmail(item.id)
                callBack()
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
    });
}
export const becomePremium = (item, callBack) => {
    const userService = new UserService()
    EventBus.$emit("showConfirmationAlert", {
        title: "Deseja tornar esse usuário um Assinante Premium?",
        message: "O usuário terá acesso a todas as funciolaidades do app como se fosse um assinante. Deseja continuar?",
        confirmCallback: async () => {
            try {
                await userService.upgradeToPremium(item.id)
                callBack()
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
    });
}

export const removePremium = (item, callBack) => {
    const userService = new UserService()
    EventBus.$emit("showConfirmationAlert", {
        title: "Deseja remover a assinatura Premium desse usuário?",
        message: "O usuário voltará a ter funcionalidades limitadas como não assinante. Deseja continuar?",
        confirmCallback: async () => {
            try {
                await userService.removePremium(item.id)
                callBack()
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
    });
}
export const createChat = async (userId, callback) => {
    try {
        const chatService = new ChatService()
        const res = await chatService.create({
            user_id: userId,
            subject: "app_team",
            reason: "Atendimento",
        });
        router.push({
            path: `${paths.chats.detail}/${res.data.id}`,
        });
    } catch (e) {
        console.log(e);
    } finally {
        callback()
    }
}