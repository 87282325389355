import { required, minLength, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "AddTasks",
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      existingImage: "",
      TITLE_MAX_LENGTH: 120,
      CONTENT_MAX_LENGTH: 7000,
      errorMessage: "",
      submitting: false,
      publicationDate: false,
      form: {
        pillar: "",
        title: "",
        content: "",
        image: "",
        publication_date: "",
      },
    };
  },
  validations() {
    return {
      form: {
        image: !this.id
          ? {
              required,
            }
          : "",
        pillar: {
          required,
        },
        title: {
          required,
          minLength: minLength(10),
        },
        content: {
          required,
          minLength: minLength(10),
        },
        publication_date: {
          required: requiredIf(() => {
            console.log(this.publicationDate);
            return this.publicationDate;
          }),
          isAfter: (value) => {
            if (this.publicationDate) {
              return this.$moment(value).isAfter();
            } else {
              return true;
            }
          },
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        const values = { ...this.form };
        if (values.image) {
          values.image = values.image.file;
        }
        if (values.publication_date) {
          values.publication_date = values.publication_date.replace("T", " ");
        }
        if (!this.id) {
          await this.$contentsService.create(values);
          this.$eventBus.$emit("showToast", {
            title: "Conteúdo criado",
            msg: `Conteúdo criado com sucesso`,
          });
          this.$router.push({ path: `${this.$paths.contents.list}` });
        } else {
          await this.$contentsService.update(this.id, values);
          this.$eventBus.$emit("showToast", {
            title: "Conteúdo alterada",
            msg: `Conteúdo alterada com sucesso`,
          });
          this.$router.back();
        }
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    cropEnd(image) {
      this.$set(this.form, "image", image);
    },
    editImageClicked() {
      document.getElementById("content-add-message-input").click();
    },
    removeImage() {
      this.$set(this.form, "image", "");
    },
    setPublicationDate() {
      this.publicationDate = !this.publicationDate;
    },
    async getData() {
      try {
        const res = await this.$contentsService.show(this.id);
        let f = {
          title: res.data.title,
          content: res.data.content,
          pillar: res.data.pillar,
        };
        this.$set(this, "existingImage", res.data.image);
        this.$set(this, "form", f);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
