import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'Titulo',
          sortName: 'title',
          order: null
        },
        {
          name: 'Autor',
          sortName: null,
          order: null
        },
        {
          name: 'Classificação',
          sortName: 'pillar',
          order: null
        },
        {
          name: 'Criação',
          sortName: 'created_at',
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => ({
        ...elem,
        statusObject: this.$tasksService.getTaskStatus(elem.status),
        pillarObject: this.$missionsService.getMissionPillar(elem.pillar),
      }))
    },
    fetchPromise() {
      return this.$contentsService.list({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    }
  }
};