import NetworkService from '@/services/NetworkService';
import store from '@/store'
export default class PermissionService {


    constructor() {
        this.ROLES = {
            ADMIN: 'admin',
            MARKETING: 'marketing',
            SPECIALIST: 'specialist',
            CUSTOMER_SUPPORT: 'customer-support'
        }
        this.permissionList = [
            {
                id: this.ROLES.ADMIN,
                name: 'Administrador',
                icon: 'fas fa-user-shield',
                description: 'Acesso completo a todas as áreas e funcionalidades do backoffice, permissão para modificar configurações, adicionar/remover usuários e gerenciar permissões de acesso',
            },
            {
                id: this.ROLES.MARKETING,
                name: 'Marketing',
                icon: 'fas fa-user-tag',
                description: 'Acesso a informações e ferramentas relacionadas a campanhas de marketing. análise de dados e promoções.Acesso a métricas e criação de conteúdo',
            },
            {
                id: this.ROLES.SPECIALIST,
                name: 'Especialista',
                icon: 'fas fa-user-shield',
                description: 'Acesso a informações de clientes, como detalhe do contato, protuário, permissão para criar, editar e visualizar registros de interações com os clientes',
            },
            {
                id: this.ROLES.CUSTOMER_SUPPORT,
                name: 'Atendimento ao cliente',
                icon: 'fa fa-comment-medical',
                description: 'Acesso limitado a informações básicas de clientes, como detalhes de contato e histórico de interações. Acesso a ferramentas e recursos necessários para fornecer suporte aos clientes',
            },
        ]
    }

    isAllowed(allowedRoles = []) {
        // console.log(allowedRoles)
        if (!Array.isArray(allowedRoles)) {
            allowedRoles = [allowedRoles]
        }
        let userRoles = store.state.userSession.roles || []
        userRoles = JSON.parse(JSON.stringify(userRoles))
        // console.log('meu roles', userRoles)
        let allowed = false
        userRoles.map(r => {
            if (allowedRoles.includes(r)) {
                allowed = true
            }
        })
        return allowed
    }
}
