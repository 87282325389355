import { Modal } from 'bootstrap';

export default {
  name: "PermissionsComponent",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    permissionHasAdmin() {
      return this.permissions.includes(this.$permissionService.ROLES.ADMIN)
    }
  },
  watch: {
    value(newVal) {
      this.permissions = JSON.parse(JSON.stringify(newVal))
    }
  },
  data() {
    return {
      permissions: [],
    }
  },
  methods: {
    handlePermissionsChanged(elem) {
      if (this.permissionHasAdmin) {
        this.permissions = [this.$permissionService.ROLES.ADMIN]
      }
      this.$emit("onPermissionChanged", JSON.parse(JSON.stringify(this.permissions)))
    }
  },
  destroyed() {
  },
};

