import NetworkService from '@/services/NetworkService';

export default class MedicalRecordsService {
    constructor() {
        this.network = new NetworkService()
    }
    list(filters) {
        return this.network.get(this.network.endpoints.medical_records, filters)
    }
    show(id) {
        return this.network.get(`${this.network.endpoints.medical_records}/${id}`)
    }
}
