import { mapActions } from 'vuex';

export default {
  name: "DutyCardComponent",
  computed: {
    isOnDuty() {
      return Boolean(this.$store.state.userSession.online);
    },
    isSpecialist() {
      return this.$store.state.userSession.type === this.$permissionService.ROLES.SPECIALIST
    },
    userSession() {
      return this.$store.state.userSession
    }
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    ...mapActions([
      'updateSession',
    ]),
    async updateOnDuty() {
      const online = Number(!this.isOnDuty)
      try {
        this.loading = true
        const res = await this.$userService.update(this.userSession.id, { online })
        await this.updateSession({ online })

      } catch (e) {
        console.log(e)
        await this.updateSession({ online: Number(this.isOnDuty) })
      } finally {
        this.loading = false
      }
    }
  },
  destroyed() {
  },
};

