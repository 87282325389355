export default {
  props: {
    item: {},
    summary: {},
  },
  data() {
    return {
    };
  },
  methods: {
    
  }
}