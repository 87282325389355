<template>
  <div id="specialist_detail">
    <Navbar current="Detalhes do especialista" :link="userSession?.type == 'admin' ? $paths.specialists.list : null"
      :linkName="'Especialistas'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Especialista">
        <template v-slot:button
          v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN]) || ($permissionService.isAllowed([$permissionService.ROLES.SPECIALIST]) && this.id == userSession?.id)">
          <button v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])" @click="handleDelete"
            class="btn btn-link text-danger ms-3">
            <i class="fa-solid fa-trash-alt me-1"></i>
            Remover
          </button>
          <router-link :to="`${$paths.specialists.password_update}/${id}`" tag="a"
            class="btn btn-outline-primary lift ms-3">
            <i class="fa-solid fa-unlock-keyhole me-1"></i>
            Alterar senha
          </router-link>
          <router-link :to="`${$paths.specialists.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>
      <!-- <Header title="Detalhes do Especialista" :btnLink="`${$paths.specialists.edit}/${id}`" btnLabel="Editar"
        btnIcon="fa-solid fa-pen-to-square">
      </Header> -->
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <h3 class="mb-5">Informações do especialista</h3>
            <div class="row">
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Nome" :value="item.name" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Email" :value="item.email" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Formação" :value="item.specialist_info?.education" />
              </div>
              <div class="col-12 col-xl-12">
                <TextAreaInput :readOnly="true" label="Resumo da biografia" :value="item.specialist_info?.bio" />
              </div>
              <div class="col-12 col-xl-12">

                <label class="text-muted form-label mb" for="">Especialidades</label>
                <div v-for="(sp, index) in $missionsService.pillars" v-bind:key="'tr-' + index">
                  <p>
                    <span v-if="item.specialties?.includes(sp.id)"
                      class="fa-solid fa-circle-check fa-medium-size mb-0 text-muted"></span>
                    <span v-else class="far fa-circle fa-medium-size mb-0 text-muted"></span>
                    {{ sp.title }}
                  </p>
                </div>

              </div>
              <div class="col-12 col-xl-12">
                <p>
                  <label class="text-muted form-label mb-0" for="">Foto do especialista</label>
                </p>
                <img class="rounded-circle avatar avatar-xxl mb-4" style="object-fit: contain;"
                  :src="`${$baseImageUrl}${item.specialist_info?.image}`" alt="">
              </div>
              <h3 class="mb-5">Horário de atendimento</h3>
              <div v-for="(weekDay, weekIndex) in weekDays" v-bind:key="'week-' + weekIndex"
                class="col-12 col-xl-12 border-bottom mt-3 pb-3">

                <h4 class="fw-bolder mb-1">{{ weekDay.title }}</h4>
                <div v-if="item.formatted_working_hours?.[`${weekDay.weekday}`]?.length">
                  <div v-for="(hour, hourIndex) in item.formatted_working_hours[`${weekDay.weekday}`]"
                    v-bind:key="'hour-' + hourIndex" class="text-muted">{{
                      hour.start_time.substring(0, 5)
                    }} as
                    {{ hour.end_time.substring(0, 5) }}</div>
                </div>
                <span class="text-muted" v-else>
                  Indisponível
                </span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import SpecialistDetailController from "./SpecialistDetailController";
export default SpecialistDetailController;
</script>
