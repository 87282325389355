import PetSummary from "./PetSummary/PetSummary.vue"
import PetMissions from "./PetMissions/PetMissions.vue"
import PetTasks from "./PetTasks/PetTasks.vue"
import PetHealthTests from "./PetHealthTests/PetHealthTests.vue"
import PetQuiz from "./PetQuiz/PetQuiz.vue"
import Appointments from "../../CustomerService/Appointments/Appointments.vue"
export default {
  watch: {
    "$route.params"(value) {
      this.selectedTab = value.tab
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      item: {},
      missions: [],
      summary: {},
      loadingItems: false,
      id: this.$route.params.id,
      tabs: [
        {
          id: 'pet-summary',
          name: 'Resumo'
        },
        {
          id: 'pet-missions',
          name: 'Missões'
        },
        {
          id: 'pet-tasks',
          name: 'Tarefas'
        },
        {
          id: 'pet-vaccines',
          name: 'Vacinas'
        },
        {
          id: 'pet-health-tests',
          name: 'Anexos'
        },
        {
          id: 'pet-appointments',
          name: 'Atendimentos'
        },
      ],
      selectedTab: this.$route.params.tab || 'pet-summary',
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$petService.show(this.id)
        let data = res.data
        data.user_created_at = data.owners[0]?.user ? this.$moment(data.owners[0].user.created_at).format('DD/MM/YYYY') : 'N/A'
        data.owner = data.owners[0]?.user
        data.account = data.owners[0]?.user.plan_status == 'paid' ? 'Assinante' : 'Não assinante'
        this.$set(this, 'item', data)
        const missions = await this.$missionsService.list({ pet_id: this.id })
        this.$set(this, 'missions', missions.data)
        if (data.owner) {
          await this.getSummary(data.owner?.id)
        }

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    async getSummary(user_id) {
      try {
        const res = await this.$missionsService.getMissionSummary(this.id)
        let p = res.data.count
        Object.keys(p).map(c => {
          let total = 0
          Object.keys(p[c]).map(it => {
            const y = p[c][it] || 0
            total += Number(y)
          })
          p[c].total = total
        })
        p.pending = {
          total: (p.active?.total || 0) + (p.active?.late || 0)
        }
        const resTasks = await this.$tasksService.list({
          user_id: user_id,
        })
        p.pendingTasks = {
          total: resTasks.data.filter(r => r.status == 'pending').length
        }
        p.allTasks = resTasks.data
        this.$set(this, 'summary', p)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
  },
  components: {
    PetMissions,
    PetSummary,
    PetTasks,
    PetHealthTests,
    PetQuiz,
    Appointments
  }
}