import { toggleModal } from "@/components/ModalComponent.vue";
import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  props: {
    propFilters: {}
  },
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Usuário',
          sortName: null,
          order: null
        },
        {
          name: 'Especialista',
          sortName: null,
          order: null
        },
        {
          name: 'Especialidade',
          sortName: 'pillar',
          order: null
        },
        {
          name: 'Status',
          sortName: 'status',
          order: null
        },
        {
          name: 'Data',
          sortName: 'date',
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => this.$appointmentsService.getFormattedAppointment(elem))
    },
    fetchPromise() {
      console.log('propFilters', this.propFilters)
      return this.$appointmentsService.list({
        ...this.filters,
        ...this.propFilters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    },
  }
};