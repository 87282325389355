import { email, required, sameAs } from 'vuelidate/lib/validators';
import PermissionsComponent from '@/components/PermissionsComponent/PermissionsComponent'
export default {
  name: 'AddAdmins',
  mounted() {
  },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        roles: [],
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        type: 'admin'
      },
    };
  },
  validations: {
    form: {
      roles: {
        required,
      },
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      passwordConfirmation: { required, sameAsPassword: sameAs('password') },
    }
  },
  components: {
    PermissionsComponent
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = JSON.parse(JSON.stringify(this.form))
        const roles = values.roles
        delete (values.passwordConfirmation)
        delete (values.roles)
        const res = await this.$userService.create(values)
        const permissionRes = await this.$userService.update(res.data.id, { roles })

        this.$eventBus.$emit('showToast', { title: 'Admin criado', msg: `Admin adicionado com sucesso` })
        this.$router.push({ path: `${this.$paths.admins.list}` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
    handlePermissionsChanged(permissions) {
      this.$set(this.form, 'roles', permissions)
    }


  }
};