<template>
  <div id="quizzes">
    <Navbar current="Quiz" />
    <Sidebar />
    <Container col="col-12">
      <Header :isSubHeader="false" title="Questionários" subtitle="Registro de todos os questionários"
        :iconClass="'fa-solid fa-clipboard-question text-primary'" :totalCount="totalItemsRegistered"
        :btnLink="$paths.quizzes.add" btnLabel="Adicionar" btnIcon="fa-solid fa-circle-plus" />
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-solid fa-clipboard-question text-black-50 mb-2"></span>
              <p>Nenhum questionário criado</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table class="
                table table-sm table-nowrap
                card-table
                table-hover
                cursor-pointer
              ">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.quizzes.detail}/${item.id}`">
                  <td>{{ item.title }}</td>
                  <td>{{ item.pillarObject.title }}</td>
                  <td>{{ item.questions.length }}</td>
                  <td class="text-end">
                    <time>{{
                    item.formatted_created_at
                    }}</time>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12  text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import QuizzesController from "./QuizzesController";
export default QuizzesController;
</script>
