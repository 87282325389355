export default {
    name: 'DetailMixinController',
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            item: {},
            loadingItems: false,
            loadingDelete: false,
            deleteConf: {
                confirmationMessage: 'Deseja excluir este item permanentemente?',
            }
        }
    },
    methods: {
        async getData(loadingMore = false) {
            try {
                const res = await this[this.contextService].show(this.id)
                let data = this.prepareResponse(res.data)
                this.$set(this, 'item', data)
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
        /**
         * @return faz as mudanças na resposta e retorna pro getData 
         * -fazer o override na tela pra tratar a resposta conforme necessario
         */
        prepareResponse(data) {
            return data
        },

        handleDelete() {
            if (!this.hasDelete) return
            this.$eventBus.$emit("showConfirmationAlert", {
                message: "Deseja excluir este item permanentemente?",
                confirmCallback: async () => {
                    try {
                        this.$set(this, `loadingDelete`, true);
                        await this[this.contextService].delete(this.id)
                        this.$eventBus.$emit('showToast', { title: 'Item excluído', msg: `Item excluído com sucesso` })
                        this.$router.back()
                    } catch (e) {
                        console.log(e);
                    } finally {
                        this.$set(this, `loadingDelete`, false);
                    }
                },
            });
        },
    }
};