<template>
  <div class="
      d-flex
      align-items-center
      bg-dark
      border-top border-top-2 border-primary
      vh-100
    ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 my-5">
          <div class="d-flex justify-content-center mb-3">
            <img src="@/assets/img/logo.png" alt="Diag" width="70" />
          </div>

          <p class="text-muted text-center mb-5">Bluu {{ $envString }} <small style="font-size: 0.7rem"
              class="text-muted">{{ $versionString }}</small> </p>

          <form>
            <TextInput label="Email" :model="$v.form.email" type="email" class="text-white"
              placeholder="nome@email.com" />
            <PasswordInput label="Senha" :model="$v.form.password" class="text-white" placeholder="Informe sua senha" />
            <FormButton label="Entrar" :loading="submitting" :disabled="$v.$invalid"
              class="btn btn-lg w-100 btn-primary mb-3" @onClick="login" />

            <p class="text-danger text-center">{{ errorMessage }}</p>

            <div class="text-center">
              <small class="text-muted text-center">
                Esqueceu a senha?
                <router-link tag="a" :to="$paths.forgot_password">
                  Cadastre uma nova</router-link>.
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginController from "./LoginController";
export default LoginController;
</script>
