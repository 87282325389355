import DetailMixinController from "@/mixins/DetailMixinController";
import { becomePremium, confirmEmail, createChat, removePremium } from "../userFunctions";

export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: "$userService",
    };
  },
  computed: {
    subscriptionInfo() {
      return this.$userService.getSubscriptionInfo(this.item);
    },
  },
  data() {
    return {
      loadingChat: false,
      loadingConfirmEmail: false,
      loadingPremium: false,
      inviteColumns: [
        {
          name: "Nome",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
        {
          name: "Data",
          sortName: null,
          order: null,
        },
      ],
      tasksColumns: [
        {
          name: "Título",
          sortName: null,
          order: null,
        },
        {
          name: "Tipo",
          sortName: null,
          order: null,
        },
        {
          name: "Categoria",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
        {
          name: "Criação",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      data.formatted_created_at = this.$moment(data.created_at).format(
        "DD/MM/YYYY"
      );
      data.tasks.map((t) => {
        t.typeObject = this.$tasksService.getTaskType(t.type);
        t.targetObject = this.$tasksService.getTaskTarget(t.target);
        t.statusObject = this.$tasksService.getTaskStatus(t.status);
        t.formatted_created_at = this.$moment(t.created_at).format(
          "DD/MM/YYYY"
        );
      });
      if (data.referrals.length) {
        data.referrals.map((elem) => {
          elem.formatted_created_at = this.$moment(elem.created_at).format(
            "DD/MM/YYYY"
          );
          elem.formatted_status =
            elem.user_info?.plan_id && elem.user_info?.plan_status == "paid"
              ? "Assinante"
              : "Gratuíto";
        });
      }
      return data;
    },
    async handleStartChat() {
      this.loadingChat = true;
      createChat(this.id, () => {
        this.loadingChat = false;
        this.$router.push({
          path: `${this.$paths.chats.detail}/${res.data.id}`,
        });
      })
    },
    handleConfirmEmail() {
      confirmEmail(this.item, () => {
        this.getData();
      });
    },
    handleBecomePremium() {
      becomePremium(this.item, () => {
        this.getData();
      });
    },
    handleRemovePremium() {
      removePremium(this.item, () => {
        this.getData();
      });
    },
  },
};
