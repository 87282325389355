import DetailMixinController from '@/mixins/DetailMixinController';

export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$quizService',
      hasDelete: true
    }
  },
  data() {
    return {
      questionColumns: [
        {
          name: 'Título',
          sortName: null,
          order: null
        },
        {
          name: '',
          sortName: null,
          order: null
        }
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      data.pillarObject = this.$missionsService.getMissionPillar(data.pillar)
      data.formatted_created_at = this.$moment(data.created_at).format('DD/MM/YYYY')
      data.questions.map(q => {
        q.question_type = this.$quizService.getQuestionType(q.field_type).name
        if (q.select_answers) {
          q.question_alternatives = q.select_answers.split('|')
          console.log(q.question_alternatives)
        }
      })
      return data
    },
  }
}