const paths = {
  not_found: "/not-found",
  login: "/login",
  forgot_password: "/esqueci-a-senha",
  password_update: "/editar-senha",
  home: "/home",
  users: {
    list: "/usuarios",
    detail: "/usuarios/detalhes",
  },
  pets: {
    list: "/prontuarios",
    detail: "/prontuarios/detalhes",
  },
  vets: {
    list: "/sessoes-veterinarios",
  },
  tasks: {
    list: "/tarefas",
    add: "/tarefas/adicionar",
    detail: "/tarefas/detalhes",
    edit: "/tarefas/editar",
  },
  chats: {
    detail: "/chat/detalhes",
  },
  appointments: {
    detail: "/sessoes/detalhes",
  },
  customerService: {
    list: "/atendimentos",
  },
  contents: {
    list: "/conteudos",
    detail: "/conteudos/detalhes",
    edit: "/conteudos/editar",
    add: "/conteudos/adicionar",
  },
  events: {
    list: "/eventos",
    edit: "/eventos/editar",
    add: "/eventos/adicionar",
  },
  quizzes: {
    list: "/questionarios",
    add: "/questionarios/adicionar",
    detail: "/questionarios/detalhes",
    edit: "/questionarios/editar",
  },
  missions: {
    list: "/missoes",
    add: "/missoes/adicionar",
    detail: "/missoes/detalhes",
    edit: "/missoes/editar",
  },
  specialists: {
    list: "/especialistas",
    add: "/especialistas/adicionar",
    detail: "/especialistas/detalhes",
    edit: "/especialistas/editar",
    password_update: "/editar-senha/especialistas",
  },
  admins: {
    list: "/administradores",
    add: "/administradores/adicionar",
    edit: "/administradores/editar",
    detail: "/administradores/detalhes",
    password_update: "/editar-senha/administradores",
  },
  settings: {
    edit: "/configuracoes/editar",
    view: "/configuracoes",
    terms: {
      edit: "/configuracoes/editar/termos-de-uso",
      view: "/configuracoes/termos-de-uso",
    },
    support: {
      edit: "/configuracoes/editar/politica-de-privacidade",
      view: "/configuracoes/politica-de-privacidade",
    },
    about: {
      edit: "/configuracoes/editar/sobre-o-app",
      view: "/configuracoes/sobre-o-app",
    },
    invitation_tos: {
      edit: "/configuracoes/editar/termos-convites",
      view: "/configuracoes/termos-convites",
    },
    initial_terms_of_use: {
      edit: "/configuracoes/editar/termos-cadastro",
      view: "/configuracoes/termos-cadastro",
    },
  },
  contract: {
    detail: "/contrato-pessoa-juridica",
    edit: "/contrato-pessoa-juridica/editar",
  },
  crashes: {
    list: "/crashes/",
    detail: "/crashes/detalhes",
  },
  invites: {
    list: "/convites",
  },
  appNotifications: "/notificacoes",
  myAccount: "/minha-conta",
};

export default paths;
