import BarChart from "@/components/BarChart.vue";
import Container from "@/components/Container.vue";
import EmptyState from "@/components/EmptyState.vue";
import EditorInput from "@/components/FormComponents/EditorInput.vue";
import FormButton from "@/components/FormComponents/FormButton.vue";
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import TextAreaInput from "@/components/FormComponents/TextAreaInput.vue";
import TextInput from "@/components/FormComponents/TextInput.vue";
import SelectInput from "@/components/FormComponents/SelectInput.vue";
import Header from "@/components/Header.vue";
import ImageCropper from "@/components/ImageCropper/ImageCropper.vue";
import LoadingContainer from "@/components/LoadingContainer.vue";
import ModalComponent from "@/components/ModalComponent";
import Navbar from "@/components/Navbar.vue";
import SearchBar from "@/components/SearchBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Slider from "@/components/Slider.vue";
import TableHead from "@/components/TableHead.vue";
import UserService from "@/services/UserService";
import { EventBus } from "@/utils/EventBus";
import * as helper from "@/utils/Helpers";
import "@fortawesome/fontawesome-free/css/all.css";
import "@popperjs/core";
import "bootstrap";
import "jquery";
import * as moment from "moment";
import "quill/dist/quill.snow.css";
import VueMask from "v-mask";
import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./assets/theme/scss/theme.scss";
import router from "./router";
import paths from "./router/paths";
import ContentsService from "./services/ContentsService";
import MedicalRecordsService from "./services/MedicalRecordsService";
import store from "./store";
import "./styles.scss";
import pdf from "vue-pdf";
import MissionsService from "./services/MissionsService";
import PetService from "./services/PetService";
import ConfirmationAlert from "./components/ConfirmationAlert/ConfirmationAlert.vue";
import HomeCard from "./components/HomeCard.vue";
import TasksService from "./services/TasksService";
import QuizService from "./services/QuizService";
import ChatService from "./services/ChatService";
import AppointmentsService from "./services/AppointmentsService";
import WebSocketService from "./services/WebSocketService";
import "./utils/PreLoadedFunctions";
import "moment/locale/pt-br";
import PermissionService from "./services/PermissionService";
import EventsService from "./services/EventsService";
import CopyText from './components/CopyText.vue';
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';
import 'vue2-datepicker/scss/index.scss';
Vue.use(VueQuillEditor);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.config.productionTip = false;

//services
Vue.prototype.$helper = helper;
Vue.prototype.$userService = new UserService();
Vue.prototype.$petService = new PetService();
Vue.prototype.$missionsService = new MissionsService();
Vue.prototype.$petService = new PetService();
Vue.prototype.$medicalRecordsService = new MedicalRecordsService();
Vue.prototype.$contentsService = new ContentsService();
Vue.prototype.$tasksService = new TasksService();
Vue.prototype.$quizService = new QuizService();
Vue.prototype.$chatService = new ChatService();
Vue.prototype.$appointmentsService = new AppointmentsService();
Vue.prototype.$permissionService = new PermissionService();
Vue.prototype.$eventsService = new EventsService();

//components
Vue.component('Datepicker', Datepicker)
Vue.component("Slider", Slider);
Vue.component("Sidebar", Sidebar);
Vue.component("Navbar", Navbar);
Vue.component("LoadingContainer", LoadingContainer);
Vue.component("EmptyState", EmptyState);
Vue.component("Container", Container);
Vue.component("SearchBar", SearchBar);
Vue.component("TextInput", TextInput);
Vue.component("SelectInput", SelectInput);
Vue.component("BarChart", BarChart);
Vue.component("TextAreaInput", TextAreaInput);
Vue.component("PasswordInput", PasswordInput);
Vue.component("EditorInput", EditorInput);
Vue.component("FormButton", FormButton);
Vue.component("TableHead", TableHead);
Vue.component("ImageCropper", ImageCropper);
Vue.component("ModalComponent", ModalComponent);
Vue.component("Header", Header);
Vue.component("pdf", pdf);
Vue.component("ConfirmationAlert", ConfirmationAlert);
Vue.component("HomeCard", HomeCard);
Vue.component("CopyText", CopyText);

//utils
Vue.prototype.$paths = paths;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$moment = moment;
Vue.prototype.$envString = process.env.VUE_APP_ENV_STRING;
Vue.prototype.$baseImageUrl = process.env.VUE_APP_IMG_BASE_URL;
Vue.prototype.$versionString = process.env.VUE_APP_VERSION_STRING;

Vue.prototype.$pusher = new WebSocketService().getInstance();

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

//prototype funcions

function capitalizeFirstPrototype(str) {
  if (!str) {
    str = this;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function limitStringPrototype(limit, str) {
  try {
    if (!str) {
      str = this;
    }
    return str.length >= limit ? `${str.substr(0, limit - 1)}...` : str;
  } catch (e) {
    return str;
  }
}
String.prototype.capitalizeFirst = capitalizeFirstPrototype;
String.prototype.limitString = limitStringPrototype;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
