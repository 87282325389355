import ListControllerMixin from "@/mixins/ListControllerMixin";
import moment from "moment";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  computed: {
  },
  watch: {
    dateRange(val) {
      this.loadingItems = true
      this.getData()
    }
  },
  data() {
    return {
      dateRange: [moment().add(-1, 'month').toDate(),moment().toDate()],
      columns: [
        {
          name: 'Veterinário',
          sortName: null,
          order: null
        },
        {
          name: 'Quantidade de sessões',
          sortName: null,
          order: null
        },
        {
          name: '',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      let dateFrom = ''
      let dateTo = ''
      if (this.dateRange?.length == 2) {
        dateFrom = moment(this.dateRange[0]).format('YYYY-MM-DD')
        dateTo = moment(this.dateRange[1]).format('YYYY-MM-DD')
      }
      return this.$userService.list({
        ...this.filters,
        type: 'specialist',
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null),
        appts_from_date: dateFrom,
        appts_to_date: dateTo,
      })
    },
    prepareResponse(data) {
      return data.map(elem => {
        let sp = ''
        if (elem.specialist_info?.specialties) {
          sp = elem.specialist_info.specialties.split('|').map(s => this.$missionsService.getMissionPillar(s).title).join(', ')
        }
        elem.concluded_sessions_time = elem.concluded_sessions_count * 30
        return {
          ...elem,
          specialties: sp
        }
      }).sort((a,b) => {
        if(a?.concluded_sessions_count > b?.concluded_sessions_count) {
          return -1
        }
        if(a?.concluded_sessions_count < b?.concluded_sessions_count) {
          
          return 1
        }
        return 0
      })
    }
  }
};