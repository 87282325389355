import DetailMixinController from '@/mixins/DetailMixinController';
export default {
  mixins: [DetailMixinController],
  setup() {
    return {
      contextService: '$userService',
      hasDelete: true
    }
  },
  methods: {
    prepareResponse(data) {
      data.formatted_created_at = this.$moment(data.created_at).format('DD/MM/YYYY')
      data.formatted_permissions = this.$userService.formatUserPermissions(data.roles)
      return data
    },
  }
}