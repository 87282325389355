import NetworkService from '@/services/NetworkService.js'

export default class QuizService {
    constructor() {
        this.network = new NetworkService()
    }
    questionTypes = [
        {
            id: 'string',
            name: 'Campo texto'
        },
        {
            id: 'int',
            name: 'Campo numérico'
        },
        {
            id: 'date',
            name: 'Campo de data'
        },
        {
            id: 'select',
            name: 'Alternativa'
        },
    ]

    create = (params) => {
        return this.network.postMultipart(this.network.endpoints.quizzes, params)
    }
    createQuestions = (params) => {
        params.questions.map(r => {
            if (r.field_type == 'select' && r.select_answers) {
                console.log('oe', r.select_answers)
                r.select_answers = r.select_answers.map(p => p.title).join('|')
            } else {
                r.select_answers = ''
            }
        })
        return this.network.post(this.network.endpoints.quizzesQuestions, params)
    }
    list = (params) => {
        return this.network.get(this.network.endpoints.quizzes, params)
    }
    show = (id) => {
        return this.network.get(`${this.network.endpoints.quizzes}/${id}`)
    }
    delete = (id) => {
        return this.network.delete(`${this.network.endpoints.quizzes}/${id}`)
    }
    update = (id, params) => {
        return this.network.put(`${this.network.endpoints.quizzes}/${id}`, params)
    }
    getQuestionType(type) {
        // Possíveis types de uma missao ['free','standard','repetition']
        console.log(type)
        const defaultObject = {
            title: ''
        }
        return this.questionTypes.find(r => r.id == type) || defaultObject

    }
    // getAnswers(quizId) {
    //     // Possíveis types de uma missao ['free','standard','repetition']
    //     console.log(type)
    //     const defaultObject = {
    //         title: ''
    //     }
    //     return this.questionTypes.find(r => r.id == type) || defaultObject

    // }

}