import {
  countryPhoneCodes,
  currencyMask,
  phoneMask,
  weekdays,
  datetimeMask,
} from "./HelperVariables";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function timeMask(value) {
  const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  return value.length > 2 ? [...hours, ":", ...minutes] : hours;
}

function pad(d) {
  return d ? (d < 10 ? "0" + d.toString() : d.toString()) : "";
}

/**
 *
 * @param {*} originArray array de itens em que deseja procurar
 * @param {*} properties qual property do obj deseja comparar a string, se for vazio, busca em todas q forem string ou number
 * @param {*} string  a string de busca
 * @returns retorna o mesmo arrray mas com uma propriedade hidden, pra esconder da lista
 */
function searchString(originArray, string, ...keys) {
  try {
    let properties = keys;
    if (!string) {
      originArray.map((r) => (r.hidden = false));
      return originArray;
    }
    if (!originArray || originArray.length == 0) return originArray;
    if (!properties || properties.length == 0) {
      properties = Object.keys(originArray[0]);
    }
    var resultArray = originArray;
    resultArray.map((item) => {
      let reg = /[\u0300-\u036f]/g;
      var itemFound = false;
      properties.map((s) => {
        if (item[s] && ["string", "number"].includes(typeof item[s])) {
          if (
            item[s]
              .toString()
              .toLowerCase()
              .normalize("NFD")
              .replace(reg, "")
              .indexOf(string.toLowerCase().normalize("NFD").replace(reg, "")) >
            -1
          ) {
            itemFound = true;
          }
        }
      });
      item.hidden = !itemFound;
    });
    return resultArray;
  } catch (e) {
    console.log(e);
    return originArray;
  }
}

function sortCols(columns, colIndex) {
  try {
    return columns.map((r, index) => {
      let order = null;
      let loading = false;
      if (colIndex == index) {
        loading = true;
        order = r.order == null ? "ASC" : r.order == "ASC" ? "DESC" : null;
      }
      let l = {
        ...r,
        order,
        loading,
      };
      return l;
    });
  } catch (e) {
    console.log(e);
    return columns;
  }
}

function checkDateIsValid(start, end) {
  try {
    console.log(start.length);
    if (start.length < 5 || end.length < 5) return false;
    const date1 = start.replace(":", "");
    const date2 = end.replace(":", "");
    return date1 < date2;
  } catch (e) {
    return false;
  }
}

export {
  sortCols,
  dataURLtoFile,
  timeMask,
  currencyMask,
  phoneMask,
  weekdays,
  pad,
  countryPhoneCodes,
  searchString,
  checkDateIsValid,
  datetimeMask,
};
