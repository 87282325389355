export default {
  props: {
    item: {
    }
  },
  mounted() {
    this.loadingItems = true
    this.getData()
  },

  data() {
    return {
      loadingItems: false,
      countItemsShowing: 0,
      totalItemsRegistered: 0,
      countItemsTotal: 0,
      filters: {
        page: 1,
        q: '',
      },
      healthTests: [],
      columns: [
        {
          name: 'Exame',
          sortName: 'title',
          order: null
        },
        {
          name: 'Criado em',
          sortName: 'created_at',
          order: null
        },
        {
          name: '',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    async getData(loadingMore = false) {
      if (!this.item.id) { return }
      try {
        const res = await this.$petService.listHealthTests({
          ...this.filters,
          pet_id: this.item.id,
          orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
        })
        let data = res.data
        this.$set(this, 'healthTests', data)
        this.moreItemsToLoad = res.next_page_url != null
        this.countItemsShowing = res.to
        this.countItemsTotal = res.total

        if (this.filters.page == 1 && this.filters.q == '') {
          // so seta o o totalzao quando nao tem filtro
          this.$set(this, 'totalItemsRegistered', res.total)
        }

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
        this.loadingMoreItems = false
        this.$set(this, 'columns', this.columns.map(c => { return { ...c, loading: false } }))
      }
    },

    sort(colIndex) {
      this.$set(this, 'columns', this.$helper.sortCols(this.columns, colIndex))
      this.getData()
    },
    search(string) {
      this.$set(this.filters, 'q', string)
      this.getData()
    },
    viewExam(it) {
      window.open(`${this.$baseImageUrl}${it.file}`, '_blank');
    }
  }
}