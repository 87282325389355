import NetworkService from '@/services/NetworkService';
import store from '@/store'
export default class ChatService {
    constructor() {
        this.network = new NetworkService()
    }
    list(filters) {
        return this.network.get(this.network.endpoints.chats, filters)
    }
    show(id) {
        return this.network.get(`${this.network.endpoints.chats}/${id}`)
    }
    update(id, params) {
        return this.network.put(`${this.network.endpoints.chats}/${id}`, params)
    }
    checkForReadMessages() {
        return this.network.get(`${this.network.endpoints.unreadBadge}`)
    }
    create(params) {
        let queryParams = { ...params }
        if (store.state.userSession?.type == 'specialist') {
            queryParams.specialist_id = store.state.userSession?.id
        }
        return this.network.post(`${this.network.endpoints.chats}`, queryParams)
    }
    listMessages(filters) {
        return this.network.get(this.network.endpoints.chatMessages, filters)
    }
    sendMessage(params) {
        return this.network.postMultipart(`${this.network.endpoints.chatMessages}`, params)
    }

    getChatStatus(status) {
        // ['active','concluded','canceled']
        const defaultObject = {
            title: ''
        }
        switch (status) {
            case 'concluded':
                return {
                    title: 'Encerrado',
                    badgeClass: 'badge bg-dark-soft',
                    description: 'Sessão finalizada'
                }
                break;
            case 'canceled':
                return {
                    title: 'Cancelado',
                    badgeClass: 'badge bg-danger-soft',
                    description: 'Sessão cancelada'
                }
                break;
            case 'active':
                return {
                    title: 'Iniciado',
                    badgeClass: 'badge bg-success-soft',
                    description: 'Sessão agendada.'
                }
                break;
            default: return defaultObject
        }
    }
}
