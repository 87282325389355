<template>
  <div id="admins">
    <Navbar current="Convites" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Convites" :isSubHeader="false" subtitle="Registro de convites"
        :iconClass="'fa-solid fa-person-circle-plus text-primary'" />

      <LoadingContainer v-if="loadingUser" />
      <div v-else>
        <template v-if="!hasPix">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Seus convidados assinam e você ganha R$10</h3>
              <p class="card-text mb-4">
                Cada convidado que se tornar um assinante da Bluu, você ganha R$10/mês enquanto ele for um assinante.
              </p>
              <TextInput label="Insira sua chave pix abaixo" :required="true" :model="$v.pixKey" :maxLength="100"
                type="text" placeholder="Sua chave pix" class="w-35" errorMsg="Informe uma chave pix válida" />
              <FormButton @onClick="handleContinuePixKey" :label="'Continuar'" :loading="submittingPixKey"
                :disabled="$v.$invalid" class="w-auto" />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Convide seus amigos para a bluu</h3>
              <p class="card-text mb-4">
                Convide seus amigos e ganhe recompensas. Toda vez que você convidar alguém e essa pessoa se tornar um
                assinante, você ganha R$10 mensalmente enquanto ela for um assinante.
              </p>
              <div class="row" v-if="user?.invitation_code">
                <div class="card col-5">
                  <div class="card-body">
                    <div class="row align-items-center gx-0">
                      <div class="col">
                        <h6 class="text-body-secondary mb-2">
                          Meu código
                        </h6>
                        <span class="h2 mb-0 fw-bold">
                          {{ user.invitation_code }}
                        </span>
                      </div>
                      <div class="col-auto">
                        <CopyText :string="user.invitation_code" class="text-primary" label="Copiar código" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h3 class="card-header-title fw-bold">
                Convites aceitos
              </h3>
            </div>
            <div class="card-body">
              <div>
                <EmptyState v-if="!user?.referrals?.length" title="Nenhum convite aceito"
                  msg="Seu convite não foi usado no até o momento">
                </EmptyState>
                <div class="list-group list-group-flush my-n3" v-else>
                  <div class="list-group-item" v-for="(user) in user.referrals">
                    <div class="row align-items-center">
                      <div class="col">
                        {{ user.user_info?.name }}
                      </div>
                      <div class="col-auto">
                        <span v-if="user.subscriptionInfo.isPremium" class="text-primary">Assinante</span>
                        <span v-else class="text-muted"> Gratuito </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </Container>
    <ModalComponent :title="'Termos de uso'" id="inviteTermsModal" :lg="true">
      <template v-slot:body>
        <div>
          <p v-html="terms"></p>
          <hr>
          <div class="row align-items-center">
            <div class="col">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="invite-terms-check" v-model="termsChecked">
                <label class="form-check-label" for="invite-terms-check"> <strong>Li e aceito os Termos de Uso</strong>
                </label>
              </div>
            </div>
            <div class="col-auto">
              <button type="button" :disabled="!termsChecked" @click="handleContinueTerms" class="btn btn-primary w-15">
                Continuar
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import CopyText from '@/components/CopyText.vue';
import InvitesController from "./InvitesController";
export default InvitesController;
</script>
./InvitesController