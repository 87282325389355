import NetworkService from '@/services/NetworkService';
import store from '@/store'
import moment from 'moment';
export default class MissionsService {

    constructor() {
        this.network = new NetworkService()
    }
    // esse none é so pra questionarios
    // Possíveis pilares de uma missao ['behavior','health','nutrition','hygiene','vaccination']
    pillars = [
        {
            id: 'none',
            title: 'Nenhum',
        },
        {
            id: 'nutrition',
            title: 'Nutrição',
        },
        {
            id: 'behavior',
            title: 'Comportamento',
        },
        {
            id: 'health',
            title: 'Saúde',
        },
        {
            id: 'hygiene',
            title: 'Higiene',
        },
        {
            id: 'vaccination',
            title: 'Vacinação',
        },
    ]
    missionTypes = [
        {
            id: 'free',
            title: 'Gratuito',
        },
        {
            id: 'standard',
            title: 'Assinante',
        }
    ]
    frequencies = [
        {
            id: 'daily',
            title: 'Diário',
            unity: (qtd) => qtd > 1 ? 'dias' : 'dia',
            active: true
        },
        {
            id: 'weekly',
            title: 'Semanal',
            unity: (qtd) => qtd > 1 ? 'semanas' : 'semana',
            active: true
        },
        {
            id: 'monthly',
            title: 'Mensal',
            unity: (qtd) => qtd > 1 ? 'mêses' : 'mês',
            active: false
        },
        {
            id: 'annually',
            title: 'Anual',
            unity: (qtd) => qtd > 1 ? 'anos' : 'ano',
            active: false
        },
    ]
    durations = [
        {
            id: 'hours',
            title: (qtd) => qtd > 1 ? 'Horas' : 'hora',
        },
        {
            id: 'days',
            title: (qtd) => qtd > 1 ? 'Dias' : 'dia',
        },
        {
            id: 'weeks',
            title: (qtd) => qtd > 1 ? 'Semanas' : 'semana',
        },
        {
            id: 'months',
            title: (qtd) => qtd > 1 ? 'Mêses' : 'mês',
        },
    ]
    list = (filters) => {
        let queryParams = { ...filters }
        if (store.state.userSession?.type == 'specialist') {
            queryParams.specialist_id = store.state.userSession?.id
        }
        return this.network.get(this.network.endpoints.missions, queryParams)
    }
    listFreeMissions = (filters) => {
        return this.network.get(this.network.endpoints.missionsFree, filters)
    }
    create = (params) => {
        params.due_date = moment(params.due_date).format('YYYY-MM-DD HH:mm:ss')
        return this.network.post(this.network.endpoints.missions, params)
    }
    createMissionFiles = (missionId, files) => {
        let promises = []
        files.map(r => {
            const params = {
                mission_id: missionId,
                file: r.file,
                type: r.type
            }
            promises.push(this.network.postMultipart(this.network.endpoints.missionFiles, params))
        })
        return Promise.all(promises)
    }
    deleteMissionFiles = (files) => {
        let mission_files = []
        files.map(r => {
            mission_files.push(r.id)
        })
        return this.network.post(this.network.endpoints.deleteMissionFiles, { mission_files })
    }
    update = (id, params) => {
        return this.network.put(`${this.network.endpoints.missions}/${id}`, params)
    }
    delete = (id) => {
        return this.network.delete(`${this.network.endpoints.missions}/${id}`)
    }
    show = (id) => {
        return this.network.get(`${this.network.endpoints.missions}/${id}`)
    }
    getMissionSummary = (pet_id) => {
        return this.network.get(`${this.network.endpoints.missionSummary}`, { pet_id })
    }
    getMissionStatus(status) {
        // Possíveis status de uma missao ['pending_review','canceled','active','done']
        const defaultObject = {
            title: ''
        }
        switch (status) {
            case 'pending':
                return {
                    title: 'Pendente',
                    badgeClass: 'badge bg-info-soft'
                }
                break;
            case 'pending_review':
                return {
                    title: 'Em revisão',
                    badgeClass: 'badge bg-success-soft'
                }
                break;
            case 'canceled':
                return {
                    title: 'Cancelada',
                    badgeClass: 'badge bg-danger-soft'
                }
                break;
            case 'active':
                return {
                    title: 'Em andamento',
                    badgeClass: 'badge bg-success-soft'
                }
                break;
            case 'done':
                return {
                    title: 'Concluída',
                    badgeClass: 'badge bg-dark-soft'
                }
                break;
            case 'late':
                return {
                    title: 'Atrasada',
                    badgeClass: 'badge bg-warning-soft'
                }
                break;
            default: return defaultObject
        }
    }
    getMissionPillar(pillar) {
        const defaultObject = {
            title: 'Outros'
        }
        return this.pillars.find(r => r.id == pillar) || defaultObject
    }
    getFrequency(frequency) {
        const defaultObject = {
            title: ''
        }
        return this.frequencies.find(r => r.id == frequency) || defaultObject
    }
    getMissionType(type) {
        // Possíveis types de uma missao ['free','standard','repetition']
        const defaultObject = {
            title: ''
        }
        return this.missionTypes.find(r => r.id == type) || defaultObject

    }
}
