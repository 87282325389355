<template>
  <!-- <button v-if="copied" class="btn btn-link text-success">
    <span class="fas fa-copy text-success"></span> Copiado!
  </button> -->

  <div v-if="copied">
    <span class="fas fa-check text-success"></span>
    <small class="text-success"> Copiado!</small>
  </div>
  <button v-else class="btn btn-sm btn-link" @click="copy">
    <span class="me-2">{{ label }}</span>
    <i class="fa-regular fa-copy"></i>
  </button>
</template>

<script>
export default {
  name: "CopyText",
  props: {
    string: String,
    label: { type: String, default: '' },
  },
  data() {
    return {
      copied: false
    }
  },
  methods: {
    copy() {
      if (this.copied) { return }
      this.copied = true
      // this.$set(this, 'copied', true)
      setTimeout(() => {
        this.copied = false
        // this.$set(this, 'copied', false)
      }, 2000);
      navigator.clipboard.writeText(this.string);
    }
  }
};
</script>


