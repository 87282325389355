<template>
  <div id="edit-admins">
    <Navbar current="Editar administrador" :link="$paths.admins.list" :sublink="`${$paths.admins.detail}/${id}`"
      :linkName="'Administradores'" :sublinkName="'Detalhes do administrador'" />
    <Sidebar />
    <Container>
      <Header title="Editar Administrador" />
      <!-- <Header
        title="Editar Administrador"
        :btnLink="`${$paths.admins.password_update}/${id}`"
        btnLabel="Alterar senha"
      /> -->
      <div class="card">
        <div class="card-body">
          <form>
            <TextInput label="Nome" type="text" :model="$v.form.name" placeholder="Nome" />
            <TextInput :value="item.email" :readOnly="true" label="Email" />
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <h3 class="my-4">Permissões</h3>
            <PermissionsComponent @onPermissionChanged="handlePermissionsChanged" :value="form.roles" />
            <FormButton label="Editar" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import EditAdminsController from "./EditAdminsController";
export default EditAdminsController;
</script>
<style scoped></style>
