import { Modal } from 'bootstrap';
import moment from 'moment';
import { minLength, required } from 'vuelidate/lib/validators';
import AgendaDay from './AgendaDay/AgendaDay.vue';

export default {
  name: "AgendaComponent",
  props: {
    date: {
      default: () => moment()
    }
  },
  watch: {
    currentDate(newVal, oldVal) {
      //toda vez que troca a data, chama a funcao que faz o get 
      this.listAgenda()
    },
    appointments(newVal, oldVal) {
      //toda vez que retorna os appointments, remonta o calendario com as parada
      this.prepareMonthDays()
    }
  },
  mounted() {
    this.editDateModal = new Modal(document.getElementById('editDateModal'), {
      backdrop: 'static',
      keyboard: false
    })
    this.listAgenda()
  },
  computed: {
    formattedMonth() {
      let c = this.currentDate.format('MMMM YYYY').capitalizeFirst()
      return c
      // return this.count
    },
    userSession() {
      return this.$store.state.userSession;
    },
    modalTitle() {
      return this.selectedEditDate ? `Horários de atendimento de ${this.selectedEditDate.date.format('dddd - DD/MM/YYYY')}` : ''
    }
  },
  data() {
    return {
      updateErrorMsg: '',
      loadingUpdate: false,
      selectedEditDate: null,
      editDateModal: null,
      loadingItems: true,
      currentDate: moment(this.date),
      monthDays: [],
      appointments: [],
      agendaTimeout: 0,
    }
  },
  validations: {
    selectedEditDate: {
      availableTimes: {
        $each: {
          from: {
            // required: requiredIf('show'),
            required,
            minLength: minLength(5)
          },
          to: {
            // required: requiredIf('show'),
            required,
            valid_date: function (value, sibilings) {
              return this.$helper.checkDateIsValid(sibilings.from, value)
            }
          },
        }
      }
    }

  },
  methods: {
    prepareMonthDays(date) {
      let weeks = [[], [], [], [], [], []]
      let startDay = moment(this.currentDate).startOf('month').startOf('week')
      let index = 0
      let j = 0
      while (index < weeks.length) {
        j = 0
        while (j < 7) {
          /**
           * // TODO: isso executa depois  do listAgenda.. aqui ele ja vai ter as info referente ao mes selecionado no calendario, tipo horarios disponiveis, agendamentos, etc ( this.appointments).
           * aqui monto o calendario em si e ja faço um filter no this.appointments pra pegar as datas disponiveis e agendamentos daquele dia,
           */

          const day = moment(startDay).format('YYYY-MM-DD')
          const d = {
            date: day, //essa e o dia do mes mesmo
            appointments: this.appointments[day]?.appointments || [],
            availableTimes: this.appointments[day]?.times || []

          }
          startDay.add(1, 'd')
          weeks[index].push(d)
          j++
        }
        index++
      }
      this.monthDays = [...weeks]
    },
    handleNextMonth() {
      this.currentDate = moment(this.currentDate).add(1, 'month')
    },
    handlePreviuosMonth() {
      this.currentDate = moment(this.currentDate).add(-1, 'month')
    },
    listAgenda() {
      //depois de 1200ms que ele muda a data, ele chama o back.. pra se ele ficar mudando rapidao de mes, nao fica arregaçando o back (tipo a search bar)
      clearTimeout(this.agendaTimeout)
      this.loadingItems = true
      this.agendaTimeout = setTimeout(async () => {
        try {
          const res = await this.$appointmentsService.listAgenda(this.userSession.id, this.currentDate)
          //depois que seta o appointment ele da o trigger no watch la em cima
          this.appointments = res.data
        } catch (e) {
          console.log(e)
        } finally {
          this.loadingItems = false
        }
      }, 1200);
    },
    handleMyAgenda() {
      window.open(`${this.$paths.specialists.detail}/${this.userSession.id}`, '_blank');
    },
    handleToday() {
      this.currentDate = moment()
    },
    handleEditCurrentDay($event, weekIndex, dayIndex) {
      this.updateErrorMsg = ''
      let day = $event
      day.date = moment(day.date)
      day.weekIndex = weekIndex
      day.dayIndex = dayIndex
      if (!day.availableTimes?.length) {
        day.availableTimes = [
          {
            from: '',
            to: '',
          }
        ]
      }
      this.selectedEditDate = day
      this.editDateModal.toggle()
    },
    handleEditWeekDay($event, weekIndex, dayIndex) {
      window.open(`${this.$paths.specialists.edit}/${this.userSession.id}#schedule-container-title`, '_blank');
    },
    handleCancelDateEdition() {
      this.editDateModal.toggle()
    },
    async handleConfirmDateEdition() {
      try {
        this.loadingUpdate = true
        const res = await this.$appointmentsService.updateScheduleForDay(this.selectedEditDate.date.format('YYYY-MM-DD'), [...this.selectedEditDate.availableTimes])
        let m = [...this.monthDays]
        m[this.selectedEditDate.weekIndex][this.selectedEditDate.dayIndex].availableTimes = this.selectedEditDate.availableTimes
        this.monthDays = m
        this.$eventBus.$emit('showToast', { title: 'Horário alterado', msg: `Sua disponibilidade do dia ${this.selectedEditDate.date.format('DD/MM/YYYY')} foi alterada` })
        this.handleCancelDateEdition()
      } catch (e) {
        this.updateErrorMsg = e.message
        console.log(e)
      } finally {
        this.loadingUpdate = false
      }
    },
    addTime() {
      this.selectedEditDate.availableTimes.push({ from: '', to: '' })
    },
    removeTime(index) {
      this.selectedEditDate.availableTimes.splice(index, 1)
    },
    isInThePast(day) {
      return moment(day.date).isBefore(moment(), 'day')
    },
  },
  components: {
    AgendaDay
  }
};

