<template>
  <div>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :class="{ 'modal-lg': lg }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border">
            <h2 class="h2 mb-0">{{ title }}</h2>
            <i type="button" class="fas fa-times text-muted" data-bs-dismiss="modal" aria-label="Close"
              @click="modalClosed"></i>
          </div>
          <div class="modal-body border-0" style="max-height: 350px;" :class="modalBodyClass">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer border" v-if="this.$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <button hidden :id="`${id}-modal-btn`" type="button" data-bs-toggle="modal" :data-bs-target="`#${id}`"></button>
  </div>
</template>

<script>
export function toggleModal(id) {
  const button = document.getElementById(`${id}-modal-btn`)
  if (button) {
    button?.click()
  }
}
export default {
  props: {
    id: String,
    title: String,
    modalBodyClass: String,
    lg: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    modalClosed() {
      this.$emit('modalClosed')
    }
  }
};
</script>

<style></style>