<template>
  <div class="list-group list-group-flush my-n3">
    <div class="list-group-item"
      :class="{ 'opacity-35': permissionHasAdmin && elem.id != $permissionService.ROLES.ADMIN }"
      v-for="(elem) in $permissionService.permissionList.filter(r => r.id != $permissionService.ROLES.SPECIALIST)">
      <div class="row align-items-center">
        <div class="col-auto">
          <input type="checkbox" style="display: none;" :id="elem.id" :value="elem.id" v-model="permissions"
            @change="handlePermissionsChanged(elem)">
          <label :for="elem.id" class="">
            <span :for="elem.id" v-if="permissions.includes(elem.id) || permissionHasAdmin"
              class="fa-solid fa-circle-check fa-medium-size mb-0 text-primary me-2"
              :class="{ 'text-muted': permissionHasAdmin && elem.id != $permissionService.ROLES.ADMIN }"></span>
            <span :for="elem.id" v-else class="far fa-circle fa-medium-size mb-0 text-muted me-2"></span>
          </label>
        </div>
        <div class="col">
          <h4 class="mb-1">
            <span class="me-2" :class="elem.icon"></span>
            <strong>{{ elem.name }}</strong>
          </h4>
          <small class="text-muted">
            {{ elem.description }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PermissionsComponentController from "./PermissionsComponentController";
export default PermissionsComponentController;
</script>
<style scoped></style>
