<template>
  <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-dark" id="sidebar">
    <div class="container-fluid">
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link tag="a" :to="$paths.home" class="navbar-brand text-start">
        <img class="navbar-brand-img" src="@/assets/img/logo.png" alt="Diag Backoffice" />
        <span class="h2 text-white ms-3">Bluu {{ $envString }} <small style="font-size: 0.7rem" class="text-muted">{{
          $versionString
            }}</small> </span>
      </router-link>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-sm">
              <i class="fas fa-user-circle display-4 text-light"></i>
              <!-- <img src="./assets/img/avatars/profiles/avatar-1.jpg" class="avatar-img rounded-circle" alt="..."> -->
            </div>
          </a>

          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <a href="#" class="dropdown-item">Profile</a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Navigation -->
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.home" tag="li" class="nav-item">
            <a class="nav-link">
              <i class="sidebar-icon fa-solid fa-chart-simple"></i>
              Visão geral
            </a>
          </router-link>
        </ul>

        <hr class="navbar-divider my-3" />
        <template
          v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.CUSTOMER_SUPPORT])">
          <h6 class="navbar-heading mb-0">Usuários</h6>
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="$paths.admins.list" tag="li" class="nav-item"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-user-shield"></i>
                Administradores
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.users.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-user-group"></i>
                Tutores
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.specialists.list" tag="li" class="nav-item"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-stethoscope"></i>
                Especialistas
              </a>
            </router-link>
          </ul>
          <hr class="navbar-divider" />
        </template>

        <template>
          <h6 class="navbar-heading mb-0">Área do especialista</h6>
          <ul class="navbar-nav">
            <router-link
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST, $permissionService.ROLES.CUSTOMER_SUPPORT])"
              active-class="router-link-active" :to="$paths.pets.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-clipboard-user"></i>
                Prontuários
              </a>
            </router-link>
            <router-link
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST, $permissionService.ROLES.CUSTOMER_SUPPORT])"
              active-class="router-link-active" :to="$paths.customerService.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa fa-comment-medical"></i>
                Atendimentos
                <small v-if="chatBadge">
                  <i class="sidebar-icon fa fa-circle ms-2 text-success"></i>
                </small>
              </a>
            </router-link>
            <router-link
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.MARKETING, $permissionService.ROLES.SPECIALIST])"
              active-class="router-link-active" :to="$paths.contents.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-puzzle-piece"></i>
                Conteúdos
              </a>
            </router-link>
            <router-link v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])"
              active-class="router-link-active" :to="$paths.events.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-regular fa-calendar-days"></i>
                Eventos
              </a>
            </router-link>
            <router-link v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])"
              active-class="router-link-active" :to="$paths.vets.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-shield-dog"></i>
                Sessões de veterinários
              </a>
            </router-link>
            <router-link v-if="$permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])"
              active-class="router-link-active" :to="$paths.invites.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-person-circle-plus"></i>
                Convites
              </a>
            </router-link>
          </ul>
        </template>

        <template
          v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.MARKETING])">

          <hr class="navbar-divider" />
          <h6 class="navbar-heading mb-0">Sobre os tutores e pets</h6>
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="$paths.missions.list" tag="li" class="nav-item"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-clipboard-check"></i>
                Missões
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.tasks.list" tag="li" class="nav-item"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-list-check"></i>
                Tarefas
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.quizzes.list" tag="li" class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-clipboard-question"></i>
                Questionários
              </a>
            </router-link>
          </ul>
        </template>
        <template
          v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.MARKETING])">
          <hr class="navbar-divider" />
          <h6 class="navbar-heading mb-0">Aplicativo</h6>
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="{ path: $paths.appNotifications }" tag="li"
              class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-bell"></i>
                Notificação
              </a>
            </router-link>
          </ul>
        </template>
        <template v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
          <hr class="navbar-divider" />
          <h6 class="navbar-heading mb-0">Contratos & Termos</h6>
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="{ path: $paths.settings.terms.edit }" tag="li"
              class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fa-solid fa-file-contract"></i>
                Contratos
              </a>
            </router-link>
          </ul>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    chatBadge() {
      const userInfo = this.$store.state.userInfo
      return userInfo?.chatBadge || userInfo?.appointmentBadge;
    }
  },
  mounted() {
    this.isAdmin = this.userSession?.type == 'admin'
  },
  data() {
    return {
      isAdmin: false
    };
  },
};
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
</style>
