import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      columns: [
        {},
        {
          name: "Titulo",
          sortName: null,
          order: null,
        },
        {
          name: "Data",
          sortName: null,
          order: null,
        },
        {
          name: "Participantes",
          sortName: null,
          order: null,
        },
        {
          name: "Criação",
          sortName: null,
          order: null,
        },
        {},
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$eventsService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
  },
};
