import DutyCardComponent from '@/components/DutyCardComponent/DutyCardComponent.vue';
export default {
    name: "Home",
    data() {
        return {
            loadingItems: false,
            info: {
                users: 0,
                admins: 0,
            },
            currentMonth: this.$moment().locale("pt").format('MMMM'),
            columns: [
                {
                    name: 'Tutor',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Status',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Data',
                    sortName: null,
                    order: null
                },
            ]
        }
    },
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    methods: {
        async getData() {
            try {
                let res = await this.$userService.statistics()
                if (res?.data?.general?.next_appointments) {
                    res.data.general.next_appointments = res.data.general.next_appointments.map(elem => this.$appointmentsService.getFormattedAppointment(elem))
                }
                this.$set(this, 'info', res.data)
            } catch (error) {
                console.log('ERRO NO GET USER NA HOME', error)
            } finally {
                this.loadingItems = false
            }
        }
    },
    components: {
        DutyCardComponent
    }
};