<template>
  <div
    class="
      d-flex
      align-items-center
      bg-dark
      border-top border-top-2 border-primary
      vh-100
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 my-5">
          <div class="d-flex justify-content-center mb-3">
            <img src="@/assets/img/logo.png" alt="Diag" width="70" />
          </div>

          <p class="text-muted text-center mb-5">Diag | {{ $envString }}</p>

          <form>
            <TextInput
              label="Email"
              :model="$v.form.email"
              type="email"
              class="text-white"
              placeholder="nome@email.com"
            />
            <FormButton
              label="Enviar"
              :loading="submitting"
              :disabled="$v.$invalid"
              class="btn btn-lg w-100 btn-primary mb-3"
              @onClick="login"
            />
            <div class="text-center">
              <small class="text-muted text-center">
                Lembrou da senha?
                <router-link tag="a" :to="$paths.login"> Acesse.</router-link>
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForgotPasswordController from "./ForgotPasswordController";
export default ForgotPasswordController;
</script>
