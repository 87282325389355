<template>
  <div id="add-admin">
    <Navbar :current="'Enviar notificação'" />
    <Sidebar />
    <Container>
      <Header :title="'Enviar Notificação'" :iconClass="'fa-solid fa-bell text-primary'" />
      <form ref="form" class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-12">
              <TextInput :required="true" label="Título" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title" type="text"
                placeholder="Informe um título para a notificação"
                :errorMsg="'Informe um título com ao menos 5 caracteres'" />
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput :rows="10" :required="true" label="Descrição" :model="$v.form.description" type="text"
                :maxLength="DESCRIPTION_MAX_LENGTH" placeholder="Informe o conteúdo da notificação">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{ DESCRIPTION_MAX_LENGTH -
                      form.description.length
                    }} caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>

          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
          </form>
        </div>
      </form>
    </Container>
  </div>
</template>
<script>
import NotificationsController from "./NotificationsController";
export default NotificationsController;
</script>
<style scoped></style>
