<template>
    <div id="add-admin">
        <Navbar :current="id ? 'Editar Evento' : 'Adicionar Evento'" :link="$paths.missions.list" :linkName="'Eventos'" />
        <Sidebar />
        <Container>
            <Header :title="id ? 'Editar Evento' : 'Adicionar Evento'" />
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12" v-if="!this.id">
                            <label class="text-muted mb-3 mt-3 form-label">Imagem <i class="text-danger">*</i></label>
                            <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="16 / 9"
                                v-on:cropEnd="cropEnd" />
                            <p v-if="$v.form.file.$invalid" class="text-danger">* Selecione uma imagem para o evento</p>
                        </div>
                        <div class="col-12 mb-3" v-else>
                            <label class="text-muted mb-3 mt-3 form-label">Imagem <i class="text-danger">*</i></label>
                            <div class="w-25 position-relative">
                                <div style="right: -8px; bottom: -8px" class="position-absolute">
                                    <button @click="editImageClicked()" class="btn btn-primary rounded-circle"><span
                                            class="fas fa-pencil"></span></button>
                                    <button v-if="form.file" @click="removeImage"
                                        class="btn btn-secondary rounded-circle ms-2"><span
                                            class="fas fa-trash-alt"></span></button>
                                </div>
                                <img v-if="form.file" :src="form.file.base64" alt="..." class="avatar-img rounded-4"
                                    style="height:100%; width: 100%; object-fit: fill">
                                <img v-else :src="`${$baseImageUrl}${existingImage}`" alt="..." class="avatar-img rounded-4"
                                    style="height:100%; width: 100%; object-fit: fill">
                            </div>
                            <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="16 / 9"
                                v-on:cropEnd="cropEnd" inputId="event-add-message-input" :hiddenInput="true" />
                        </div>
                        <div class="col-12 col-md-12">
                            <TextInput :required="true" label="Título" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title"
                                type="text" placeholder="Informe um título para o artigo"
                                :errorMsg="'Informe um título com ao menos 10 caracteres'" />
                        </div>
                        <div class="col-12 col-md-12">
                            <TextInput :required="true" label="Data do evento" :model="$v.form.date" type="date"
                                placeholder="Informe a data do evento" :errorMsg="'Informe uma data válida'" />
                        </div>
                        <div class="col-12 col-md-12">
                            <TextInput :required="true" label="Link" :model="$v.form.link" type="text"
                                placeholder="Informe o link para o evento" :errorMsg="'Informe um link válido'" />
                        </div>
                    </div>
                    <form>
                        <div class="row">
                            <div class="col-12 text-center">
                                <span class="text-danger text-center">{{ errorMessage }}</span>
                            </div>
                        </div>
                        <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
                    </form>
                </div>
            </div>
        </Container>
    </div>
</template>
<script>
import EventAddController from "./EventAddController";
export default EventAddController;
</script>
<style scoped></style>
  