import NetworkService from "@/services/NetworkService";
import moment from "moment";
import PermissionService from "./PermissionService";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
    this.permissionService = new PermissionService();
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.users, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.users, params);
  };
  updatePicture = (id, params) => {
    return this.network.postMultipart(
      `${this.network.endpoints.users}/${id}/update`,
      params
    );
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.users}/${id}`, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.users}/${id}`, {});
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.users}/${id}`);
  };
  settings = (key) => {
    return this.network.get(`${this.network.endpoints.settings}/${key}`);
  };
  updateSettings = (key, value) => {
    return this.network.put(`${this.network.endpoints.settings}/${key}`, {
      value,
    });
  };
  login = async (params) => {
    let res = await this.network.post(this.network.endpoints.login, params);
    if (["admin", "specialist"].includes(res.data.type)) {
      return res;
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };
  forgotPassword = (params) => {
    return this.network.post(this.network.endpoints.forgotPassword, params);
  };
  logout = () => {
    return this.network.put(`${this.network.endpoints.users}/logout`);
  };
  statistics = () => {
    return this.network.get(this.network.endpoints.statistics);
  };
  createWorkingHours(params) {
    return this.network.post(this.network.endpoints.workingHours, params);
  }
  listCrashes = () => {
    return this.network.get(`${this.network.endpoints.appCrashes}`);
  };
  showCrash = (id) => {
    return this.network.get(`${this.network.endpoints.appCrashes}/${id}`);
  };
  sendNotification = (params) => {
    return this.network.post(
      `${this.network.endpoints.sendNotification}`,
      params
    );
  };
  upgradeToPremium = (userId) => {
    return this.network.post(
      `${this.network.endpoints.users}/${userId}/give-free-premium`
    );
  };
  removePremium = (userId) => {
    return this.network.post(
      `${this.network.endpoints.users}/${userId}/remove-free-premium`
    );
  };
  confirmEmail = (userId) => {
    return this.network.post(
      `${this.network.endpoints.users}/${userId}/fake-validate-email`
    );
  };
  getSubscriptionInfo = (user) => {
    let isPremium = false;
    let subscriptionDate = "-";
    let subscriptionString = "Não assinante";
    let upgradedByAdmin = false;
    let subscriptionType = '';

    if (user) {
      const stripeSub = user.stripe_subscription
      console.log('stripeSub', stripeSub)
      if (user.plan_status == "paid" || stripeSub?.status == 'active' || stripeSub?.status == 'trialing') {
        isPremium = true
      }
      subscriptionString = isPremium ? "Assinante" : "Não assinante";
      if (isPremium) {
        if (user.subscription || stripeSub) {
          if (user.subscription) {
            subscriptionType = 'InApp'
            subscriptionDate = moment(user.subscription.created_at).format("DD/MM/YYYY");
          } else {
            subscriptionType = stripeSub?.plan ? stripeSub?.plan.title : 'Plano sem nome'
            subscriptionType += ' (Stripe)'
            subscriptionDate = moment(stripeSub.created_at).format("DD/MM/YYYY");
          }
        } else {
          subscriptionType = 'Admin'
          upgradedByAdmin = true;
          subscriptionDate = "Upgrade pelo Administrador";
        }
      }
    }
    return {
      isPremium,
      subscriptionString,
      subscriptionDate,
      upgradedByAdmin,
      subscriptionType,
    };
  };
  formatUserPermissions(roles) {
    if (!roles) return "";
    return roles
      .map((r) => {
        const perm = this.permissionService.permissionList.find(
          (p) => p.id == r
        );
        return perm?.name || "";
      })
      .join(", ");
  }
  getFormattedUserAccount(data) {
    data.formatted_created_at = moment(data.created_at).format("DD/MM/YYYY");

    if (data.specialist_info?.specialties) {
      const sp = data.specialist_info.specialties.split("|");
      data.specialties = sp;
    }

    if (data.working_hours) {
      const fh = {
        monday: data.working_hours.filter((r) => r.weekday === "monday"),
        tuesday: data.working_hours.filter((r) => r.weekday === "tuesday"),
        wednesday: data.working_hours.filter((r) => r.weekday === "wednesday"),
        thursday: data.working_hours.filter((r) => r.weekday === "thursday"),
        friday: data.working_hours.filter((r) => r.weekday === "friday"),
        saturday: data.working_hours.filter((r) => r.weekday === "saturday"),
        sunday: data.working_hours.filter((r) => r.weekday === "sunday"),
      };
      data.formatted_working_hours = fh;
    }

    data.formatted_permissions = this.formatUserPermissions(data.roles);
    return data;
  }
}
