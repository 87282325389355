<template>
  <div id="quiz_detail">
    <Navbar current="Detalhes do questionário" :link="$paths.quizzes.list" :linkName="'Questionários'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Questionário">
        <template v-slot:button>
          <button @click="handleDelete" class="btn btn-outline-primary ms-3">
            Remover
          </button>
          <router-link :to="`${$paths.quizzes.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>

      <!-- <Header v-else title="Detalhes da Missão" /> -->
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <h3>Informações do questionário</h3>
            <div class="row">
              <!-- <label class="text-muted mb-3 mt-3 form-label">Imagem do questionário</label>
              <div class="col-12 col-xl-12">
                <img class="avatar quiz-img border border-light rounded-3 avatar-xxl mb-4"
                  :src="`${$baseImageUrl}${item.picture}`" alt="">
              </div> -->
              <div class="col-12 col-xl-8">
                <TextInput :readOnly="true" label="Título" :value="item.title" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Pilar" :value="item.pillarObject?.title" />
              </div>
              <div class="col-12 col-xl-12">
                <TextAreaInput :readOnly="true" label="Descrição" :value="item.description" />
              </div>

              <hr>
              <div class="row" v-for="(question, index) in item.questions" v-bind:key="'tr-' + index">
                <h3>Questão {{index+1}}</h3>
                <div class="col-12 col-xl-12">
                  <TextInput :readOnly="true" label="Tipo de resposta" :value="question.question_type" />
                </div>
                <div class="col-12 col-xl-8">
                  <TextInput :readOnly="true" :label="`Pergunta`" :value="question.title" />
                </div>
                <div class="col-12 col-xl-4">
                  <TextInput :readOnly="true" :label="`Label`" :value="question.field_label" />
                </div>
                <template v-if="question.question_alternatives?.length">
                  <!-- handleAddAlternative -->
                  <div class="row" v-for="(alternative, altIndex) in question.question_alternatives">
                    <div class="col-12 col-xl-6">
                      <TextInput :readOnly="true" :label="`Alternativa ${altIndex+1}`" :value="alternative" />
                    </div>
                  </div>
                </template>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<style scoped>
  img.quiz-img {
    width: 200px !important;
    object-fit: cover;
  }
</style>
<script>
  import QuizDetailController from "./QuizDetailController";
  export default QuizDetailController;
</script>