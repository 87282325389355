import ListControllerMixin from "@/mixins/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Nome',
          sortName: 'name',
          order: null
        },
        {
          name: 'Email',
          sortName: 'email',
          order: null
        },
        {
          name: 'Permissões',
          sortName: null,
          order: null
        },
        {
          name: 'Data',
          sortName: 'created_at',
          order: null
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        type: 'admin',
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    },
    prepareResponse(data) {
      return data.map(elem => {
        return {
          ...elem,
          formatted_roles: this.$userService.formatUserPermissions(elem.roles)
        }
      })
    }
  }
};