import Appointments from './Appointments/Appointments.vue'
import AgendaComponent from '../../components/AgendaComponent/AgendaComponent.vue'
import Chats from './Chats/Chats.vue'
import { toggleModal } from '@/components/ModalComponent.vue';
import { searchString } from '@/utils/Helpers';
import { createChat } from '../Users/userFunctions';
export default {
  name: 'Tasks',
  watch: {
    "$route.params"(value) {
      this.tabQueryParam = value.tab
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    chatBadge() {
      const userInfo = this.$store.state.userInfo
      return userInfo?.chatBadge || userInfo?.appointmentBadge;
    },
    selectedTab() {
      if (!this.tabQueryParam) {
        return this.userSession?.type == 'specialist' ? 'agenda-specialist' : 'appointments'
      }
      return this.tabQueryParam
    }
  },
  mounted() {
    this.getUsers()
    this.tabQueryParam = this.$route.params.tab
    if (this.userSession?.type == 'admin') {
      this.tabs = [
        {
          id: 'appointments',
          name: 'Sessões'
        },
        {
          id: 'chats',
          name: 'Chat Geral'
        },
      ]
    } else {
      this.tabs = [
        {
          id: 'agenda-specialist',
          name: 'Agenda'
        },
        {
          id: 'chats-specialist',
          name: 'Minhas conversas'
        },
        {
          id: 'appointments',
          name: 'Sessões'
        },
        {
          id: 'chats',
          name: 'Chat Geral'
        }
      ]
    }
  },
  data() {
    return {
      selectedChatUser: null,
      loadingCreateChat: false,
      chatUsers: null,
      tabQueryParam: null,
      tabs: [],
      createChatModalId: 'create-chat-modal',
    };
  },
  methods: {
    openChatModal() {
      toggleModal(this.createChatModalId)
    },
    handleSelectChatUser(item) {
      this.selectedChatUser = item
    },
    async getUsers() {
      try {
        const res = await this.$userService.list({ type: "user" })
        this.chatUsers = res.data
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    handleCreateChat() {
      this.loadingCreateChat = true
      createChat(this.selectedChatUser.id, () => {
        this.loadingCreateChat = false
        toggleModal(this.createChatModalId)
      })
    },
    searchChatUser(string) {
      this.chatUsers = JSON.parse(JSON.stringify(searchString(this.chatUsers, string, 'name')))
    }
  },
  components: {
    Appointments,
    Chats,
    AgendaComponent
  }
};