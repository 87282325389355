<template>
  <div id="admins_detail">
    <Navbar current="Detalhes do administrador" :link="$paths.admins.list" :linkName="'Administradores'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Administrador">
        <template v-slot:button>
          <button @click="handleDelete" class="btn btn-link text-danger ms-3">
            <i class="fa-solid fa-trash-alt me-1"></i>
            Remover
          </button>
          <router-link :to="`${$paths.admins.password_update}/${id}`" tag="a" class="btn btn-outline-primary lift ms-3">
            <i class="fa-solid fa-unlock-keyhole me-1"></i>
            Alterar senha
          </router-link>
          <router-link :to="`${$paths.admins.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações</h3>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Nome" :value="item.name" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Data de criação" :value="item.formatted_created_at" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Email" :value="item.email" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput :readOnly="true" label="Permissões" :value="item.formatted_permissions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AdminDetailController from "./AdminDetailController";
export default AdminDetailController;
</script>
