import { email, required, sameAs } from 'vuelidate/lib/validators';
import PermissionsComponent from '@/components/PermissionsComponent/PermissionsComponent'
export default {
  name: 'AddAdmins',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      item: {},
      errorMessage: '',
      submitting: false,
      // id: this.$route.params.id,
      form: {
        name: '',
        roles: [],
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      roles: {
        required,
      },
    }
  },
  components: {
    PermissionsComponent
  },
  methods: {
    async getData() {
      try {
        const res = await this.$userService.show(this.id)
        let data = res.data
        this.$set(this, 'form', { name: data.name, roles: data.roles || [] })
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    async submit() {
      try {
        // console.log(this.form)
        // return
        this.submitting = true
        this.errorMessage = ''
        const values = this.form
        const res = await this.$userService.update(this.id, values)
        this.$eventBus.$emit('showToast', { title: 'Admin alterado', msg: `Admin alterado com sucesso` })
        this.$router.back()
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
    handlePermissionsChanged(permissions) {
      console.log(permissions)
      this.$set(this.form, 'roles', permissions)
    }

  }
};