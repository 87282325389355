<template>
  <div id="home">
    <Navbar current="Home" />
    <Sidebar />
    <Container col="col-12">
      <!-- HEADER  -->
      <Header :isSubHeader="false" title="Visão geral"
        :subtitle="`Informações pertencentes ao mês de ${currentMonth.capitalizeFirst()}`"
        :iconClass="'fa-solid fa-chart-simple text-primary'" />

      <DutyCardComponent />

      <!-- LOADING CARDS -->
      <div v-if="loadingItems" class="row">
        <div class="col-12">
          <div class="row placeholder-glow">
            <div class="col-6">
              <span class="placeholder placeholder-lg col-12"></span>
            </div>
            <div class="col-6">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="row placeholder-glow">
            <div class="col-6">
              <span class="placeholder placeholder-lg col-12"></span>
            </div>
            <div class="col-6">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="row placeholder-glow">
            <div class="col-6">
              <span class="placeholder placeholder-lg col-12"></span>
            </div>
            <div class="col-6">
              <span class="placeholder col-12"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- CARDS -->
      <template v-else>
        <div class="row">
          <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
            <HomeCard :path="$paths.users.list" iconClass="fa-solid fa-user-group" title="Tutores"
              :qtd="info?.current_month?.users" />
          </div>
          <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
            <HomeCard :path="$paths.pets.list" iconClass="fa-solid fa-solid fa-dog" title="Pets cadastrados"
              :qtd="info?.current_month?.pets" />
          </div>
          <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
            <HomeCard :path="$paths.missions.list" iconClass="fa-solid fa-clipboard-check" title="Missões"
              :qtd="info?.current_month?.missions" />
          </div>
          <div class="col-12 col-lg-4"
            v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST, $permissionService.ROLES.MARKETING])">
            <HomeCard :path="$paths.contents.list" iconClass="fa-solid fa-puzzle-piece" title="Conteúdos"
              :qtd="info?.current_month?.articles" />
          </div>
          <div class="col-12 col-lg-4"
            v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST])">
            <HomeCard :path="$paths.customerService.list" iconClass="fa-solid fa-comment-medical" title="Atendimentos"
              :qtd="info?.current_month?.chats" />
          </div>
          <div class="col-12 col-lg-4"
            v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.MARKETING])">
            <HomeCard iconClass="fa-solid fa-coins" title="Total de assinantes"
              :qtd="info?.current_month?.premiun_users" />
          </div>
          <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])">
            <HomeCard iconClass="fa-solid fa-headset" title="Sessões atendidas"
              :qtd="info?.current_month?.concluded_sessions_count" />
          </div>
          <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])">
            <HomeCard iconClass="fa-solid fa-stopwatch" title="Tempo total em sessões (mins)"
              :qtd="info?.current_month?.concluded_sessions_count * 30" />
          </div>
        </div>

        <!-- CARDS DE TODO O PERÍODO, os especialistas nao veem os dados do periodo -->
        <template v-if="!$permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])">
          <p class="text-muted">Informações de todo o período</p>
          <hr>
          <div class="row">
            <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <HomeCard :path="$paths.users.list" iconClass="fa-solid fa-user-group" title="Tutores"
                :qtd="info?.general?.users" />
            </div>
            <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <HomeCard :path="$paths.pets.list" iconClass="fa-solid fa-solid fa-dog" title="Pets cadastrados"
                :qtd="info?.general?.pets" />
            </div>
            <div class="col-12 col-lg-4" v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN])">
              <HomeCard :path="$paths.missions.list" iconClass="fa-solid fa-clipboard-check" title="Missões"
                :qtd="info?.general?.missions" />
            </div>
            <div class="col-12 col-lg-4"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST, $permissionService.ROLES.MARKETING])">
              <HomeCard :path="$paths.contents.list" iconClass="fa-solid fa-puzzle-piece" title="Conteúdos"
                :qtd="info?.general?.articles" />
            </div>
            <div class="col-12 col-lg-4"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.SPECIALIST])">
              <HomeCard :path="$paths.customerService.list" iconClass="fa-solid fa-comment-medical" title="Atendimentos"
                :qtd="info?.general?.chats" />
            </div>
            <div class="col-12 col-lg-4"
              v-if="$permissionService.isAllowed([$permissionService.ROLES.ADMIN, $permissionService.ROLES.MARKETING])">
              <HomeCard iconClass="fa-solid fa-coins" title="Total de assinantes" :qtd="info?.general?.premiun_users" />
            </div>
          </div>
        </template>

        <!-- proximas sessoes, so pra especialistas-->
        <template v-if="$permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])">
          <p class="text-muted">Próximas sessões</p>
          <hr>
          <div class="col-12 card card-body">
            <div class="table-responsive" v-if="info?.general?.next_appointments?.length">
              <table class="
                  table table-sm table-nowrap
                  card-table
                  table-hover
                  cursor-pointer
                ">
                <TableHead :columns="columns" />
                <tbody class="list">
                  <router-link tag="tr" v-for="(item, index) in info?.general?.next_appointments"
                    v-bind:key="'tr-' + index" :to="`${$paths.appointments.detail}/${item.id}`">
                    <td>{{ item.user.name }}</td>
                    <td>
                      <span :class="item.statusObject?.badgeClass">
                        {{ item.statusObject?.title }}
                      </span>
                    </td>
                    <td class="text-end">
                      <time>{{
                        $moment(item.date).format("DD/MM/YYYY HH:mm")
                      }}</time>
                    </td>
                  </router-link>
                </tbody>
              </table>
            </div>
            <EmptyState v-else>
              <template v-slot:noItemsRegistered>
                <p>Nenhum atendimento</p>
              </template>
            </EmptyState>
          </div>
        </template>
      </template>
    </Container>
  </div>
</template>

<script>
import HomeController from "./HomeController";
export default HomeController;
</script>