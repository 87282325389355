<template>
  <div id="settings">
    <Navbar current="Contratos" :link="settingsInfo.path" :linkName="settingsInfo.title" />
    <Sidebar />
    <Container col="col-12">
      <Header :isSubHeader="false" :title="'Contratos'" subtitle="Termos de aceite e suporte para usuários"
        :iconClass="'fa-solid fa-file-contract text-primary'" :totalCount="2" @btnAction="view" btnLabel="Visualizar" />

      <div class="row">
        <div class="col-10 offset-1">
          <ul class="nav nav-tabs mb-4">
            <router-link :to="$paths.settings.terms.edit" tag="li" class="nav-item">
              <a href="#" class="nav-link" :class="{ active: settingsInfo.key == 'terms-of-use' }">
                Termos de uso
              </a>
            </router-link>
            <router-link :to="$paths.settings.support.edit" tag="li" class="nav-item">
              <a href="#" class="nav-link" :class="{ active: settingsInfo.key == 'support' }">
                Política de privacidade
              </a>
            </router-link>
            <router-link :to="$paths.settings.initial_terms_of_use.edit" tag="li" class="nav-item">
              <a href="#" class="nav-link" :class="{ active: settingsInfo.key == 'initial-terms-of-use' }">
                Termos de uso do cadastro inicial
              </a>
            </router-link>
            <router-link :to="$paths.settings.invitation_tos.edit" tag="li" class="nav-item">
              <a href="#" class="nav-link" :class="{ active: settingsInfo.key == 'invitation-tos' }">
                Termos de uso para convites
              </a>
            </router-link>
          </ul>
          <LoadingContainer v-if="loadingItems" />
          <div v-else>
            <div class="mb-4">
              <EditorInput :model="$v.text" style="height: calc(50vh)" />
            </div>
            <div class="text-center mt-6" v-if="errorMessage">
              <span class="text-danger">{{ errorMessage }}</span>
            </div>
            <FormButton label="Concluir" :loading="submitting" :disabled="$v.$invalid" class="mt-5" @onClick="submit" />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import SettingsEditController from "./SettingsEditController";
export default SettingsEditController;
</script>
