<template>
  <div id="mission_detail">
    <Navbar current="Detalhes da sessão" :link="`${$paths.customerService.list}/appointments`" :linkName="'Sessões'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes da Sessão">
        <template v-slot:button>
          <template v-if="canEdit">

            <button class="btn btn-link text-danger lift ms-3" @click="handleCancel">
              <span class="fas fa-times"></span>
              Cancelar
            </button>
            <button class="btn btn-outline-warning lift ms-3" @click="handleNotAvailable">
              <span class="fas fa-calendar-xmark"></span>
              Indisponível
            </button>
            <button class="btn btn-outline-primary lift ms-3" @click="handleConclude">
              <span class="fas fa-thumbs-up"></span>
              Realizei a sessão
            </button>
          </template>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h3>Informações da sessão</h3>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Status" :value="`${item.statusObject?.title}`" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Especialista" :value="item.specialist?.name || 'N/A'" />
              </div>
              <div class="col-12 col-xl-2">
                <TextInput :readOnly="true" label="Criado em" :value="item.formatted_created_at" />
              </div>
              <div class="col-12 col-xl-12">
                <TextInput v-if="item.status == 'canceled'" :readOnly="true" label="Motivo do cancelamento"
                  :value="`${item.cancel_reason || 'Não informado'}`" />
                <TextInput v-else :readOnly="true" label="Descrição do status"
                  :value="`${item.statusObject?.description}`" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Dia" :value="item.formatted_day" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Horário" :value="item.formatted_time" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Pilar" :value="item.pillarObject?.title" />
              </div>
              <div class="col-12 col-xl-12">
                <TextAreaInput :rows="2" :readOnly="true" label="Descrição" :value="item.reason" />
              </div>
              <div class="col-12 col-md-12">
                <TextAreaInput v-if="canEditDiagnosis" :rows="10" label="Diagnóstico" :model="$v.diagnosis" type="text"
                  :maxLength="DIAGNOSIS_MAX_LENGTH" placeholder="Informe aqui o diagnóstico da sessão quando existir"
                  :errorMsg="'Para salvar este campo corretamente, preencha um diagnóstico com no mínimo 10 caracteres'">
                  <template v-slot:content>
                    <div class="text-end">
                      <small class="text-muted" style="font-size: 0.7rem">{{
                        DIAGNOSIS_MAX_LENGTH - diagnosis?.length
                      }}
                        caracteres restantes</small>
                    </div>
                  </template>
                </TextAreaInput>
                <TextAreaInput v-else :rows="10" :readOnly="true" label="Diagnóstico" :value="item.diagnosis" />
              </div>

              <div class="col-12 col-xl-12">
                <div class="form-group" @click="goToRoom">
                  <div class="d-flex justify-content-between">
                    <label class="form-label text-muted"> link </label>
                  </div>
                  <input class="form-control form-disabled text-primary cursor-pointer" :value="item?.call_link"
                    disabled />
                </div>
              </div>
              <small style="margin-top: -15px" class="text-muted mb-4">
                <span class="fas fa-exclamation-triangle me-2"></span>O link é
                gerado automaticamente pela equipe Bluu
              </small>
              <template v-if="canSeeRating">
                <h3>Feedback da sessão</h3>
                <template v-if="item.rating !== null">
                  <div class="col-12 col-md-12 mb-4">
                    <label class="text-muted mt-3 mb-0 form-label">Avaliação</label>
                    <div>
                      <span v-for="(rating, index) in Array(5).fill('')" v-bind:key="'rating-' + index">
                        <span v-if="index + 1 <= item.rating" class="fas fa-2x fa-star text-primary"></span>
                        <span v-else class="far fa-2x fa-star text-muted"></span>
                      </span>
                      <span class="text-muted ms-2">({{ item.rating }}.0)</span>
                    </div>
                  </div>
                  <div class="col-12 col-xl-12">
                    <TextAreaInput :readOnly="true" label="Feedback" :value="item.feedback || 'N/A'" />
                  </div>
                </template>
                <div v-else>
                  <p>Não há feedbacks para essa sessão</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AppointmentDetailController from "./AppointmentDetailController";
export default AppointmentDetailController;
</script>
