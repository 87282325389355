<template>
  <div id="content_detail">
    <Navbar current="Detalhes do conteúdo" :link="$paths.tasks.list" :linkName="'Conteúdos'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do Conteúdo">
        <template v-slot:button
          v-if="userSession?.id === item?.author?.id && $permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])">
          <button @click="handleDelete" class="btn btn-outline-primary ms-3">
            Remover
          </button>
          <router-link :to="`${$paths.contents.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>

      <!-- <Header v-else title="Detalhes da Missão" /> -->
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-xl-12">
                <img :src="`${$baseImageUrl}${item.image}`" alt="..." class="avatar-img rounded-4"
                  style="height:200px; width: 340px; object-fit: fill">
              </div>
              <div class="col-12 col-xl-12">

                <h3 class="text-muted mt-3">{{ item.pillarObject?.title }}</h3>


                <h1 class="fw-bolder mb-2">{{ item.title }}</h1>


                <small class="text-muted fw-bold">
                  Publicado dia {{ item?.publication_date ? $moment(item.publication_date).format('DD/MM/YYYY') :
                    $moment(item.created_at).format('DD/MM/YYYY') }}
                </small>

                <p class="mt-3">
                  {{ item.content }}
                </p>

                <h2 class="fw-bolder mt-4 mb-3">Escrito por {{ item.author?.name }}</h2>

                <p v-if="item.author?.specialist_info?.bio" class="mt-3">
                  {{ item.author.specialist_info.bio }}
                </p>
              </div>
              <!-- <div class="col-12 col-xl-3">
                <TextInput :readOnly="true" label="Status" :value="item.statusObject?.title" />
              </div>
              <div class="col-12 col-xl-2">
                <TextInput :readOnly="true" label="Criado em" :value="item.formatted_created_at" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Categoria" :value="item.targetObject?.title" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Tipo" :value="item.typeObject?.title" />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Especialista"
                  :value="item.specialist_id ? item.specialist?.name : 'N/A' " />
              </div>
              <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Tutor" :value="item.user?.name" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ContentDetailController from "./ContentDetailController";
export default ContentDetailController;
</script>
