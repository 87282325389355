import { data } from 'jquery';
import { email, required, sameAs, url, minValue } from 'vuelidate/lib/validators';
export default {
  name: 'AddMissions',
  mounted() {

    if (this.$route.query?.pet_id) {
      this.$set(this.form, 'pet_id', this.$route.query.pet_id)
      this.getPetInfo(this.$route.query.pet_id)
    }

    if (this.id) {
      this.getData()
    }

    this.getSpecialists()
    const tomorrow = this.$moment().add(1, 'days').format('YYYY-MM-DD')
    this.minDueDate = tomorrow
    this.form.due_date = tomorrow
  },
  watch: {
    endlessMission(newVal, oldVal) {
      if (newVal) {
        this.$set(this.form.repetition, 'repetitions', 1)
      }
    },
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    missionFrequencySentence() {
      const frequency = this.$missionsService.getFrequency(this.form.repetition?.frequency)
      const sentence = `${this.form.repetition?.interval} ${frequency?.unity(this.form.repetition?.interval)} depois `
      return sentence
    },
    repetitionTimesLabel() {
      const sentence = this.endlessMission ? 'para sempre' : this.form.repetition?.repetitions > 1 ? 'vezes' : 'vez'
      return sentence
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      hasRepetition: false,
      endlessMission: false,
      pet: null,
      minDueDate: '',
      TITLE_MAX_LENGTH: 40,
      SHORT_DESCRIPTION_MAX_LENGTH: 90,
      DESCRIPTION_MAX_LENGTH: 800,
      errorMessage: '',
      submitting: false,
      specialistList: [],
      referenceLinkArray: [],
      extraContent: [],
      reference_link: '',
      durationUnity: 'days',
      form: {
        specialist_id: '', // opcional
        pet_id: null,
        // due_date: '',
        pillar: '',
        type: '',
        title: '',
        short_description: '',
        description: '',
        repetition: {
          frequency: "daily",
          interval: 1,
          repetitions: 1
        },
        automatic_task: false,
        times_to_complete: '1',
        start_automatically: false,
        duration: '1',
        extra_description: '', // opcional
      },
    };
  },
  validations: {
    durationUnity: '',
    endlessMission: '',
    reference_link: { url },
    form: {
      specialist_id: '',
      pet_id: '',
      extra_description: '',
      automatic_task: '',
      start_automatically: '',
      // due_date: {
      //   required,
      // },
      times_to_complete: {
        minValue: minValue(1)
      },
      pillar: {
        required,
      },
      type: {
        required,
      },
      title: {
        required,
      },
      short_description: {
        required,
      },
      description: {
        required,
      },
      repetition: {
        frequency: { required },
        interval: {
          required,
          minValue: minValue(1)
        },
        repetitions: {
          required,
          minValue: minValue(1)
        },
      },
      duration: {
        required,
        minValue: minValue(1)
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.addReferenceLink()
        let values = JSON.parse(JSON.stringify(this.form))
        switch (this.durationUnity) {
          case 'hours':
            values.duration = values.duration * 3600
            break;
          case 'days':
            values.duration = values.duration * 86400
            break;
          case 'weeks':
            values.duration = values.duration * 604800
            break;
          case 'months':
            values.duration = values.duration * 2.628e+6
            break;
        }
        if (!this.hasRepetition) {
          values.repetition = null
          // delete (values.repetition)
        }
        if (this.referenceLinkArray.length) {
          values.reference_links = this.referenceLinkArray.join('|')
        }
        if (this.endlessMission) {
          values.repetition.repetitions = 9999
        }
        // console.log(values)
        // return 
        this.submitting = true
        this.errorMessage = ''
        if (!this.id) {
          //adicionando
          const res = await this.$missionsService.create(values)
          const filesRes = await this.$missionsService.createMissionFiles(res.data.id, this.extraContent)
          this.$eventBus.$emit('showToast', { title: 'Missão criada', msg: `Missão criada com sucesso` })
          this.$router.push({ path: `${this.$paths.missions.list}` })

        } else {
          //editando

          delete (values.automatic_task)
          delete (values.start_automatically)
          delete (values.times_to_complete)
          const res = await this.$missionsService.update(this.id, values)
          let newFiles = this.extraContent.filter(o => o.new)
          if (newFiles.length) {
            const filesRes = await this.$missionsService.createMissionFiles(this.id, newFiles)
          }
          let deletedFiles = this.extraContent.filter(o => o.deleted)
          if (deletedFiles.length) {
            const filesRes = await this.$missionsService.deleteMissionFiles(deletedFiles)
          }
          this.$eventBus.$emit('showToast', { title: 'Missão alterada', msg: `Missão alterada com sucesso` })
          this.$router.back()
        }
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
    async getPetInfo(petId) {
      try {
        const res = await this.$petService.show(petId)
        this.$set(this, 'pet', res.data)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async getSpecialists() {
      try {
        const res = await this.$userService.list({ type: 'specialist' })
        this.$set(this, 'specialistList', res.data)
        if (this.userSession.type === 'specialist') {
          this.$set(this.form, 'specialist_id', this.userSession.id)
        }

      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async getData() {
      try {
        const res = await this.$missionsService.show(this.id)
        console.log(res)
        let repetition = {
          frequency: "daily",
          interval: 1,
          repetitions: 1
        }
        if (res.data.repetition) {
          this.$set(this, 'hasRepetition', true)
          let repetitionsQtd = res.data.repetition.repetitions
          this.endlessMission = repetitionsQtd >= 9999

          repetition = {
            frequency: res.data.repetition.frequency,
            interval: res.data.repetition.interval,
            repetitions: repetitionsQtd,
          }
        }
        if (res.data.pet_id) {
          this.getPetInfo(res.data.pet_id)
        }
        if (res.data.reference_links) {
          this.$set(this, 'referenceLinkArray', res.data.reference_links.split('|'))
        }

        let contents = res.data.files.map(file => {
          return {
            ...file,
            new: false
          }
        })

        let duration
        if (res.data.duration >= 2.628e+6) {
          this.durationUnity = 'months'
          duration = res.data.duration / 2.628e+6
        } else if (res.data.duration >= 604800) {
          this.durationUnity = 'weeks'
          duration = res.data.duration / 604800
        } else if (res.data.duration >= 86400) {
          this.durationUnity = 'days'
          duration = res.data.duration / 86400
        } else {
          this.durationUnity = 'hours'
          duration = res.data.duration / 3600
        }

        let f = {
          specialist_id: res.data.specialist_id || '',
          pet_id: res.data.pet_id,
          due_date: res.data.due_date ? this.$moment(res.data.due_date).format('YYYY-MM-DD HH:mm') : null,
          pillar: res.data.pillar,
          type: res.data.type,
          title: res.data.title,
          short_description: res.data.short_description,
          description: res.data.description,
          extra_description: res.data.extra_description,
          repetition: repetition,
          duration
        }

        this.$set(this, 'form', f)
        this.$set(this, 'extraContent', contents)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    handleAddExtraContent() {
      document.getElementById('mission-content-input').click()
    },
    removeExtraContent(fileIndex, file = null) {
      let contents = [...this.extraContent]
      if (contents[fileIndex].new) {
        contents.splice(fileIndex, 1)
      } else {
        const ind = contents.indexOf(file)
        contents[ind].deleted = true
      }

      console.log(JSON.stringify(contents))
      this.$set(this, 'extraContent', contents)

    },
    cropEnd(image) {
      let contents = [...this.extraContent]
      contents.push({
        file: image.file,
        type: image.file.type.includes('image') ? 'image' : 'file',
        new: true
      })
      this.$set(this, 'extraContent', contents)
      console.log(JSON.stringify(contents))
    },
    addReferenceLink() {
      if (!this.reference_link || this.$v.reference_link.$error || this.referenceLinkArray.length == 10) return
      const link = this.reference_link
      this.referenceLinkArray.push(link)
      this.reference_link = ''
    },
    removeReferenceLink(index) {
      let ls = this.referenceLinkArray
      ls.splice(index, 1)
      this.referenceLinkArray = ls
    },
    linkChanged($event) {
      const l = $event.target.value
      if (!l.startsWith('http')) {
        if (l == 'htt') {
          this.reference_link = `http://`
        } else {
          this.reference_link = `http://${this.reference_link}`

        }
      }
      // console.log($event)
    }
  }
};