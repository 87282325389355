<template>
  <div id="chats">
    <Navbar current="Chats" />
    <Sidebar />
    <Container col="col-12">
      <Header :isSubHeader="false" title="Atendimentos" subtitle="Registro dos atendimentos">
        <template v-slot:button>
          <button class="btn btn-primary lift ms-3" @click="openChatModal" v-if="chatUsers">
            <i class="fa-solid fa-circle-plus"></i>
            Chat
          </button>
        </template>
      </Header>
      <ul class="nav nav-tabs mb-4">
        <li tag="li" class="nav-item" v-for="(tab, index) in tabs" v-bind:key="'tabp-' + index"
          v-on:click="tabQueryParam = tab.id">
          <router-link tag="a" :to="`${$paths.customerService.list}/${tab.id}`" class="nav-link"
            :class="{ active: selectedTab == tab.id }">
            <small v-if="chatBadge && tab.id === 'chats-specialist'">
              <i class="sidebar-icon fa fa-circle ms-2 text-success"></i>
            </small>
            {{ tab.name }}
          </router-link>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent2">
        <div class="tab-pane fade" id="agenda-specialist" role="tabpanel" aria-labelledby="agenda-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'agenda-specialist' }">
          <AgendaComponent />
        </div>
        <div class="tab-pane fade" id="appointments" role="tabpanel" aria-labelledby="appointments-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'appointments' }">
          <Appointments />
        </div>
        <div class="tab-pane fade" id="chats" role="tabpanel" aria-labelledby="chats-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'chats' }">
          <Chats :chatFilter="'without_specialist'" />
        </div>
        <div v-if="$permissionService.isAllowed([$permissionService.ROLES.SPECIALIST])" class="tab-pane fade"
          id="chats-specialist" role="tabpanel" aria-labelledby="chats-specialist-tab" tabindex="0"
          :class="{ 'show active': selectedTab == 'chats-specialist' }">
          <Chats :chatFilter="'with_specialist'" :specialistId="userSession?.id" />
        </div>
      </div>

    </Container>
    <ModalComponent :title="'Criar Chat'" :id="createChatModalId" v-if="chatUsers"
      :modalBodyClass="'overflow-y-scroll'">
      <template v-slot:body style="height:350px; overflow-y: scroll;">
        <SearchBar @onSearch="searchChatUser" />
        <table class="
                table table-sm table-nowrap
                card-table
                table-hover
                cursor-pointer
              ">
          <tbody class="list">
            <tr v-for="(item, index) in chatUsers" @click="handleSelectChatUser(item)">
              <td class="" style="padding-left: 0 !important;" v-if="!item.hidden">
                <span v-if="selectedChatUser?.id == item.id" class="fas fa-circle-dot text-primary me-2"></span>
                <span v-else class="far fa-circle text-muted me-2"></span>
                {{ item.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-slot:footer>
        <button type="button" @click="handleCreateChat" :disabled="!selectedChatUser"
          class="btn btn-primary w-15">
          <span v-if="loadingCreateChat" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Criar
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import CustomerServiceController from "./CustomerServiceController";
export default CustomerServiceController;
</script>
