<template>
	<div id="users_detail">
		<Navbar current="Detalhes do tutor" :link="$paths.users.list" :linkName="'Tutores'" />
		<Sidebar />
		<Container>
			<Header title="Detalhes do Tutor">
				<template v-slot:button v-if="!loadingItems">
					<button v-if="!item?.email_verified_at" @click="handleConfirmEmail"
						class="btn btn-secondary lift ms-3">
						<span v-if="loadingConfirmEmail" class="spinner-border spinner-border-sm" role="status"
							aria-hidden="true"></span>
						<i v-if="!loadingConfirmEmail" class="fa-solid fa-user-check"></i>
						Confirmar email
					</button>
					<button v-if="!subscriptionInfo?.isPremium" @click="handleBecomePremium"
						class="btn btn-outline-primary lift ms-3">
						<span v-if="loadingPremium" class="spinner-border spinner-border-sm" role="status"
							aria-hidden="true"></span>
						<i v-if="!loadingPremium" class="fa-solid fa-crown text-warning"></i>
						Tornar usuário Premium
					</button>
					<button v-if="subscriptionInfo?.isPremium && subscriptionInfo?.upgradedByAdmin"
						@click="handleRemovePremium" class="btn btn-white lift ms-3">
						<span v-if="loadingPremium" class="spinner-border spinner-border-sm" role="status"
							aria-hidden="true"></span>
						Remover assinatura Premium
					</button>
					<button @click="handleStartChat" class="btn btn-primary lift ms-3">
						<span v-if="loadingChat" class="spinner-border spinner-border-sm" role="status"
							aria-hidden="true"></span>
						<i v-if="!loadingChat" class="fa-solid fa-comment"></i>
						Iniciar chat
					</button>
				</template>
			</Header>
			<LoadingContainer v-if="loadingItems" />
			<div v-else>
				<div class="card">
					<div class="card-header">
						<h3 class="mt-4">Informações</h3>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Nome" :value="item.name" />
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Email" :value="item.email" />
							</div>
							<div class="col-8 col-xl-4">
								<TextInput :readOnly="true" label="Telefone" :value="item.phone" />
							</div>
							<div class="col-4 col-xl-2 pt-4">
								<a :href="`http://wa.me/${item.phone}`" class="btn btn-xs btn-success w-100" target="_blank"><i class="fa-brands fa-whatsapp"></i> Enviar mensagem</a>
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Data de criação"
									:value="item.formatted_created_at" />
							</div>

							<div class="col-12 col-xl-6" v-if="item.key_pix">
								<TextInput :readOnly="true" label="Termos de uso dos convites" :value="'Aceito'" />
							</div>
							<div class="col-12 col-xl-6" v-if="item.key_pix">
								<TextInput :readOnly="true" label="Chave pix" :value="item.key_pix" />
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="subscriptionInfo?.isPremium">
					<div class="card-header">
						<h3 class="mt-4">Assinatura</h3>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Tipo" :value="subscriptionInfo.subscriptionType" />
							</div>
							<div class="col-12 col-xl-6">
								<TextInput :readOnly="true" label="Data de assinatura"
									:value="subscriptionInfo.subscriptionDate" />
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<div class="row align-items-center">
							<div class="col">
								<h3 class="mt-4">Tarefas</h3>
							</div>
							<div class="col-auto">
								<router-link :to="`${$paths.tasks.add}?user_id=${item.id}`" tag="a"
									class="btn btn-primary btn-sm">
									<span class="fa-solid fa-circle-plus"></span>
									Adicionar
								</router-link>
							</div>
						</div>
					</div>
					<div class="card-body">
						<table class="table table-sm table-nowrap card-table cursor-pointer">
							<TableHead :columns="tasksColumns" />
							<tbody class="list">
								<router-link tag="tr" v-for="(task, index) in item.tasks" v-bind:key="'trm-' + index"
									:to="`${$paths.tasks.detail}/${task.id}`">
									<td>{{ task.title }}</td>
									<td>{{ task.typeObject?.title }}</td>
									<td>{{ task.targetObject?.title }}</td>
									<td>{{ task.statusObject?.title }}</td>
									<td class="text-end">{{ task.formatted_created_at }}</td>
								</router-link>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card" v-if="item.referrals?.length">
					<div class="card-header">
						<div class="row align-items-center">
							<div class="col">
								<h3 class="mt-4">Convites aceitos</h3>
							</div>
							<div class="col-auto">
								<router-link :to="`${$paths.tasks.add}?user_id=${item.id}`" tag="a"
									class="btn btn-primary btn-sm">
									<span class="fa-solid fa-circle-plus"></span>
									Adicionar
								</router-link>
							</div>
						</div>
					</div>
					<div class="card-body">
						<table class="table table-sm table-nowrap card-table cursor-pointer">
							<TableHead :columns="inviteColumns" />
							<tbody class="list">
								<router-link tag="tr" v-for="(referral, index) in item.referrals"
									v-bind:key="'trm-' + index" :to="`${$paths.users.detail}/${referral.id}`">
									<td>{{ referral.user_info?.name }}</td>
									<td>{{ referral.formatted_status }}</td>
									<td class="text-end">{{ referral.formatted_created_at }}</td>
								</router-link>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Container>
	</div>
</template>
<script>
import UserDetailController from "./UserDetailController"
export default UserDetailController
</script>
