<template>
  <div id="add-admin">
    <Navbar :current="id ? 'Editar Questionário' : 'Adicionar Questionário'" :link="$paths.quizzes.list"
      :linkName="'Missões'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar Questionário' : 'Adicionar Questionário'" />
      <div class="card">
        <div class="card-body">
          <h3>Informações do questionário</h3>
          <div class="row">
            <div class="col-12 col-md-8">
              <TextInput label="Título" :required="true" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title"
                type="text" placeholder="Informe um título para a tarefa"
                :errorMsg="'Informe um título com ao menos 10 caracteres'" />
            </div>
            <div class="col-12 col-md-4">
              <SelectInput label="Pilar" :model="$v.form.pillar" :options="$missionsService.pillars" />
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput label="Descrição" :required="true" :model="$v.form.description" type="text"
                :maxLength="DESCRIPTION_MAX_LENGTH" placeholder="Informe a descrição">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{ DESCRIPTION_MAX_LENGTH -
    form.description.length
}} caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>
            <hr>
            <div class="col-12 mt-3">
              <h3>Perguntas</h3>
              <div>
                <div class="row" v-for="(item, index) in $v.form.questions.$each.$iter"
                  v-bind:key="'question_' + index">
                  <h3 class="mt-3">Questão {{ Number(index) + 1 }}
                    <button class="btn btn-link text-danger" v-if="form.questions.length > 1" style="margin-top: -3px"
                      @click="handleRemoveQuestion(index)">
                      <span class="fas fa-trash-alt"></span>
                      <small class="ms-1">Remover</small>
                    </button>
                  </h3>
                  <div class="col-12">
                    <SelectInput :required="true" label="Tipo de resposta" :model="item.field_type"
                      :options="$quizService.questionTypes" :propertyForText="'name'"
                      @selectChanged="answerTypeChanged($event, index)" />
                  </div>
                  <div class="col-12">
                    <TextInput :required="true" :label="`Pergunta`" :maxLength="TITLE_MAX_LENGTH" :model="item.title"
                      type="text" placeholder="Informe um título da questão"
                      :errorMsg="'Informe um título com ao menos 10 caracteres'" />
                  </div>
                  <div class="col-12">
                    <TextInput :required="true" :label="`Label`" :maxLength="TITLE_MAX_LENGTH" :model="item.field_label"
                      type="text" placeholder="Informe um label para a questão" :errorMsg="'Informe um label válido'" />
                  </div>

                  <template v-if="item.field_type.$model == 'select'">
                    <!-- handleAddAlternative -->
                    <div class="row" v-for="(alternative, altIndex) in item.select_answers.$each.$iter">
                      <div class="col-6">
                        <TextInput :required="true" :label="`Alternativa ${Number(altIndex) + 1}`" :model="alternative.title" type="text"
                          placeholder="Informe a alternativa" :errorMsg="'Informe uma alternativa'" />
                      </div>
                    </div>
                    <div class="col-12 mb-3" style="margin-top: -5px;">
                      <button class="btn btn-link p-0 m-0 text-success me-3" @click="handleAddAlternative(index)">
                        <span class="fas fa-circle-plus"></span>
                        <small class="ms-1">Adicionar</small>
                      </button>

                      <button class="btn btn-link p-0 m-0 text-danger" @click="handleRemoveAlternative(index)"
                        v-if="form.questions[index].select_answers.length > 1">
                        <span class="fas fa-trash-alt"></span>
                        <small class="ms-1">Remover</small>
                      </button>
                    </div>
                  </template>
                  <hr>
                </div>
                <div class="col-12">
                  <button @click="handleAddQuestion" class="btn btn-link ps-0"> <span class="fas fa-circle-plus"></span>
                    Adicionar questão</button>
                </div>
              </div>
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import QuizAddController from "./QuizAddController";
export default QuizAddController;
</script>
<style scoped>

</style>
