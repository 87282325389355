import { Modal } from 'bootstrap';
import { minLength, required } from 'vuelidate/lib/validators';
export default {
  // mixins: [ListControllerMixin],
  mounted() {
    this.loadingUser = true
    this.getUser()
    this.inviteTermsModal = new Modal(document.getElementById('inviteTermsModal'), {
      backdrop: 'static',
      keyboard: false
    })
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
    hasPix() {
      return this.user?.key_pix;
    },
  },
  data() {
    return {
      loadingUser: false,
      user: null,
      submittingPixKey: false,
      terms: '',
      termsChecked: false,
      pixKey: '',
    };
  },
  validations() {
    return {
      pixKey: { required, minLength: minLength(5) }
    }
  },
  methods: {
    async getUser() {
      try {
        const res = await this.$userService.show(this.userSession.id)
        let user = { ...res.data }
        user.referrals.map((elem) => {
          elem.subscriptionInfo = this.$userService.getSubscriptionInfo(elem.user_info)
        })
        console.log(user)
        
        this.$set(this, 'user', user)
        if (!user.key_pix) {
          this.getTerms()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingUser = false
      }
    },
    async getTerms() {
      try {
        const res = await this.$userService.settings('invitation-tos')
        this.terms = res.data.value
        this.inviteTermsModal.toggle()
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    handleContinueTerms() {
      this.inviteTermsModal.toggle()
    },
    async handleContinuePixKey() {
      if (!this.termsChecked) {
        this.inviteTermsModal.toggle()
        return
      }
      try {
        this.submittingPixKey = true
        const res = await this.$userService.update(this.userSession.id, { key_pix: this.pixKey })
        this.$set(this, 'user', { ...this.user, key_pix: this.pixKey })
        this.$eventBus.$emit('showToast', { title: 'Chave pix cadastrada', msg: `Sua chave foi cadastrada com sucesso` })
      } catch (e) {
        console.log(e)
      } finally {
        this.submittingPixKey = false
      }
    }
  }
};