<template>
  <div id="appointments">

    <div class="card">
      <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
          <template v-slot:noItemsRegistered>
            <span class="fa-3x fa-solid fa-comment-medical text-black-50 mb-2"></span>
            <p>Nenhum atendimento</p>
          </template>
        </EmptyState>
        <div v-else class="table-responsive">
          <table class="
                table table-sm table-nowrap
                card-table
                table-hover
                cursor-pointer
              ">
            <TableHead :columns="columns" v-on:sort="sort" />
            <tbody class="list">
              <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                :to="`${$paths.appointments.detail}/${item.id}`">
                <td>{{ item.user?.name }}</td>
                <td>{{ item.specialist?.name }}</td>
                <td>{{ item.pillarObject?.title }}</td>
                <td>
                  <span :class="item.statusObject?.badgeClass">
                    {{ item.statusObject?.title }}
                  </span>
                </td>
                <td class="text-end">
                  <time>{{
                    $moment(item.date).format("DD/MM/YYYY")
                  }}</time>
                </td>
              </router-link>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loadingItems">
      <div class="col-12  text-end">
        <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
          <b>{{ countItemsTotal }}</b> resultado(s)</span>
      </div>
    </div>
    <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
      <div class="col-2">
        <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
      </div>
    </div>
  </div>
</template>
<script>
import AppointmentsController from "./AppointmentsController";
export default AppointmentsController;
</script>
