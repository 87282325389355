var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"mission_detail"}},[_c('Navbar',{attrs:{"current":"Detalhes da missão","link":_vm.$paths.users.list,"linkName":'Usuários'}}),_c('Sidebar'),_c('Container',[_c('Header',{attrs:{"title":"Detalhes da Missão"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{staticClass:"btn btn-outline-primary ms-3",on:{"click":_vm.handleDelete}},[_vm._v(" Remover ")]),_c('router-link',{staticClass:"btn btn-primary lift ms-3",attrs:{"to":`${_vm.$paths.missions.edit}/${_vm.id}`,"tag":"a"}},[_vm._v(" Editar "),_c('i',{staticClass:"fa-solid fa-pen me-1"})])]},proxy:true}])}),(_vm.loadingItems)?_c('LoadingContainer'):_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('h3',[_vm._v("Informações")]),_c('div',{staticClass:"col-12 col-xl-6"},[_c('TextInput',{attrs:{"readOnly":true,"label":"Título","value":_vm.item.title}})],1),_c('div',{staticClass:"col-12 col-xl-4"},[_c('TextInput',{attrs:{"readOnly":true,"label":"Status","value":`${_vm.item.statusObject?.title}`}})],1),_c('div',{staticClass:"col-12 col-xl-2"},[_c('TextInput',{attrs:{"readOnly":true,"label":"Criado em","value":_vm.item.formatted_created_at}})],1),(_vm.item.reject_reason)?_c('div',{staticClass:"col-12 col-xl-12"},[_c('TextInput',{attrs:{"readOnly":true,"label":"Motivo do cancelamento","value":`${_vm.item.reject_reason}`}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-xl-12"},[_c('TextInput',{attrs:{"readOnly":true,"label":"Resumo","value":_vm.item.short_description}})],1),_c('div',{staticClass:"col-12 col-xl-12"},[_c('TextAreaInput',{attrs:{"readOnly":true,"label":"Descrição Completa","value":_vm.item.description}})],1),_c('div',{staticClass:"col-12 col-xl-12"},[_c('TextAreaInput',{attrs:{"readOnly":true,"label":"Descrição adicional","value":_vm.item.extra_description}})],1),_c('div',{staticClass:"col-12",class:{
                'col-xl-4': _vm.item.status == 'pending',
                'col-xl-3': _vm.item.status != 'pending',
              }},[_c('TextInput',{attrs:{"readOnly":true,"label":"Especialista","value":_vm.item.specialist_id ? _vm.item.specialist?.name : 'N/A'}})],1),_c('div',{staticClass:"col-12",class:{
                'col-xl-4': _vm.item.status == 'pending',
                'col-xl-3': _vm.item.status != 'pending',
              }},[_c('TextInput',{attrs:{"readOnly":true,"label":"Pilar","value":_vm.item.pillarObject?.title}})],1),(_vm.item.status != 'pending')?_c('div',{staticClass:"col-12",class:{
                'col-xl-4': _vm.item.status == 'pending',
                'col-xl-3': _vm.item.status != 'pending',
              }},[_c('TextInput',{attrs:{"readOnly":true,"label":"Data de término","value":_vm.item.formatted_due_date}})],1):_vm._e(),_c('div',{staticClass:"col-12",class:{
                'col-xl-4': _vm.item.status == 'pending',
                'col-xl-3': _vm.item.status != 'pending',
              }},[_c('TextInput',{attrs:{"readOnly":true,"label":"Tipo","value":_vm.item.typeObject?.title}})],1),(_vm.item.repetition)?_c('div',{staticClass:"col-12"},[_c('TextInput',{attrs:{"readOnly":true,"label":"Recorrência","value":_vm.item?.formatted_frequency}})],1):_vm._e(),_c('h3',[_vm._v("Links de referência")]),(!_vm.item.reference_links)?_c('EmptyState',{attrs:{"totalItemsRegistered":0},scopedSlots:_vm._u([{key:"noItemsRegistered",fn:function(){return [_c('p',[_vm._v("Nenhum link de referência criado")])]},proxy:true}],null,false,1447645946)}):_c('div',{staticClass:"col-12 mb-3"},_vm._l((_vm.item.reference_links?.split('|')),function(ref,index){return _c('span',{key:'link_' + index,staticClass:"badge bg-primary-soft me-3 mb-2"},[_c('a',{attrs:{"href":ref,"target":"_blank"}},[_vm._v(_vm._s(ref.limitString(50))+" ")])])}),0),_c('h3',[_vm._v("Conteúdos extras")]),(!_vm.item.files?.length)?_c('EmptyState',{attrs:{"totalItemsRegistered":0},scopedSlots:_vm._u([{key:"noItemsRegistered",fn:function(){return [_c('p',[_vm._v("Nenhum conteúdo extra criado")])]},proxy:true}],null,false,3889323178)}):_c('table',{staticClass:"table table-sm table-nowrap card-table table-hover cursor-pointer"},[_c('TableHead',{attrs:{"columns":_vm.columns}}),_c('tbody',{staticClass:"list"},_vm._l((_vm.item.files),function(item,index){return _c('tr',{key:'tr-' + index,on:{"click":function($event){return _vm.viewContent(item)}}},[(item.type == 'file')?_c('td',[_c('span',{staticClass:"fas fa-file-pdf display-5 text-muted"})]):_c('td',[_c('span',{staticClass:"fas fa-file-image display-5 text-muted"})]),_c('td',[_c('time',[_vm._v(_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY")))])]),_c('td',{staticClass:"text-end"},[_c('span',{staticClass:"fw-bold text-info"},[_vm._v("Ver conteúdo")])])])}),0)],1)],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }