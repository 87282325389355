<template>
  <div id="add-admin">
    <Navbar :current="id ? 'Editar Conteúdo' : 'Adicionar Conteúdo'" :link="$paths.missions.list"
      :linkName="'Conteúdos'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar Conteúdo' : 'Adicionar Conteúdo'" />
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12" v-if="!this.id">
              <label class="text-muted mb-3 mt-3 form-label">Imagem <i class="text-danger">*</i></label>
              <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="16 / 9"
                v-on:cropEnd="cropEnd" />
              <p v-if="$v.form.image.$invalid" class="text-danger">* Selecione uma imagem para o conteúdo</p>
            </div>
            <div class="col-12 mb-3" v-else>
              <label class="text-muted mb-3 mt-3 form-label">Imagem <i class="text-danger">*</i></label>
              <div class="w-25 position-relative">
                <div style="right: -8px; bottom: -8px" class="position-absolute">
                  <button @click="editImageClicked()" class="btn btn-primary rounded-circle"><span
                      class="fas fa-pencil"></span></button>
                  <button v-if="form.image" @click="removeImage()" class="btn btn-secondary rounded-circle ms-2"><span
                      class="fas fa-trash-alt"></span></button>
                </div>
                <img v-if="form.image" :src="form.image.base64" alt="..." class="avatar-img rounded-4"
                  style="height:100%; width: 100%; object-fit: fill">
                <img v-else :src="`${$baseImageUrl}${existingImage}`" alt="..." class="avatar-img rounded-4"
                  style="height:100%; width: 100%; object-fit: fill">
              </div>
              <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="16 / 9" v-on:cropEnd="cropEnd"
                inputId="content-add-message-input" :hiddenInput="true" />
            </div>
            <div class="col-12 col-md-12">
              <SelectInput :required="true" label="Classificação" :model="$v.form.pillar"
                :options="$missionsService.pillars.filter(mp => mp.id !== 'none')" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput :required="true" label="Título" :maxLength="TITLE_MAX_LENGTH" :model="$v.form.title" type="text"
                placeholder="Informe um título para o artigo"
                :errorMsg="'Informe um título com ao menos 10 caracteres'" />
            </div>
            <div class="col-12 col-md-12">
              <TextAreaInput :rows="20" :required="true" label="Descrição" :model="$v.form.content" type="text"
                :maxLength="CONTENT_MAX_LENGTH" placeholder="Informe o conteúdo">
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem">{{ CONTENT_MAX_LENGTH -
                      form.content.length
                    }} caracteres restantes</small>
                  </div>
                </template>
              </TextAreaInput>
            </div>
            <div class="col-12 col-md-12">
              <div class="form-group">

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="publicationDate"
                    :checked="publicationDate" @change="setPublicationDate" />
                  <label class="form-check-label" for="publicationDate">Programar data para a publicação?</label>
                </div>
              </div>

              <TextInput v-if="publicationDate" :required="true" label="Data da publicação" type="datetime-local"
                :model="$v.form.publication_date" placeholder="Informe a data para publicação"
                :errorMsg="'Informe uma data válida'" />
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton :label="'Salvar'" :loading="submitting" :disabled="$v.$invalid" @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ContentAddController from "./ContentAddController";
export default ContentAddController;
</script>
<style scoped></style>
